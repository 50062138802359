import React, { useState, useEffect } from "react";
import "../../App.scss";
import { MdClose } from "react-icons/md";

const NotificationBar = ({ message, showNotification, setShowNotification }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [lineWidth, setLineWidth] = useState(100);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(false);
      setShowNotification(false);
    }, 4500);

    const interval = setInterval(() => {
      setLineWidth((prevWidth) => Math.max(prevWidth - 0.1, 0));
    }, 0.1);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [showNotification]);

  return (
    <div
      style={{
        position: "fixed",
        top: "10px",
        right: "20px",
        backgroundColor: "#fff",
        padding: "40px",
        borderRadius: "5px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        display: isVisible ? "flex" : "none",
        flexDirection: "column",
        alignItems: "center",
        color: "black",
        fontSize: "16px",
        fontFamily: "sans-serif",
      }}
    >
      <div className="notification-container">
        <div className="message">{message} </div>
        <div className="text-btn" onClick={() => {
          setIsVisible(false);
          setShowNotification(false);
        }}>
          <MdClose />
        </div>
      </div>
      <div
        style={{
          height: "4px",
          width: `${lineWidth}%`,
          backgroundColor: "orange",
          borderRadius: "5px",
          marginTop: "10px",
          transition: "width 0.1s ease-out",
          position: "absolute",
          bottom: 0,
          left: 0,
        }}
      />
    </div>
  );
};

export default NotificationBar;
