import { useCallback, useEffect, useMemo, useState } from "react";
import "../styles/calendar.scss";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import {useTheme} from "../Providers/ThemeContext";

function DateCalendar({ currentDate, onDateChange }) {
  const [days, setDays] = useState([]);
  const [toggleSelect, setToggleSelect] = useState(true);
  const [initialSelectedDate, setInitialSelectedDate] = useState(null);
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const date = useMemo(() => new Date(), []);
  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth());
  const theme = useTheme();

  const findDays = useCallback(() => {
    const monthDays = [];
    let startDate = new Date(year, month, 1);

    const startDay = startDate.getDay();
    startDate.setDate(startDate.getDate() - startDay);

    for (let i = 0; i < startDay; i++) {

      monthDays.push({
        day: startDate.getDate(),
        currentMonth: false,
        currentDate: false,
      });

      startDate.setDate(startDate.getDate() + 1);
    }

    while (startDate.getMonth() === month) {

      monthDays.push({
        day: startDate.getDate(),
        currentMonth: startDate.getMonth() === month,
        currentDate:
            date &&
            date.getDate() === startDate.getDate() &&
            date.getMonth() === startDate.getMonth() &&
            date.getFullYear() === startDate.getFullYear(),
        isSelected: currentDate &&
            currentDate.getDate() === startDate.getDate() &&
            currentDate.getMonth() === startDate.getMonth() &&
            currentDate.getFullYear() === startDate.getFullYear(),
      });

      startDate.setDate(startDate.getDate() + 1);
    }

    while (monthDays.length < 35) {

      monthDays.push({
        day: startDate.getDate(),
        currentMonth: false,
        currentDate: false,
      });

      startDate.setDate(startDate.getDate() + 1);
    }

    return monthDays;
  }, [currentDate, date, month, year]);

  const getCurrentDate = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${months[month]} ${year}`;
  };

  const monthLeft = () => {
    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const monthRight = () => {
    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  const setDate = (day) => {
    if(onDateChange) {
        onDateChange(new Date(year, month, day));
    }
    setToggleSelect(true);
  };

  useEffect(() => {
    if (!initialSelectedDate) {
      setInitialSelectedDate(currentDate);
    }
    setDays(findDays());
  }, [month, initialSelectedDate, year, currentDate, toggleSelect, findDays]);

  useEffect(() => {
    if(currentDate) {
        setMonth(currentDate.getMonth());
        setYear(currentDate.getFullYear());
    }
  }, [currentDate])

  return (
    <div className={`calendar pos-abs ${theme.getThemeClass()}`}>
      <div>
        <div className="date-display">{getCurrentDate()}</div>
        <div className="header-wrapper">
          <MdChevronLeft onClick={monthLeft} />
          <div className="header">
            {dayNames.map((day, index) => (
              <div key={index} className="item">
                {day}
              </div>
            ))}
          </div>
          <MdChevronRight onClick={monthRight} />
        </div>
        <div className="days">
          {days.map((day, index) => (
            <div key={index}
              className={`item ${day.currentMonth ? "" : "inactive"} ${ day.isSelected ? "active" : "" }`} onClick={() => setDate(day.day)} >
              {day.day}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DateCalendar;
