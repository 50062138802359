import React, { useCallback, useEffect, useState } from "react";
import { MdSearch, MdOutlineArrowBack } from "react-icons/md";
import {sendGet, sendPost} from '../../common/request';
import Urls from '../../common/links';
import ImagePlaceholder from "../images/image-placeholder.jpg";
import Loader from "./Loader";
import useSubAccount from "../hooks/useSubAccount";
import { toast } from "react-toastify";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {useTranslation} from "react-i18next";

export default function SearchGroup({ handleChatPage }) {
  
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [query, setQuery] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const subAccount = useSubAccount();
  const {t} = useTranslation();

  const loadUser = useCallback(async () => {
    if(subAccount) {
      setIsLoading(true);
      let existingChats = [];
      //Loading existing chats
      const existingChatsResponse = await sendGet(Urls.GetAllChats, true);
      if(existingChatsResponse.error) {
        console.log(existingChatsResponse.error);
      } else {
        existingChats = existingChatsResponse.chats.map(chat => {
          if(chat.Sender && chat.Sender.id !== subAccount.UserId) {
            return chat.Sender.id
          } else {
            return chat.Receiver.id
          }
        });
      }

      const response = await sendGet(Urls.GetUsers, true);
      if(response.error) {
        console.error(response.error)
      } else {
        setSuggestions(response.users.filter(user => user.id !== subAccount.UserId && !existingChats.includes(user.id)));
      }
      setIsLoading(false);
    }
  }, [subAccount])

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setQuery(value);

    if (value.length > 0) {
      const newVal = value.toLowerCase();
      const filtered = suggestions.filter((suggestion) =>
        suggestion.username.toLowerCase().includes(newVal) || suggestion.email.toLowerCase().includes(newVal)
      );
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  };

  const startChat = async (user) => {
    setIsLoading(true);
    const response = await sendPost(Urls.CreateChat, {receiverId: user.id}, true, 'application/json', true);
    if(response.error) {
      toast.error(response.error);
    } else {
      if(handleChatPage) {
        handleChatPage();
      }
    }
    setIsLoading(false);
  }

  return (
    <>
      {
        isLoading ? 
        <Loader fullScreen={true} text={'Hold tight! We are loading your data...'}/> : 
        <div className="search-community">
          <div className="back-btn" onClick={handleChatPage}>
            <MdOutlineArrowBack />
          </div>
          <h1 className="search-community-heading">
            {t("community.searchChat.header")}
          </h1>

          <div className="main-search-container">
            <div className="search-bar-ug">
              <MdSearch />
              <input
                type="search"
                id="search-users"
                name="query"
                placeholder={t("community.searchChat.placeholder")}
                value={query}
                onChange={handleInputChange}
              />
            </div>
            <div className={'suggestions-list'}>
              {filteredSuggestions.length > 0 ? (
                      <ul className="search-dropdown">
                        {filteredSuggestions.map((suggestion, index) => (
                            <div key={index} className="searched-groups">
                              <LazyLoadImage className="user-image" src={suggestion.profileImage ?? ImagePlaceholder} alt="" />
                              <div className="start-chat-bar">
                                <div className="name-email">
                                  <li>{suggestion.username}</li>
                                  <span>{suggestion.email}</span>
                                </div>
                                <button
                                    onClick={() => startChat(suggestion)}
                                >
                                  <svg
                                      className="start-chat"
                                      width="21"
                                      height="20"
                                      viewBox="0 0 21 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                        d="M6.66641 5.2668L13.7414 2.90846C16.9164 1.85013 18.6414 3.58346 17.5914 6.75846L15.2331 13.8335C13.6497 18.5918 11.0497 18.5918 9.46641 13.8335L8.76641 11.7335L6.66641 11.0335C1.90807 9.45013 1.90807 6.85846 6.66641 5.2668Z"
                                        stroke="#000000"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M8.92578 11.3755L11.9091 8.38379"
                                        stroke="#000000"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                        ))}
                      </ul>
                  ) :
                  !query ?
                      <p className="empty-message">{t("community.searchChat.message")}</p> : <p className="empty-message">{t("community.searchChat.notFoundMessage")}</p>
              }
            </div>
          </div>
        </div>
      }
    </>
  );
}
