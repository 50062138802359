import React, { useState } from "react";
import { MdSearch, MdAdd, MdClose } from "react-icons/md"; // Import MdClose for remove button
import ImagePlaceholder from "../images/image-placeholder.jpg";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {useTranslation} from "react-i18next";

export default function GroupChat({
  handleNewGroupClick,
  handleFindGroupsClick,
  handleGroupSelect,
  handleGroupsearch,
  groups,
}) {

    const {t} = useTranslation();
    const [selectedGroup, setSelectedGroup] = useState(null);

    const handleUserClick = (group) => {
      setSelectedGroup(group.id);
      handleGroupSelect(group);
    };

  return (
    <div className="user-com">
      <div className="btns-width">
        <button className="new-chat-btn" onClick={handleNewGroupClick}>
          <MdAdd /> {t("community.chatBar.createNewGroupButton")}
        </button>
      </div>
      <div className="btns-width">
        <button className="find-groups-btn" onClick={handleFindGroupsClick}>
          <MdSearch /> {t("community.chatBar.findGroupsButton")}
        </button>
      </div>
      <div className="user-list-container">
        <div className="users-list">
          {groups.map((group) => (
            <div
              className={"user" + (selectedGroup === group.id ? " active-user" : "")}
              key={group.id}
              onClick={() => handleUserClick(group)}
            >
              <div className="user-name-message">
                <div className="user-image-container">
                  <LazyLoadImage className="user-image" src={group.image ?? ImagePlaceholder} alt="" />
                </div>
                <div className="name-message-container">
                  <p className="user-name">{group.name}</p>
                  {
                    group.LastMessage ? 
                    <p className="user-message">{group.LastMessage.text}</p> : <></>
                  }
                </div>
              </div>
              <div className="time-message">
                {
                  group.lastMessage ? 
                  <p className="time-of-message">{group.lastMessage.time}</p> : <></>
                }
                {
                  group.unreadMessages > 0 ? 
                  <p className="message-number-circle">
                    <span className="message-numbers">{group.unreadMessages}</span>
                  </p> : <></>
                }
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
