import { MdDragIndicator } from "react-icons/md";
import "../styles/sortable.scss";
import { useCallback, useEffect, useRef } from "react";

function SortableContainer({id, items, renderItem, updateItems, getItemHeight, scrollableContainerClass, grabIconInMiddle}) {

    const selectedItemIndex = useRef(-1);
    const selectedElement = useRef(undefined);
    const isMouseDown = useRef(false);
    const mousePosition = useRef({x: 0, y: 0});
    const mainContainer = useRef(undefined);
    const diff = useRef({x: 0, y: 0});
    const scrollableContainer = useRef(undefined)

    const getTop = useCallback((order, sortableItems) => {
        let top = 0;
        sortableItems.forEach((item, index) => {
            const itemOrder = parseInt(item.getAttribute('order'));
            if(itemOrder < order) {
                top += getItemHeight(items[index]);
            }
        });
        return top;
    }, [getItemHeight, items]);

    const positionElements = useCallback(() => {
        const sortableItems = document.querySelectorAll(`#${id} > .sortable-item`);
        sortableItems.forEach((item) => {
            const order = parseInt(item.getAttribute('order'));
            const index = order - 1;
            const top = index === 0 ? 0 : getTop(order, sortableItems);
            item.style.top = top + 'px';
            item.style.left = '0px';
        })
    }, [getTop, id])

    const assignOrders = useCallback(() => {
        const sortableItems = document.querySelectorAll(`#${id} > .sortable-item`);
        sortableItems.forEach((item, index) => {
            const itemOrder = item.getAttribute('itemOrder');
            item.setAttribute('order', itemOrder);
        })
    }, [id])

    useEffect(() => {
        const container = document.getElementById(id);
        mainContainer.current = container;
        const height = items.length > 0 ? items.map(item => getItemHeight(item)).reduce((a, b) => a + b) : 0;
        mainContainer.current.style.height = height + 'px';
        scrollableContainer.current = document.getElementsByClassName(scrollableContainerClass)[0];
        assignOrders();
        positionElements();
    }, [id, assignOrders, positionElements, items, getItemHeight, scrollableContainerClass]);

    const onMouseDown = (e, index) => {
        selectedItemIndex.current = index;
        selectedElement.current = e.currentTarget.parentElement;
        isMouseDown.current = true;
        diff.current = {x: mousePosition.current.x - selectedElement.current.offsetLeft, y: mousePosition.current.y - selectedElement.current.offsetTop};
        const offset = {x: mousePosition.current.x - diff.current.x, y: mousePosition.current.y - diff.current.y};
        selectedElement.current.style.top = offset.y + 'px';
        selectedElement.current.style.left = offset.x + 'px';
        selectedElement.current.style.transition = 'none';
        selectedElement.current.style.zIndex = '1000';
    }

    const onMouseMove = (e) => {
        e.stopPropagation();
        //If the main container is found
        if(mainContainer.current) {
            mousePosition.current = {x: e.clientX - mainContainer.current.offsetLeft, y: e.clientY - (mainContainer.current.offsetTop - scrollableContainer.current.scrollTop)}
        }

        if(!isMouseDown.current) {
            return;
        }

        if(selectedElement.current) {
            const offset = {x: mousePosition.current.x - diff.current.x, y: mousePosition.current.y - diff.current.y};
            selectedElement.current.style.left = offset.x + 'px';
            selectedElement.current.style.top = offset.y + 'px';
            const elementTop = (offset.y + selectedElement.current.clientHeight / 2);
            const triggerOffset = 10;

            //Item is the first element in the sequence
            const order = parseInt(selectedElement.current.getAttribute('order'));
            if(order === 1) {
                const afterElement = document.querySelector(`#${id} > .sortable-item[order='${order + 1}']`);
                const isAfterMiddle = elementTop > (afterElement.offsetTop + afterElement.clientHeight / 2);
                //If the current element is after the next element
                if(isAfterMiddle) {
                    //Changing the order'
                    afterElement.setAttribute('order', order);
                    selectedElement.current.setAttribute('order', order + 1);
                    positionElements();
                }
            } else if(order !== 1 && order < items.length) {
                const beforeElement = document.querySelector(`#${id} > .sortable-item[order='${order - 1}']`);
                const isBeforeMiddle = elementTop < (beforeElement.offsetTop + beforeElement.clientHeight / 2);

                if(isBeforeMiddle) {
                    //Changing the order'
                    beforeElement.setAttribute('order', order);
                    selectedElement.current.setAttribute('order', order - 1);
                    positionElements();
                    return;
                }
                
                const afterElement = document.querySelector(`#${id} > .sortable-item[order='${order + 1}']`);
                const isAfterMiddle = elementTop > (afterElement.offsetTop + afterElement.clientHeight / 2);
                //If the current element is after the next element
                if(isAfterMiddle) {
                    //Changing the order'
                    afterElement.setAttribute('order', order);
                    selectedElement.current.setAttribute('order', order + 1);
                    positionElements();
                    return;
                }
            } else if(order === items.length) {
                const beforeElement = document.querySelector(`#${id} > .sortable-item[order='${order - 1}']`);
                const isBeforeMiddle = elementTop < (beforeElement.offsetTop + beforeElement.clientHeight / 2);

                if(isBeforeMiddle) {
                    //Changing the order'
                    beforeElement.setAttribute('order', order);
                    selectedElement.current.setAttribute('order', order - 1);
                    positionElements();
                    return;
                }
            }
        }

    }

    const onMouseUp = (e) => {
        isMouseDown.current = false;
        selectedElement.current.style.transition = 'top 0.3s ease-in-out, left 0.3s ease-in-out';
        selectedElement.current.style.zIndex = '1';
        positionElements();

        //Sending the updated items
        const sortableItems = document.querySelectorAll(`#${id} > .sortable-item`);
        const newItems = [...items];
        sortableItems.forEach((item, index) => {
            const order = item.getAttribute('order');
            newItems[index].order = parseInt(order);
        });
        updateItems(newItems);
    }

    return (
        <div className="sortable-container" id={id} onMouseMove={onMouseMove}>
            {
                items.map((item, index) => {
                    const itemId = `${id}-${item.id}-${index}`;
                    return (
                        <div className="sortable-item" itemOrder={item.order} id={itemId}
                            style={{alignItems: grabIconInMiddle ? 'center' : 'flex-start'}}> 
                            <div className="icon" onMouseDown={e => onMouseDown(e, index)}
                                onMouseUp={onMouseUp} 
                                style={{marginTop: grabIconInMiddle ? '0' : '0.3em'}}>
                                <MdDragIndicator />
                            </div>
                            <div className="item-container">
                                {
                                    renderItem(item, index)
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default SortableContainer;