import React, { useEffect, useState } from "react";
import Select from "../components/selectcontrol";
import ImagePlaceholder from "../images/image-placeholder.jpg";
import { MdUpload, MdOutlineArrowBack, MdClose } from "react-icons/md";
import useSubAccount from "../hooks/useSubAccount";
import { sendPut } from "../../common/request";
import Urls from "../../common/links";
import { toast } from "react-toastify";
import Loader from "./Loader";
import {useTranslation} from "react-i18next";

export default function GroupSettings({
    selectedGroup,
    toggleInviteModal,
    toggleDeleteModal,
    leaveGroup,
    toggleSettingsVisibility,
    onGroupUpdate,
}) {
  const [imageFile, setImageFile] = useState(undefined);
  const [newGroupImage, setNewGroupImage] = useState('');
  const [groupImage, setGroupImage] = useState();
  const [groupName, setGroupName] = useState('');
  const [groupPrivacy, setGroupPrivacy] = useState('');
  const [canSave, setCanSave] = useState(false);
  const subAccount = useSubAccount();
  const [isLoading, setIsLoading] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false);
  const {t} = useTranslation();

  const loadData = () => {
    if(selectedGroup) {
      setGroupImage(selectedGroup.image);
      setGroupName(selectedGroup.name);
      setGroupPrivacy(selectedGroup.visibility); 
    }
  }

  useEffect(() => {
    loadData();
  }, [selectedGroup]);

  useEffect(() => {
    if(selectedGroup) {
      if((selectedGroup.name !== groupName || selectedGroup.visibility !== groupPrivacy || imageFile) && groupName) {
        setCanSave(true);
      } else {
        setCanSave(false);
      }
    }
  }, [selectedGroup, imageFile, groupName, groupPrivacy ]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        setNewGroupImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setImageFile(undefined);
    setNewGroupImage('');
    document.getElementById('fileUploads').value = '';
  }

  const privacyOptions = ["Public", "Private"];

  const onDiscardChanges = () => {
    loadData();
    setImageFile(undefined);
    setNewGroupImage('')
    document.getElementById('fileUploads').value = '';
  }

  const onSaveChanges = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('name', groupName);
    formData.append('visibility', groupPrivacy);
    formData.append('image', groupImage);
    
    if(imageFile) {
      formData.append('file', imageFile);
    }

    const response = await sendPut(Urls.UpdateGroup(selectedGroup.id), formData, true, 'multipart/form-data', false);
    if(response.error) {
      toast.error(response.error);
    } else {
      toast.success('Group Data Updated Successfully');
      onGroupUpdate();
      onDiscardChanges();
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if(selectedGroup && subAccount) {
      if(selectedGroup.adminId === subAccount.UserId) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
  }, [selectedGroup, subAccount])

  return (
    <div className="main-group-settings">
      <div className="group-settings">
        <div className="group-header">
          <div className="user-image-container">
            <img className="user-image" src={groupImage ?? ImagePlaceholder} alt="Group Icon" />
          </div>

          <p>{t("community.settings.header")}</p>
          <div className={'back-btn'} onClick={toggleSettingsVisibility}>
            <MdOutlineArrowBack />
          </div>
        </div>

        {
          isAdmin ? 
          <div>
            <label className="group-settings-label">{t("community.settings.fields.groupImage")}</label>
            <div className="upload-group-image" onClick={handleRemoveImage}>
              {newGroupImage ? (
                <div className="image-container">
                  <img
                    src={newGroupImage}
                    alt="Group"
                    className="group-image-preview"
                  />
                  <button className="remove-image-btn">
                    <MdClose />
                  </button>
                </div>
              ) : (
                <label htmlFor="fileUploads" className="upload-label">
                  <MdUpload /> {t("community.settings.fields.uploadText")}
                </label>
              )}
              <input
                className="hidden"
                type="file"
                id="fileUploads"
                name="fileUploads"
                onChange={handleImageUpload}
              />
            </div>
          </div> : <></>
        }
        <div>
          <label className="group-settings-label">{t("community.settings.fields.groupName")}</label>
          <input type="text" id="group-name" aria-label="Group Name" value={groupName} onChange={e => setGroupName(e.currentTarget.value)} disabled={!isAdmin} />
        </div>
        <div>
          <label className="group-settings-label" htmlFor="group-privacy">
            {t("community.settings.fields.groupVisibility")}
          </label>
          {
            isAdmin ? 
            <Select
              options={privacyOptions}
              selectedIndex={privacyOptions.indexOf(groupPrivacy)}
              onChange={(index) => setGroupPrivacy(privacyOptions[index])}
              itemName="privacy"
            /> : 
            <input type="text" id="group-name" aria-label="Group Visibility" value={groupPrivacy} disabled/>
          }
        </div>
        {
          canSave ? 
          <div className="buttons-container">
            <div className="field-buttons">
              <div className="field-save-btn" onClick={onSaveChanges}>
                {
                  isLoading ? 
                  <Loader color="white" height={20} barWidth={3} /> :
                  t("community.settings.fields.saveBtn")
                }
              </div>
              <div className="field-discard-btn" onClick={onDiscardChanges}>
                {t("community.settings.fields.discardBtn")}
              </div>
            </div>
          </div> : <></>
        }
        {
          isAdmin ? 
          <div>
            <button className="invite-btn" onClick={toggleInviteModal}>
              {t("community.settings.fields.inviteToGroupButton")}
            </button>
          </div> : <></>
        }
        {
          isAdmin ? 
          <button className="delete-btn" onClick={toggleDeleteModal}>
            {t("community.settings.fields.deleteGroupButton")}
          </button> : 
          <button className="leave-btn" onClick={leaveGroup}>
            {t("community.settings.fields.leaveGroupButton")}
          </button>
        }
      </div>
    </div>
  );
}
