const Line = ({className}) => {
    return (
        <svg width="487" height="107" viewBox="0 0 487 107" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M3 104H242.5V3H484.5" stroke="url(#paint0_linear_2905_23256)" strokeWidth="5"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <defs>
                <linearGradient id="paint0_linear_2905_23256" x1="13.5" y1="-43" x2="485" y2="113.5"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#02FEB3"/>
                    <stop offset="1" stopColor="#39B6FF"/>
                </linearGradient>
            </defs>
        </svg>

    )
}

export default Line;