import {useEffect, useState} from "react";
import {sendGet} from "../../common/request";
import Urls from "../../common/links";
import UserContext from "../contexts/UserContext";
import {useLocation, useNavigate} from "react-router-dom";

const AuthenticateUser = ({shallRedirect = false, children}) => {

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    const redirect = () => {
        if(shallRedirect) {
            const query = {
                redirect: location.pathname,
            }
            navigate(`/login?${new URLSearchParams(query)}`);
        }
    }

    const getUser = async () => {
        setLoading(true)
        try {
            const response = await sendGet(Urls.GetUser, true);
            if(response.error) {
                setUser(null)
                redirect();
            }
            else {
                setUser(response.user);
            }
        }
        catch (error) {
            console.log(error);
            redirect();
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getUser()
    }, []);

    return (
        <UserContext.Provider value={{user: user, loading: loading}}>
            {children}
        </UserContext.Provider>
    )
}

export default AuthenticateUser;