import React, {useEffect, useRef, useState} from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Quill } from "react-quill";
import { MdRedo, MdTableView, MdUndo } from "react-icons/md";
import "../styles/rich-text-editor.scss";
import { useTranslation } from "react-i18next";
import {useTheme} from "../Providers/ThemeContext";

const fontSizeArr = [
  "8px",
  "9px",
  "10px",
  "12px",
  "14px",
  "16px",
  "20px",
  "24px",
  "32px",
  "42px",
  "54px",
];

const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = fontSizeArr;
Quill.register(SizeStyle, true);

const Size = Quill.import('formats/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida"
];
Quill.register(Font, true);

// Formats objects for setting up the Quill editor
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "background",
  'list',
  'bullet',
  "code-block",
];

const CustomToolbar = ({quillRef}) => {
  
  const handleUndo = () => {
    const quill = quillRef.current.getEditor();
    quill.history.undo();
  };

  const handleRedo = () => {
    const quill = quillRef.current.getEditor();
    quill.history.redo();
  };

  return (
    <div id="toolbar">
      <span className="ql-formats">
        <div className="ql-section">
          <button className="ql-undo" onClick={handleUndo}><MdUndo /></button>
          <button className="ql-redo" onClick={handleRedo}><MdRedo /></button>
        </div>
        <div className="ql-section">
          <select className="ql-header">
            <option value={1}></option>
            <option value={2}></option>
            <option value={3}></option>
            <option value={4}></option>
            <option value={5}></option>
            <option value={6}></option>
            <option value={false}></option>
          </select>
        </div>
        <div className="ql-section">
          <select className="ql-font" defaultValue="comic-sans">
            <option value="arial">Arial</option>
            <option value="comic-sans">Comic Sans</option>
            <option value="courier-new">Courier New</option>
            <option value="georgia">Georgia</option>
            <option value="helvetica">Helvetica</option>
            <option value="lucida">Lucida</option>
          </select>
          <select className="ql-size">
            {
              fontSizeArr.map((font, index) => {
                return (
                  <option value={font} key={index}>{font}</option>
                )
              })
            }
          </select>
        </div>
        <div className="ql-section">
          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
          <button className="ql-strike"></button>
          <button className="ql-blockquote"></button>
          <button className="ql-code-block"></button>
          <button className="ql-link"></button>
        </div>
        <div className="ql-section">
          <button className="ql-image"></button>
          <select className="ql-color" />
          <select className="ql-background" />
          <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button>
        </div>
        <div className="ql-section">
          <button className="ql-indent" value="+1">
            Indent
          </button>
          <button className="ql-indent" value="-1">
            Outdent
          </button>
          <select className="ql-align" />
        </div>
      </span>
    </div>
  )
}

const RichTextEditor = ({content, onSaveChanges, onDiscardChanges, onContentChange}) => {

  const quillRef = useRef(null);
  const [htmlContent, setHtmlContent] = useState('');
  const {t} = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    setHtmlContent(content);
  }, [content])

  const handleChange = (html) => {
    if(onContentChange) {
      onContentChange(html);
    }

    setHtmlContent(html);
  };

  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };

  const onSaveClick = () => {
    if(onSaveChanges) {
      onSaveChanges(htmlContent)
    }
  }

  const onDiscardClick = () => {
    if(onDiscardChanges) {
      onDiscardChanges();
    }
  }

  return (
    <div className={`editor-container ${theme.getThemeClass()}`}>
      <CustomToolbar quillRef={quillRef} />
      <ReactQuill
        value={htmlContent}
        theme="snow"
        ref={quillRef}
        className="text-editor"
        onChange={handleChange}
        formats={formats}
        modules={modules}
      />
      <div className="buttons-container">
        <div className="field-buttons">
          <div className="field-save-btn" onClick={onSaveClick}>
              {t("save_changes")}
          </div>
          <div className="field-discard-btn" onClick={onDiscardClick}>
            {t("discard_changes")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RichTextEditor;
