import Calendar from "./calendar";
import { useEffect, useState } from "react";
import "../styles/calendar.scss";
import { MdExpandLess, MdExpandMore, MdCalendarMonth } from "react-icons/md";
import useOutsideeClick from "../hooks/useOutsideClick";
import moment from "moment";
import {useTheme} from "../Providers/ThemeContext";

function CalendarInput({ selectedDate, endDate }) {
  const [calendarShown, setCalendarShown] = useState(false);
  const {ref, isComponentVisible, setIsComponentVisible} = useOutsideeClick(true);
  const theme = useTheme();

  useEffect(() => {
    if(!isComponentVisible) {
      setIsComponentVisible(true);
      setCalendarShown(false);
    }
  }, [isComponentVisible, setIsComponentVisible])

  const getCurrentDate = () => {
    const startDate = moment(selectedDate).format("ddd, MMM DD YYYY");
    if(endDate) {
        const date = moment(endDate).format("ddd, MMM DD YYYY");
        return `${startDate} - ${date}`;
    } else {
        return startDate;
    }
  };

  const toggleCalendar = () => {
    setCalendarShown(!calendarShown);
  };

  return (
    <div className={`calendar-input ${theme.getThemeClass()}`} ref={ref}>
      <div
        className="input"
        style={{
          padding: "10px 5px",
          width: "100%",
        }}
        onClick={toggleCalendar}
      >
        <MdCalendarMonth
          style={calendarShown}
          className="calendar-icon"
        />
        <div className="date">{getCurrentDate()}</div>
        <div
          className="icon"
          style={{
            marginRight: "5px",
          }}
        >
          {calendarShown ? <MdExpandLess /> : <MdExpandMore />}
        </div>
      </div>
      {calendarShown && (
        <div className="calendar-wrapper">
          <Calendar selectedDate={typeof selectedDate === "string" ? new Date(selectedDate) : selectedDate} endDate={typeof endDate === "string" ? new Date(endDate) : endDate} />
        </div>
      )}
    </div>
  );
}

export default CalendarInput;
