import permissions from "./Permissions.json";

const packages = [
  {
    "popular": false,
    "name": "Basic",
    "class": "basic",
    "color": "#00f9ea",
    "description": "For personal use only or for small team up to 5 members",
    "price": {
      "month": 29,
      "year": 100
    },
    "features": [
      "Up to 5 Accounts",
      "Daily Reports",
      "Trading Journal",
      "Community"
    ],
    "permissions": [
        permissions.PLAYBOOK.VIEW
    ]
  },
  {
    "popular": true,
    "name": "Gold",
    "class": "gold",
    "color": "#ffa800",
    "description": "For large teams of up to 20 members or for professional traders",
    "price": {
      "month": 69,
      "year": 700
    },
    "features": [
      "Up to 20 Accounts",
      "Daily Reports",
      "Trading Journal",
      "Community"
    ],
    "permissions": [
      permissions.PLAYBOOK.VIEW,
      permissions.PLAYBOOK.CREATE,
      permissions.PLAYBOOK.EDIT,
      permissions.PLAYBOOK.DELETE,
      permissions.NOTEBOOK.VIEW,
    ]
  },
  {
    "popular": false,
    "name": "Premium",
    "class": "premium",
    "color": "#d18cfd",
    "description": "For large teams and businesses",
    "price": {
      "month": 129,
      "year": 1300
    },
    "features": [
      "Unlimited Accounts",
      "Daily Reports",
      "Trading Journal",
      "Community"
    ],
    "permissions": [
      permissions.PLAYBOOK.VIEW,
      permissions.PLAYBOOK.CREATE,
      permissions.PLAYBOOK.EDIT,
      permissions.PLAYBOOK.DELETE,
      permissions.PLAYBOOK.DETAILS,
      permissions.PLAYBOOK.DUPLICATE,
      permissions.NOTEBOOK.VIEW,
      permissions.NOTEBOOK.CREATE,
      permissions.NOTEBOOK.DELETE,
      permissions.NOTEBOOK.EDIT,
    ]
  }
]

export default packages;