import {useState} from "react";

const FAQSection = () => {

    const [faqs, setFAQs] = useState([
        {
            question: "Do you offer trade journaling?",
            answer: "Yes we offer trade journaling. We not only allow to journal trades in a group we allow you to add notes and attach playbooks with each trade individually to identify your trades and make most out of your analysis",
            selected: false,
        },
        {
            question: "Is community open for everyone or do I have to pay for it?",
            answer: "Yes we offer trade journaling. We not only allow to journal trades in a group we allow you to add notes and attach playbooks with each trade individually to identify your trades and make most out of your analysis",
            selected: false,
        },
        {
            question: "Do you offer trade journaling?",
            answer: "Yes we offer trade journaling. We not only allow to journal trades in a group we allow you to add notes and attach playbooks with each trade individually to identify your trades and make most out of your analysis",
            selected: false,
        },
        {
            question: "Do you offer trade journaling?",
            answer: "Yes we offer trade journaling. We not only allow to journal trades in a group we allow you to add notes and attach playbooks with each trade individually to identify your trades and make most out of your analysis",
            selected: false,
        },
        {
            question: "Do you offer trade journaling?",
            answer: "Yes we offer trade journaling. We not only allow to journal trades in a group we allow you to add notes and attach playbooks with each trade individually to identify your trades and make most out of your analysis",
            selected: false,
        },
        {
            question: "Do you offer trade journaling?",
            answer: "Yes we offer trade journaling. We not only allow to journal trades in a group we allow you to add notes and attach playbooks with each trade individually to identify your trades and make most out of your analysis",
            selected: false,
        },
        {
            question: "Do you offer trade journaling?",
            answer: "Yes we offer trade journaling. We not only allow to journal trades in a group we allow you to add notes and attach playbooks with each trade individually to identify your trades and make most out of your analysis",
            selected: false,
        },
    ])

    const onFAQSelected = (index) => {
        const newFAQs = faqs.map((faq, i) => {
            if(i === index) {
                faq.selected = !faq.selected;
            }
            else {
                faq.selected = false;
            }
            return faq;
        });
        setFAQs(newFAQs);
    }

    return (
        <div className="faq-section front">
            <div className="content">
                <div className="title">
                    <div className="heading">
                        <span className='bold'>Have <span className="gradient">Questions?</span></span>
                        <span>We have answers</span>
                    </div>
                    <div className="description">
                        Our team of experts is always ready to answer all of your questions
                        regarding the platform. Our sole purpose is to make this journey
                        as easy as possible for you by laying out most commonly asked
                        questions
                    </div>
                </div>
                <div className="faqs">
                    {
                        faqs.map((faq, index) => {
                            return (
                                <div className={`faq ${faq.selected ? 'expanded' : ''}`} key={index} onClick={() => onFAQSelected(index)}>
                                    <div className="header">
                                        <div className="heading">{faq.question}</div>
                                        <div className="icon">
                                            <div className="line line-1"></div>
                                            <div className="line line-2"></div>
                                        </div>
                                    </div>
                                    <div className="body">{faq.answer}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default FAQSection;