import { MdChevronLeft, MdMenu, MdNotifications } from "react-icons/md";
import CalendarInput from "./calendarinput";
import Select from "./selectcontrol";
import { useDispatch, useSelector } from "react-redux";
import useSubAccounts from "../hooks/useSubAccounts";
import useSubAccount from "../hooks/useSubAccount";
import { setAccounts } from "../../redux/selectedAccounts.js/action";
import { useNavigate } from "react-router-dom";
import {useTheme} from "../Providers/ThemeContext";

function Header() {
    const dispatch = useDispatch();
    const subAccounts = useSubAccounts();
    const subAccountNames = subAccounts ? subAccounts.map(s => s.name) : [];
    const selectedAccount = useSubAccount();
    const selectedDate = useSelector((state) => state.date.selectedDate);
    const endDate = useSelector((state) => state.date.endDate);
    const header = useSelector(state => state.header);
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <>
            {
                header.show ? 
                    <div className={`page-header ${theme.getThemeClass()}`}>
                        <div className="flex-horizontal">
                            {
                                header.backButton ? 
                                <div className="back-btn" onClick={() => navigate(-1)}>
                                    <div className="icon-container">
                                        <MdChevronLeft />
                                    </div>
                                </div> :  <></>
                            }
                            <div className="title">
                                {header.title}
                            </div>
                        </div>
                        <div className="support">
                            <div className="calendar-field">
                                <CalendarInput selectedDate={selectedDate} endDate={endDate} />
                            </div>
                            <div className="account">
                                <Select
                                onChange={(index) => {
                                    dispatch(setAccounts(subAccountNames[index]));
                                }}
                                options={subAccountNames}
                                selectedIndex={
                                    selectedAccount ? (subAccountNames.indexOf(selectedAccount.name) !== -1
                                        ? subAccountNames.indexOf(selectedAccount.name)
                                        : 0 ) : -1
                                }
                                />
                            </div>
                            <div className="sidebar-expand-btn">
                                <MdMenu />
                            </div>
                        </div>
                    </div> : <></>
            }
        </>
    )
}

export default Header;