function Loader({text, height, width, barWidth, fullScreen = false, color= '#00df9d'}) {
    return (
        <div className="loader-container" style={{width: fullScreen ? "100%" : "auto", height: fullScreen ? "85vh" : "auto"}}>
            <div className="loader-items" style={{height: height ? height : '50px', width: width ? width : 'auto'}}>
                <div className="loader-bar" style={{width: barWidth ? barWidth : 8, backgroundColor: color}}></div>
                <div className="loader-bar" style={{width: barWidth ? barWidth : 8, backgroundColor: color}}></div>
                <div className="loader-bar" style={{width: barWidth ? barWidth : 8, backgroundColor: color}}></div>
                <div className="loader-bar" style={{width: barWidth ? barWidth : 8, backgroundColor: color}}></div>
                <div className="loader-bar" style={{width: barWidth ? barWidth : 8, backgroundColor: color}}></div>
            </div>
            {
                text ? 
                <div style={{marginTop: "10px"}}>
                    {text}
                </div> : <></>
            }
        </div>
    )
}

export default Loader;