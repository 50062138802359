const initialState = {
  unReadMessages: 0,
};
  
export default function communityReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_UNREAD_MESSAGES":
      return {
        ...state,
        unReadMessages: action.payload,
      };
    default:
      return state;
  }
}
  