const initialState = {
    title: 'Dashboard',
    show: true,
    backButton: false,
  };
  
  export default function headerReducer(state = initialState, action) {
    switch (action.type) {
      case "SET_TITLE":
        return {
          ...state,
          title: action.payload,
          show: true
        };
      case 'HIDE_TITLE': 
        return {
          ...state,
          title: '',
          show: false,
        }
      case 'SET_HEADER':
        return {
          ...state,
          title: action.payload.title,
          show: action.payload.show,
          backButton: action.payload.backButton
        }
      default:
        return state;
    }
  }
  