import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdDelete, MdEdit, MdMoreHoriz } from "react-icons/md";

function FolderItem({id, title, color, isActive, onDelete, onEdit, onClick, canShowMenu}) {

    const [isActionMenuShown, setIsActionMenuShown] = useState(false);
    const {t} = useTranslation();
    const onDeleteClick = () => {
        setIsActionMenuShown(false);
        if(onDelete) {
            onDelete(id);
        }
    }

    const onEditClick = () => {
        setIsActionMenuShown(false);
        if(onEdit) {
            onEdit(id);
        }
    }

    const onActionMenuToggle = (e) => {
        e.stopPropagation();
        setIsActionMenuShown(!isActionMenuShown);
    }

    return (
        <div className={`folder-item ${isActive ? 'active' : ''}`} onClick={onClick}>
            <div className={`color-box ${color ? `color-${color}` : ''}`}></div>
            <div className='title'>
                <div className='short-title'>{title[0]}</div>
                <div className='full-title'>{title}</div>
            </div>
            {
                canShowMenu ? 
                <div className={`action-menu no-border ${isActionMenuShown ? 'show' : ''}`}>
                    <div className='title' onClick={e => onActionMenuToggle(e)}>
                        <div className="icon">
                            <MdMoreHoriz />
                        </div>
                    </div>
                    <div className='options'>
                        <div className='primary-btn' onClick={onEditClick}>
                            <MdEdit />
                            {t("edit_action")}
                        </div>
                        <div className='delete-btn' onClick={onDeleteClick}>
                            <MdDelete />
                            {t("delete_action")}
                        </div>
                    </div>
                </div> : <></>
            }
        </div>
    )
}

export default FolderItem;