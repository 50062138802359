import {useEffect, useState} from "react";
import { Provider } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./App.scss";
import { getToken } from "./common/token";
import store, {persistor} from "./redux/store";
import Root from "./root";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {PersistGate} from "redux-persist/integration/react";
import ThemeContext from "./assets/Providers/ThemeContext";

function App() {
  let navigation = useNavigate();
  let token = getToken();
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    if (!token) {
      navigation("/login");
    }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18next}>
          <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={true}
              closeOnClick={true}
              rtl={false}
              pauseOnFocusLoss={false}
              draggable={false}
              pauseOnHover={false}
              theme={theme === 'dark' ? 'dark' : 'light'}
              transition={Bounce}
          />
          <ThemeContext.Provider value={{
            theme: theme,
            changeTheme: (theme) => setTheme(theme),
            getThemeClass: () => theme === 'dark' ? 'dark' : 'light'
          }}>
            <Root />
          </ThemeContext.Provider>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
