const initialState = {
  selectedDate: new Date(),
  endDate: null,
};

const dateReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DATES":
      return {
        ...state,
        selectedDate: action.payload.selectedDate,
        endDate: action.payload.endDate,
      };
    default:
      return state;
  }
};

export default dateReducer;
