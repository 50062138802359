import { useSelector } from "react-redux";

const useSubAccounts = () => {

    const accounts = useSelector((state) => state?.user?.user);
    if(accounts && accounts.user) {
        return accounts.user.subAccounts;
    } else {
        return undefined;
    }
}

export default useSubAccounts;