import { useState } from "react";
import "../styles/calendar.scss";
import { MdExpandLess, MdExpandMore, MdCalendarMonth } from "react-icons/md";
import DateCalendar from "./DateCalendar";
import moment from "moment";
import {useTheme} from "../Providers/ThemeContext";

function DateInput({ currentDate, onDateChange }) {
  const [calendarShown, setCalendarShown] = useState(false);
  const theme = useTheme();

  const getCurrentDate = () => {
    if(currentDate) {
        return moment(currentDate, 'MM-DD-YYYY').format('MMM DD, YYYY');
    } else {
        return 'MMM DD, YYYY'
    }
  };

  const toggleCalendar = () => {
    setCalendarShown(!calendarShown);
  };

  const dateChange = (date) => {
    if(onDateChange) {
        onDateChange(date);
    }
    setCalendarShown(false);
  }

  return (
    <div className={`calendar-input ${theme.getThemeClass()}`}>
      <div
        className="input"
        style={{
          padding: "10px 5px",
          width: "100%",
        }}
        onClick={toggleCalendar}
      >
        <MdCalendarMonth className="calendar-icon"/>
        <div className="date">{getCurrentDate()}</div>
        <div className="icon" style={{ marginRight: "5px", }} >
          {calendarShown ? <MdExpandLess /> : <MdExpandMore />}
        </div>
      </div>
      {calendarShown && (
        <div className="calendar-container">
          <DateCalendar currentDate={moment(currentDate, 'MM-DD-YYYY').toDate()} onDateChange={dateChange} />
        </div>
      )}
    </div>
  );
}

export default DateInput;
