import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import Loader from '../Loader';
import { sendPost, sendPut } from "../../../common/request";
import Urls from '../../../common/links';
import { useTranslation } from "react-i18next";
import {useTheme} from "../../Providers/ThemeContext";

function FolderCreateModal({handleCloseModal, handleSaveModal, isAdd, editFolder}) {

    const [name, setName] = useState('');
    const [selectedColor, setSelectedColor] = useState('')
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [canCreate, setCanCreate] = useState(false);
    const theme = useTheme();

    const {t} = useTranslation();

    const colors = ['white', 'gray', 'purple', 'blue', 'light-green', 'green', 'green-yellow', 'yellow', 'orange', 'red', 'bright-purple'];

    useEffect(() => {
        if(!isAdd && editFolder) {
            setName(editFolder.name);
            setSelectedColor(editFolder.color);
        }
    }, [isAdd, editFolder])

    useEffect(() => {

        if(name && selectedColor) {
            setCanCreate(true);
        } else {
            setCanCreate(false);
        }

    }, [selectedColor, name])

    const handleSave = async () => {

        if(!name) {
            setError('Name is required');
            return;
        } else if(!selectedColor) {
            setError('Color is required');
            return;
        }

        setIsLoading(true);
        
        if(isAdd) {
            const response = await sendPost(Urls.CreateFolder, {
                name: name,
                color: selectedColor
            }, true, 'application/json', true);
            if(response.error) {
                setError(response.error);
            } else {
                if(handleSaveModal) {
                    handleSaveModal();
                }
            }
        } else {
            const response = await sendPut(Urls.UpdateFolder(editFolder.id), {
                name: name,
                color: selectedColor
            }, true, 'application/json', true);
            if(response.error) {
                setError(response.error);
            } else {
                if(handleSaveModal) {
                    handleSaveModal();
                }
            }
        }

        setIsLoading(false);
    }

    return (
        <div>
            <div className="modal-overlay">
                <div className={`modal ${theme.getThemeClass()}`}>
                    <div className="modal-header">
                        <div className="title">{t("folder_create_modal_title")}</div>
                        <div className="close-btn" onClick={handleCloseModal}>
                            <MdClose />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label htmlFor="name">{t("folder_create_modal_name")}:</label>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder={t("folder_create_modal_name_placeholder")}
                                className="w-full"
                            />
                        </div>
                        <div className="color-boxes-container">
                            <label>{t("folder_create_modal_select_color")}</label>
                            <div className="color-boxes">
                            {
                                colors.map((color, index) => {
                                    return (
                                        <div className={`color-box ${color === selectedColor ? 'active' : ''}`} key={index} onClick={() => setSelectedColor(color)}>
                                            <div className={`color color-${color}`}></div>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                            {error ? <div className="form-group error">{error}</div> : <></>}
                        </div>
                        <div className="modal-footer">
                            <button className="danger-btn" onClick={handleCloseModal}>
                                {t("cancel")}
                            </button>
                            {isLoading ? (
                                <Loader height={20} barWidth={3} />
                            ) : (
                                <button
                                    className={`primary-btn ${canCreate ? "" : "disabled"}`}
                                    onClick={() => {
                                        if (canCreate) {
                                            handleSave();
                                        }
                                    }}>
                                    {t("save")}
                                </button>
                            )}
                        </div>
                </div>
            </div>
        </div>
    )
}

export default FolderCreateModal;