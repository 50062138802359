import Modal from "./Modal";

function DeleteConfirmationModalComponent({
    isShown,
    onClose,
    message
}) {
    return (
        <Modal isShown={isShown} onClose={onClose} title={'Delete Confirmation'}>
            <Modal.Body>
                <div className="note">
                    {message}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="button-container">
                    <div className="danger-btn" onClick={() => onClose(true)}>
                        Confirm
                    </div>
                    <div className="secondary-btn" onClick={() => onClose(false)}>
                        Cancel
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteConfirmationModalComponent;