const Lights = () => {
    return (
        <div className="lights">
            <div className='rect pos-1'></div>
            <div className='rect pos-2'></div>
            <div className='circle pos-3'></div>
        </div>
    )
}

export default Lights;