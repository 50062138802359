import Mobile from "../../../images/mobile.png";
import Blob from "../../../images/blob.png";

const AnalyticsDashboardFeature = ({section}) => {
    return (
        <div className="analytics-feature-content" data-section={section}>
            <h2 className="heading"><span className="gradient">Analytics Dashboard</span> with Everything you need in one place</h2>
            <div className="mobile-view">
                <img src={Mobile} alt="Mobile" className="mobile"/>
                <img src={Blob} alt="Blob" className="blob"/>
                <div className="tag tag-1">Mobile Responsive</div>
                <div className="tag tag-2">Modern and Sleek Design</div>
                <div className="tag tag-3">Supports 4 different languages</div>
                <div className="tag tag-4">Easy to Use</div>
            </div>
        </div>
    )
}

export default AnalyticsDashboardFeature;