import { useCallback, useEffect, useRef, useState } from "react";
import "../styles/share-trade-page.scss";
import { useParams } from "react-router-dom";
import { sendGet } from "../../common/request";
import Urls from '../../common/links';
import { toast } from "react-toastify";
import currencyFormat from '../../common/format';
import moment from "moment";
import tickData from '../data/tick-data.json';
import RuleShow from '../utils/rule-show';
import { MdSell } from "react-icons/md";
import Checkbox from '../components/checkbox';

function ShareTradePage() {

    const [currentTab, setCurrentTab] = useState('stats');
    const [loading, setIsLoading] = useState(false)
    const {subAccountId, tradeId} = useParams();
    const [tradeData, setTradeData] = useState(undefined);
    const [sharedTrade, setSharedTrade] = useState({});
    const setupTags = useRef([]);
    const mistakeTags = useRef([]);
    const customTags = useRef([]);
    const playbook = useRef(undefined);
    const checkedRules = useRef(0);
    const totalRules = useRef(0);
    const criteriaGroups = useRef([]);
    const tradeState = useRef(RuleShow.Always);
    const [tradeNote, setTradeNote] = useState('');

    const loadData = useCallback(async () => {
        if(tradeId && subAccountId) {
            setIsLoading(true);
            const response = await sendGet(Urls.GetSharedTrade(tradeId, subAccountId), false);

            if(response.error) {
                toast.error(response.error);
            } else {
                console.log(response.trade)
                setSharedTrade(response.trade);
                setTradeData(response.trade.trade.data);
                const tags = response.trade.trade.tags;
                setupTags.current = tags.filter(tag => tag.type === 'Setup'); 
                mistakeTags.current = tags.filter(tag => tag.type === 'Mistake'); 
                customTags.current = tags.filter(tag => tag.type === 'Custom'); 

                if(response.trade.trade.data.PnL > 0) {
                    tradeState.current = RuleShow.TradeWinner;
                } else if(response.trade.trade.data.PnL < 0) {
                    tradeState.current = RuleShow.TradeLoser;
                } else if(response.trade.trade.data.PnL === 0) {
                    tradeState.current = RuleShow.TradeBreakEven;
                }

                if(response.trade.trade.playbooks.length > 0) {
                    totalRules.current = 0;
                    checkedRules.current = 0;
                    playbook.current = response.trade.trade.playbooks[0];
                    criteriaGroups.current = playbook.current.PlaybookTrade.criteriaGroups;
                    criteriaGroups.current.forEach(group => {
                        group.rules.forEach(rule => {
                            totalRules.current += 1;
                            if(rule.checked) {
                                checkedRules.current += 1;
                            }
                        })
                    })
                }
            }

            setIsLoading(false);
        }
    }, [tradeId, subAccountId])

    const loadTradeNote = useCallback(async () => {
        setIsLoading(true);
        const response = await sendGet(Urls.GetNoteForSharedTrade(tradeId, subAccountId), false);
        if(response.error) {
            console.log(response.error);
        } else {
            setTradeNote(response.note);
        }
        setIsLoading(false);
    }, [tradeId, subAccountId]);

    useEffect(() => {
        loadData();
    }, [loadData])

    useEffect(() => {
        loadTradeNote();
    }, [loadTradeNote])

    const changeTab = (tab) => {
        setCurrentTab(tab);
    }

    moment.locale('en')

    const getTickInfo = (trade) => {
        let keys = Object.keys(tickData);
        for(const key of keys) {
            if(trade.SecurityId?.startsWith(key)) {
                const tickInfo = tickData[key];
                return tickInfo;
            }
        }
        return undefined;
    }

    const getInitialTarget = (trade) => {
        const difference = Math.abs(trade.OpenPrice - trade.ProfitTarget);
        const tickInfo = getTickInfo(trade);
        if(tickInfo) {
            return currencyFormat.format(((difference * (1 / tickInfo.tickSize)) * tickInfo.tickValue) * Math.abs(parseInt(trade.OpenVolume)));
        } else {
            return `$0.00`;
        }
    }

    const getTradeRisk = (trade) => {
        const difference = Math.abs(trade.OpenPrice - trade.StopLoss);
        const tickInfo = getTickInfo(trade);

        if(tickInfo) {
            return currencyFormat.format(((difference * (1 / tickInfo.tickSize)) * tickInfo.tickValue) * Math.abs(parseInt(trade.OpenVolume)));
        } else {
            return '$0.00';
        }
    }

    const getNetROI = (trade) => {
        const commission = trade.Commission ? 
            Math.abs(parseFloat(trade.Commission)) : 0;
        const totalInvestment = trade.OpenPrice + commission;
        return (trade.PnL / totalInvestment).toFixed(2);
    }

    const getHoldTime = (trade) => {
        const startTime = moment(trade.OpenTime);
        const endTime = moment(trade.CloseTime);
        const duration = moment.duration(endTime.diff(startTime));
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        if(hours === 0) {
            return `${minutes}m ${seconds}s`;
        } else {
            return `${hours}h ${minutes}m ${seconds}s`;
        }
    }

    return (
        <div className="share-trade-page">
            <div className="header">
                <div className="logo">
                    <img src="/logo.png" alt="Logo" />
                    <div className="text">
                        Trading Prozess
                    </div>
                </div>
            </div>
            {
                tradeData ? 
                <div className="trade-container">
                    <div className="trade-details">
                        <div className="trade-detail-row">
                            <div className="col-1">
                                <div className="title">{tradeData.SecurityId}</div>
                                <div className="date">{moment(tradeData.OpenTime).format('ddd, MMM DD, YYYY')}</div>
                                <div className="date light">06-01-2024</div>
                            </div>
                            <div className="col-2">
                                <div className="label">Net P&L</div>
                                {
                                    sharedTrade.showNetPNL ? 
                                    <div className={`value ${tradeData.PnL >= 0 ? 'positive': 'negative'}`}>{currencyFormat.format(tradeData.PnL)}</div> : <div className="value">--</div>
                                }
                            </div>
                        </div>
                        <div className="tab-container">
                            <div className="tabs">
                                <div className={`tab ${currentTab === 'stats' ? 'active' : ''}`} onClick={() => changeTab('stats')}>Stats</div>
                                {
                                    sharedTrade.showPlaybooks ? 
                                    <div className={`tab ${currentTab === 'playbook' ? 'active' : ''}`} onClick={() => changeTab('playbook')}>Playbook</div> : <></>
                                }
                            </div>
                        </div>
                        {
                            currentTab === 'stats' ? 
                            <div className="trade-detail-items">
                                <div className="trade-detail-item">
                                    <div className="label">Net ROI</div>
                                    <div className="value">{getNetROI(tradeData)}%</div>
                                </div>
                                <div className="trade-detail-item">
                                    <div className="label">Profit Target</div>
                                    <div className="value">{currencyFormat.format(tradeData.ProfitTarget)}</div>
                                </div>
                                <div className="trade-detail-item">
                                    <div className="label">Stop Loss</div>
                                    <div className="value">{currencyFormat.format(tradeData.StopLoss)}</div>
                                </div>
                                <div className="trade-detail-item">
                                    <div className="label">Initial Target</div>
                                    <div className="value">{getInitialTarget(tradeData)}</div>
                                </div>
                                <div className="trade-detail-item">
                                    <div className="label">Trade Risk</div>
                                    <div className="value">{getTradeRisk(tradeData)}</div>
                                </div>
                                <div className="trade-detail-item">
                                    <div className="label">Planned R Multiple</div>
                                    <div className="value">
                                    {
                                        ((tradeData.ProfitTarget - tradeData.OpenPrice) / (tradeData.OpenPrice - tradeData.StopLoss)).toFixed(2)
                                    }
                                    </div>
                                </div>
                                <div className="trade-detail-item">
                                    <div className="label">Realized R Multiple</div>
                                    <div className="value">
                                    {
                                        (tradeData.PnL / (tradeData.OpenPrice - tradeData.StopLoss)).toFixed(2)
                                    }
                                    </div>
                                </div>
                                <div className="trade-detail-item">
                                    <div className="label">Setups</div>
                                    <div className="value">
                                    {
                                        setupTags.current.length === 0 || !sharedTrade.showTags ? '--' :
                                        setupTags.current.map(tag => {
                                            return (
                                                <div className="tag tag-setup">
                                                    <div className="horizontal-container">
                                                        <div className="setup">
                                                            <MdSell />
                                                        </div>
                                                        {tag.name}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                                <div className="trade-detail-item">
                                    <div className="label">Mistakes</div>
                                    <div className="value">
                                    {
                                        mistakeTags.current.length === 0 || !sharedTrade.showTags ? '--' :
                                        mistakeTags.current.map(tag => {
                                            return (
                                                <div className="tag tag-mistake">
                                                    <div className="horizontal-container">
                                                        <div className="mistake">
                                                            <MdSell />
                                                        </div>
                                                        {tag.name}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                                <div className="trade-detail-item">
                                    <div className="label">Custom Tags</div>
                                    <div className="value">
                                    {
                                        customTags.current.length === 0 || !sharedTrade.showTags ? '--' :
                                        customTags.current.map(tag => {
                                            return (
                                                <div className="tag tag-custom">
                                                    <div className="horizontal-container">
                                                        <div className="custom">
                                                            <MdSell />
                                                        </div>
                                                        {tag.name}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                                <div className="trade-detail-item">
                                    <div className="label">Hold Time</div>
                                    <div className="value">{getHoldTime(tradeData)}</div>
                                </div>
                            </div> : 
                            (
                                sharedTrade.showPlaybooks ? 
                                <div className="playbook-panel">
                                    <div className="main-container">
                                        <div className="input-group">
                                            <label>Playbook</label>
                                            <div className="heading">{playbook.current.name}</div>
                                        </div>
                                    </div>
                                    <div className="criteria-groups-container">
                                        <div className="rules-bar">
                                            <div className="title">Followed Rules</div>
                                            <div className="bar-container">
                                                <div className="bar">
                                                    <div className="fill" style={{width: `${(checkedRules.current / (totalRules.current === 0 ? 1 : totalRules.current)) * 100}%`}}></div>
                                                </div>
                                                <div className="label">{checkedRules.current}/{totalRules.current}</div>
                                            </div>
                                        </div>
                                        {
                                            criteriaGroups.current.map((group, index) => {
                                                return (
                                                    <div key={index} className="criteria-group">
                                                        <div className="title">
                                                            {group.title}
                                                        </div>
                                                        <div className="rules">
                                                            {
                                                                group.rules.map((rule, index) => {

                                                                    if(rule.showRule !== RuleShow.Always) {
                                                                        if(rule.showRule !== tradeState.current) {
                                                                            return (<></>)
                                                                        }
                                                                    }

                                                                    return (
                                                                        <div className="rule" key={index}>
                                                                            <Checkbox title={rule.value} value={rule.checked} canChange={false}/>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        
                                    </div> : <></>
                                </div> : <></>
                            )
                        }
                    </div>
                    <div className="notes-container">
                        {
                            sharedTrade.showTradeNotes ? 
                            <div className="editor-note" dangerouslySetInnerHTML={{__html: tradeNote.content}}></div> : <></>
                        }
                    </div>
                </div> : <div className="empty-message">No Trade Found</div>
            }
        </div>
    )
}

export default ShareTradePage;