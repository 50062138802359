import tickData from "../assets/data/tick-data.json";
import numberFormat from "./format";

const getNetROI = (trade) => {
    const adjustedCost = getAdjustedCost(trade);
    return (((trade.PnL - trade.Commission) / adjustedCost) * 100).toFixed(2);
};

const getTickInfo = (trade) => {
    let keys = Object.keys(tickData);
    for (const key of keys) {
        if (trade.SecurityId?.startsWith(key)) {
            return tickData[key];
        }
    }
    return undefined;
};

const getAdjustedCost = (trade) => {
    const tickInfo = getTickInfo(trade);
    if (tickInfo) {
        return (
            trade.OpenPrice *
            (1 / tickInfo.tickSize) *
            tickInfo.tickValue *
            Math.abs(parseInt(trade.OpenVolume))
        );
    } else {
        return (
            trade.OpenPrice *
            Math.abs(parseInt(trade.OpenVolume))
        );
    }
}

const getPlannedRMultiple = (trade) => {
    return parseFloat(((trade.ProfitTarget - trade.OpenPrice) / (trade.OpenPrice - trade.StopLoss)).toFixed(2));
}

const getRealizedRMultiple = (trade) => {
    const initialRisk = (trade.OpenPrice - trade.StopLoss) * trade.OpenVolume;
    const tickData = getTickInfo(trade);
    if(tickData) {
        const adjustedRisk = (initialRisk / tickData.tickSize) * tickData.tickValue;
        return parseFloat((trade.PnL / adjustedRisk).toFixed(2))
    } else {
        return parseFloat((trade.PnL / initialRisk).toFixed(2))
    }
}

const getAveragePlannedRMultiple = (trades) => {
    const rMultiples = [];
    for(let trade of trades) {
        rMultiples.push(getPlannedRMultiple(trade));
    }
    return parseFloat((rMultiples.reduce((total, current) => total + current, 0) / rMultiples.length).toFixed(2));
}

const getAverageRealizedRMultiple = (trades) => {
    const rMultiples = [];
    for(let trade of trades) {
        rMultiples.push(getRealizedRMultiple(trade));
    }
    return parseFloat((rMultiples.reduce((total, current) => total + current, 0) / rMultiples.length).toFixed(2));
}

const getGrossPnL = (trade, isFormatted) => {
    if (isFormatted) {
        return numberFormat.format(trade.PnL + Math.abs(trade.Commission));
    } else {
        return trade.PnL + Math.abs(trade.Commission);
    }
}

export {
    getNetROI,
    getAdjustedCost,
    getTickInfo,
    getPlannedRMultiple,
    getRealizedRMultiple,
    getAveragePlannedRMultiple,
    getAverageRealizedRMultiple,
    getGrossPnL
}