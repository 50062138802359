import "../../styles/InnerPage.scss";
import { useEffect, useState } from "react";
import {sendGet, sendPut} from "../../../common/request";
import links from "../../../common/links";
import Loader from "../../components/Loader";
import { setUser as setReduxUser } from "../../../redux/userActions.js/action";
import { useDispatch } from "react-redux";
import {useTheme} from "../../Providers/ThemeContext";
import Packages from "../../data/packages.js";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import Checkbox from "../../components/checkbox";
import {toast} from "react-toastify";


function SubscriptionPage() {
  let [user, setUser] = useState({});
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [remainingDays, setRemainingDays] = useState(0);
  const theme = useTheme();
  const navigate = useNavigate();

  let loadUser = async () => {
    setIsLoading(true);
    let response = await sendGet(links.GetUser, true);
    if (!response.error) {
      setUser(response.user);
      dispatch(
        setReduxUser({
          user: response.user,
        })
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadUser()
  }, []);

  const toDays = (ms) => {
    return Math.ceil(ms / (1000 * 60 * 60 * 24));
  }

  useEffect(() => {
    if(user && user.subscription) {
      const startDate = new Date(user.subscription.purchaseDate);
      const endDate = new Date(user.subscription.endDate);
      const currentDate = new Date();
      const totalDays = toDays(endDate - startDate)
      const daysRemaining = Math.max(toDays(endDate - currentDate), 0);
      const daysPast = totalDays - daysRemaining;
      const width = (daysRemaining / totalDays) * 100;
      const left = (daysPast / totalDays) * 100
      setRemainingDays(daysRemaining)
      const bar = document.querySelector('.days-bar .bar-fill');
      const legend = document.querySelector('.days-bar .legend');
      if(bar) {
        bar.style.width = `${width}%`;
        bar.style.left = `${left}%`;
      }

      if(legend) {
        legend.style.setProperty('--position', `${left}%`);
        legend.style.setProperty('--transform', `${left <= 6 ? 0 : (left >= 94 ? -100 : -50)}%`);
      }

    }
  }, [user]);

  const userPackage = Packages.filter(pack => pack.name === user.subscription?.package)[0];

  const updateSubscriptionRenewal = async (autoRenew) => {

    setIsLoading(true);
    try {
      const response = await sendPut(links.UpdateSubscriptionRenewal, {autoRenew}, true, 'application/json', true);
      if(response.error) {
        toast.error(response.error)
      }
      else {
        await loadUser();
      }
    }
    catch (error) {
      console.log(error);
      toast.error(error)
    }
    finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading ? (
        <Loader fullScreen={true} />
      ) : (
        <div className={`data-page ${theme.getThemeClass()}`}>
          <div className="data-page-body">
            <div className='subscription-section'>
              <div className="header">
                <div className="heading">Subscription Details</div>
                <div className="buttons">
                  <div className="primary-outline-btn" onClick={() => navigate('/pricing')}>
                    Change Plan
                  </div>
                  <div className="danger-outline-btn">
                    Cancel
                  </div>
                </div>
              </div>
              {
                user.subscription ?
                    <div className="content">
                      <div className="row">
                        <div className="col">
                          <div className="label">
                            Package
                          </div>
                          <div className={`value ${userPackage?.class}`}>
                            {user.subscription.package}
                          </div>
                        </div>
                        <div className="col">
                          <div className="label">
                            Charges
                          </div>
                          <div className="value">
                            <span className='price'>${userPackage?.price[user.subscription.period].toLocaleString()}</span> per {user.subscription.period}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="label">
                            Purchase Date
                          </div>
                          <div className="value">
                            {moment(user.subscription.purchaseDate).format("MMMM DD, YYYY")}
                          </div>
                        </div>
                        <div className="col">
                          <div className="label">
                            {user.subscription.autoRenew ? 'Renewal Date' : 'End Date'}
                          </div>
                          <div className="value">
                            {moment(user.subscription.endDate).format("MMMM DD, YYYY")}
                          </div>
                        </div>
                      </div>
                      <div className="horizontal-container">
                        <Checkbox title={'Auto Renew'} value={user.subscription.autoRenew} onChange={value => updateSubscriptionRenewal(value)} />
                      </div>
                      <div className="days-bar-container">
                        <div className="days-bar">
                          <div className="bar-bg"></div>
                          <div className="bar-fill"></div>
                          <div className="legend">
                            <div className="label">
                              Remaining Days
                            </div>
                            <div className="value">
                              {remainingDays} days
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> :
                    <div className="empty-message">
                      No Subscription Currently Exists for the User
                    </div>
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SubscriptionPage;
