import { setHeader } from "./action";

function createHeader(title = undefined, show = true, backButton = false) {
    return setHeader({
        title: title,
        show: show,
        backButton: backButton
    });
}

export default createHeader;