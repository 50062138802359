import {createContext, useContext} from 'react'

const ThemeContext = createContext({
    theme: 'light',
    changeTheme: () => {},
    getThemeClass: () => {
        return this.theme === 'dark' ? 'dark' : 'light'
    }
});

const useTheme = () => {
    return useContext(ThemeContext);
}

export {
    useTheme
}

export default ThemeContext;