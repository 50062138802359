import Logo from "../../images/logo/logo-dark-small.png";
import {FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter} from "react-icons/fa";
import {useNavigate} from "react-router-dom";

const Footer = () => {

    const navigate = useNavigate();

    return (
        <div className="footer-container front">
            <div className="footer-background">
                <div className="footer">
                    <div className="info-section">
                        <div className="logo">
                            <img src={Logo} alt="Logo"/>
                        </div>
                        <div className="description">
                            Tradingprozess is a trading analysis platform providing keen insights in your trades that
                            help you push your limits and increase your profits
                        </div>
                    </div>
                    <div className="links-section">
                        <div className="links-content">
                            <div className="heading">
                                Links
                            </div>
                            <div className="links">
                                <div className="link" onClick={() => navigate('/')}>Home</div>
                                <div className="link" onClick={() => navigate('/pricing')}>Pricing</div>
                                <div className="link" onClick={() => navigate('/dashboard')}>Dashboard</div>
                            </div>
                        </div>
                    </div>
                    <div className="contact-section">
                        <div className="contact-content">
                            <div className="heading">Contact Us</div>
                            <div className="contact-item">
                                <div className="label">Email</div>
                                <div className="value">contact@tradingprozess.com</div>
                            </div>
                            <div className="contact-item">
                                <div className="label">Social</div>
                                <div className="social-icons">
                                    <div className="social-icon">
                                        <FaFacebookF/>
                                    </div>
                                    <div className="social-icon">
                                        <FaTwitter/>
                                    </div>
                                    <div className="social-icon">
                                        <FaInstagram/>
                                    </div>
                                    <div className="social-icon">
                                        <FaLinkedinIn/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;