export const setTitle = (title) => ({
    type: "SET_TITLE",
    payload: title,
});

export const hideTitle = () => ({
    type: "HIDE_TITLE",
    payload: false,
});

export const setHeader = (payload) => ({
    type: "SET_HEADER",
    payload: payload
})