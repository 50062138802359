import "../styles/landing-page.scss";
import Navbar from "../components/landing-page/Navbar";
import Lights from "../components/landing-page/Lights";
import HeroSection from "../components/landing-page/HeroSection";
import LaptopSection from "../components/landing-page/LaptopSection";
import FeaturesSection from "../components/landing-page/FeaturesSection";
import BrokersSection from "../components/landing-page/BrokersSection";
import WhyUsSection from "../components/landing-page/WhyUsSection";
import PricingSection from "../components/landing-page/PricingSection";
import CTASection from "../components/landing-page/CTASection";
import FAQSection from "../components/landing-page/FAQSection";
import Footer from "../components/landing-page/Footer";
import ScrollToTop from "../components/landing-page/ScrollToTop";

const LandingPage = () => {

    return (
        <div className="website-page">
            <ScrollToTop/>
            <Lights/>
            <Navbar currentPage={'home'}/>
            <div className="sections">
                <div>
                    <HeroSection/>
                    <LaptopSection/>
                </div>
                <FeaturesSection/>
                <BrokersSection/>
                <WhyUsSection/>
                <PricingSection/>
                <CTASection/>
                <FAQSection/>
                <Footer/>
            </div>
        </div>
    )
}

export default LandingPage;