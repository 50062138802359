import { useEffect, useState } from "react";

function Checkbox({title, value = false, onChange = null, canChange= true}) {

    let [checked, setChecked] = useState(value);

    useEffect(() => {
        setChecked(value);
    }, [value])

    let onClick = (e) => {
        if(canChange) {
            e.stopPropagation();
            if(onChange) {
                onChange(!checked);
            }
            setChecked(!checked);
        }
    }

    return (
        <div class="checkbox-wrapper-4" onClick={e => onClick(e)}>
            <input class="inp-cbx" name="Limited" checked={checked} type="checkbox" />
            <label class="cbx">
                <span>
                    <svg width="12px" height="10px">
                        <use xlinkHref="#check-4"></use>
                    </svg>
                </span>
                {title ? <span>{title}</span> : <></>}
            </label>
            <svg class="inline-svg">
                <symbol id="check-4" viewbox="0 0 12 10">
                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                </symbol>
            </svg>
        </div>
    )
}

export default Checkbox;