const FeatureRow = ({section, title, description, image, inverted}) => {
    return (
        <div className="feature-row-container" data-section={section}>
            <div className={`feature-row-background ${inverted ? 'inverted' : ''}`}>
                {
                    inverted ?
                        <div className="feature-row">
                            <div className="image">
                                <img src={image} alt={'Feature'}/>
                            </div>
                            <div className="text-section">
                                <div className="title" dangerouslySetInnerHTML={{__html: title}}></div>
                                <div className="description">{description}</div>
                            </div>
                        </div> :
                        <div className="feature-row">
                            <div className="text-section">
                                <div className="title" dangerouslySetInnerHTML={{__html: title}}></div>
                                <div className="description">{description}</div>
                            </div>
                            <div className="image">
                                <img src={image} alt={'Feature'}/>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

export default FeatureRow