const initialState = {
  shown: false,
};

const permissionModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PERMISSION_DISPLAY":
      return { ...state, shown: action.payload };
    default:
      return state;
  }
};

export default permissionModalReducer;
