import {MdCheck, MdEast} from "react-icons/md";
import {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import packages from "../../data/packages.js";
import UserContext from "../../contexts/UserContext";
import Loader from "../Loader";

const PricingSection = ({canViewFeatureComparison}) => {

    const [currentPeriod, setCurrentPeriod] = useState('month');
    const navigate = useNavigate();
    const userContext = useContext(UserContext);

    const featureComparison = [
        {
            name: "Account Management",
            features: [
                ['Daily Journal', true, true, true],
                ['Accounts', 5, 20, 'Unlimited'],
            ]
        },
        {
            name: "AI Features",
            features: [
                ['AI Alerts', false, true, true],
                ['Daily Goals', false, false, true]
            ]
        }
    ]

    const onGetStarted = (packageName) => {

        let purpose = 'new';

        if(userContext.user && userContext.user.subscription) {
            purpose = 'change'

            if(userContext.user.subscription.package === packageName) {
                return;
            }
        }

        window.location.href = `/payment/${packageName}/${currentPeriod}/${purpose}`;
    }

    return (
        <div className='pricing-section front' id='pricing'>
            <div className="content">
                <div className="title">
                    <div className="heading">Choose Your Plan</div>
                    <div className="description">Choose the plan that best fits your need. You can change the plan anytime you need</div>
                </div>
                <div className="pricing">
                    <div className="button-group rounded">
                        <div className={`button ${currentPeriod === 'month' ? 'active' : ''}`} onClick={() => setCurrentPeriod('month')}>Monthly</div>
                        <div className={`button ${currentPeriod === 'year' ? 'active' : ''}`} onClick={() => setCurrentPeriod('year')} >Yearly</div>
                    </div>
                    <div className="packages">
                        {
                            packages.map((pack, index) => {
                                return (
                                    <div className={`package ${pack.class}`} key={index}>
                                        <div className="package-content">
                                            <div className="top-section">
                                                {
                                                    pack.popular ?
                                                        <div className="tag-container">
                                                            <div className="popular-tag">Popular</div>
                                                        </div> : <></>
                                                }
                                                <div className="title">{pack.name}</div>
                                                <div className="description">{pack.description}</div>
                                            </div>
                                            <div className="price-section">
                                                <div className="price-row">
                                                <span className='price'>${pack.price[currentPeriod].toLocaleString()}</span>
                                                    <span>per {currentPeriod}</span>
                                                </div>
                                                <div className="rounded-button" onClick={() => onGetStarted(pack.name)}>
                                                    {
                                                        userContext.loading ?
                                                            <Loader width={30} height={15} color={pack.color}/> :
                                                            (
                                                                userContext.user && userContext.user.subscription ?
                                                                    (
                                                                        userContext.user.subscription.package === pack.name && userContext.user.subscription.period === currentPeriod ?
                                                                            <span className='text'>Current Plan</span> :
                                                                            <span className='text'>Change Plan</span>
                                                                    ) :
                                                                    <span className='text'>Get Started</span>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                            <div className="included-items">
                                                {
                                                    pack.features.map(feature => {
                                                        return (
                                                            <div className="included-item">
                                                                <div className="icon"><MdCheck/></div>
                                                                {feature}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        canViewFeatureComparison ?
                            <></> :
                            <div className="filled-btn button gradient" onClick={() => navigate('pricing')}>
                                View More <MdEast/>
                            </div>
                    }
                </div>
                {
                    canViewFeatureComparison ?
                        <div className='feature-comparison'>
                            <div className="table">
                                <div className="header">
                                    <div className="item">
                                        <div className="heading">
                                            Feature Comparison
                                        </div>
                                    </div>
                                    <div className="group">
                                        {
                                            packages.map((pack) => {
                                                return (
                                                    <div className={`item ${pack.class}`}>{pack.name}</div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="table-body">
                                    {
                                        featureComparison.map((group, index) => {
                                            return (
                                                <div className="group" key={index}>
                                                    <div className="group-header">
                                                        {group.name}
                                                    </div>
                                                    {
                                                        group.features.map((feature, featureIndex) => {
                                                            return (
                                                                <div className="row" key={`${index}-${featureIndex}`}>
                                                                    {
                                                                        feature.map(feature => {
                                                                            return (
                                                                                <div className="item">
                                                                                    {
                                                                                        typeof feature === 'boolean' ?
                                                                                            (
                                                                                                feature === true ?
                                                                                                    <div className="tick">
                                                                                                        <MdCheck />
                                                                                                    </div> :
                                                                                                    '-'
                                                                                            ) : feature
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div> :
                        <></>
                }
            </div>
        </div>
    )
}

export default PricingSection