import {useEffect, useRef, useState} from "react";
import {MdAdd, MdArrowForward, MdClose, MdDelete, MdEdit} from "react-icons/md";
import Loader from '../Loader';
import EmojiPicker from 'emoji-picker-react';
import SortableContainer from "../sortable-container";
import CriteriaGroupAddModal from "./CriteriaGroupAddModal";
import RuleAddModal from './RuleAddModal';
import {sendPost, sendPut} from '../../../common/request';
import Urls from '../../../common/links';
import ModalMode from "../../utils/modal-mode";
import {useTranslation} from "react-i18next";
import {useTheme} from "../../Providers/ThemeContext";
import useSubAccount from "../../hooks/useSubAccount";

function PlaybookCreateModal({handleCloseModal, handleSaveModal, playbook, mode}) {

    const [isLoading, setIsLoading] = useState(false);
    const [isEmojiBoxShown, setIsEmojiBoxShown] = useState(false);
    const [selectedEmoji, setSelectedEmoji] = useState('');
    const [playbookName, setPlaybookName] = useState('');
    const [playbookDescription, setPlaybookDescription] = useState('');
    const [error, setError] = useState('');
    const [isFirstStep, setIsFirstStep] = useState(true);
    const [isCriteriaCreateModelShown, setIsCriteriaCreateModalShown] = useState(false);
    const [isRuleCreateModalShown, setIsRuleCreateModalShown] = useState(false);
    const [criteriaToAddRule, setCriteriaToAddRule] = useState(undefined);
    const [criteriaToEdit, setCriteriaToEdit] = useState(undefined);
    const [criteriaModalMode, setCriteriaModalMode] = useState('add');
    const [criteriaGroups, setCriteriaGroups] = useState();
    const lastCriteriaId = useRef(2);
    const lastCriteriaRuleId = useRef({});
    const [ruleToEdit, setRuleToEdit] = useState(undefined);
    const [ruleModalMode, setRuleModalMode] = useState(ModalMode.Add);
    const {t} = useTranslation();
    const theme = useTheme();
    const subAccount = useSubAccount();

    useEffect(() => {
        if(mode === ModalMode.Edit) {
            setPlaybookName(playbook.name);
            setPlaybookDescription(playbook.description);
            setSelectedEmoji(playbook.icon);
            //Setting the criteria groups
            const cGroups = playbook.criteriaGroups.map((g, index) => {
                g.order = index + 1;
                if(g.id > lastCriteriaId.current) {
                    lastCriteriaId.current = g.id;
                }
                g.rules = g.rules.map((rule, ruleIndex) => {
                    if(!lastCriteriaRuleId.current[g.id]) {
                        lastCriteriaRuleId.current[g.id] = rule.id;   
                    } else if(rule.id > lastCriteriaRuleId.current[g.id]) {
                        lastCriteriaRuleId.current[g.id] = rule.id;   
                    }
                    rule.order = ruleIndex + 1;
                    return rule;
                })

                return g;
            })
            setCriteriaGroups(cGroups)
        } else {
            setCriteriaGroups([
                {
                    title: 'Entry Criteria',
                    id: 1,
                    order: 1,
                    itemId: 'entry-criteria',
                    rules: []
                },
                {
                    title: 'Exit Criteria',
                    id: 2,
                    order: 2,
                    itemId: 'exit-criteria',
                    rules: []
                },
            ])
        }
    }, [playbook, mode])

    const handleSave = async () => {

        if(!subAccount) {
            return;
        }

        setIsLoading(true);

        //Removing the order attribute from each criteria and rule and storing them in the order they were stored
        const orderedCriteriaGroups = [...criteriaGroups];
        criteriaGroups.forEach(criteria => {
            const criteriaIndex = criteria.order - 1;

            //Ordering the rules as well
            const newRules = [...criteria.rules];
            criteria.rules.forEach(rule => {
                const ruleIndex = rule.order - 1;
                newRules[ruleIndex] = {
                    id: rule.id,
                    value: rule.value,
                    showRule: rule.showRule,
                };
            })

            orderedCriteriaGroups[criteriaIndex] = {
                id: criteria.id,
                title: criteria.title,
                itemId: criteria.itemId,
                rules: newRules
            };
        });

        let response = {};

        if(mode === ModalMode.Add) {
            response = await sendPost(Urls.CreatePlaybook, {
                name: playbookName,
                description: playbookDescription,
                icon: selectedEmoji,
                criteriaGroups: orderedCriteriaGroups,
                subAccountId: subAccount.id
            }, true, 'application/json', true);
        } else {
            response = await sendPut(Urls.UpdatePlaybook(playbook.id), {
                name: playbookName,
                description: playbookDescription,
                icon: selectedEmoji,
                criteriaGroups: orderedCriteriaGroups,
                subAccountId: subAccount.id
            }, true, 'application/json', true);
        }

        if(response.error) {
            setError(response.error);
            console.log(response.error)
        } else {
            if(handleSaveModal) {
                handleSaveModal();
            }
        }
        setIsLoading(false);
    }

    const onNextStep = () => {
        if(!playbookName || !selectedEmoji) {
            setError('Invalid Data. Please enter data for required fields')
            return;
        }
        else {
            setError('');
        }

        setIsFirstStep(false);
    }

    const updateRules = (id, newRules) => {
        const newCriteriaGroups = criteriaGroups.map(group => {
            if(group.id === id) {
                group.rules = newRules;
            }

            return group;
        })
        setCriteriaGroups(newCriteriaGroups);
    }

    const updateCriteriaGroups = (newCriteriaGroups) => {
        setCriteriaGroups(newCriteriaGroups)
    }

    const getItemHeight = (item) => {
        return (item.rules.length * 55) + 100;
    }

    const addCriteriaGroup = () => {
        setCriteriaToEdit({});
        setCriteriaModalMode('add');
        setIsCriteriaCreateModalShown(true);
    }
    
    const handleCriteriaCloseModal = () => {
        setIsCriteriaCreateModalShown(false);
        setCriteriaToEdit(undefined);
        setCriteriaModalMode('add');
    }

    const editCriteria = (criteria) => {
        console.log(criteria)
        setCriteriaToEdit(criteria);
        setCriteriaModalMode('edit');
        setIsCriteriaCreateModalShown(true);
    }

    const handleCriteriaSaveModal = (name) => {
        if(criteriaModalMode === 'add') {
            lastCriteriaId.current += 1;
            setCriteriaGroups([...criteriaGroups, {
                title: name,
                id: lastCriteriaId.current,
                order: criteriaGroups.length + 1,
                itemId: name.toLowerCase().replaceAll(' ', '-'),
                rules: []
            }]);
        } else {
            const newCriteriaGroups = criteriaGroups.map(group => {
                if(group.id === criteriaToEdit.id) {
                    group.title = name;
                }
                return group;
            })
            setCriteriaGroups(newCriteriaGroups);
        }
        handleCriteriaCloseModal();
    }

    const addNewRule = (criteriaId) => {
        setCriteriaToAddRule(criteriaId)
        setIsRuleCreateModalShown(true);
        setRuleModalMode(ModalMode.Add);
    }

    const editRule = (criteriaId, ruleId) => {
        setCriteriaToAddRule(criteriaId)
        criteriaGroups.forEach(group => {
            if(group.id === criteriaId) {
                const ruleToEdit = group.rules.filter(rule => rule.id === ruleId)[0];
                setRuleToEdit(ruleToEdit)
            } 
        });
        setRuleModalMode(ModalMode.Edit);
        setIsRuleCreateModalShown(true);
    }

    const handleRuleModalSave = (name, showRuleWhen) => {

        if(ruleModalMode === ModalMode.Add) {
            //Checking whether the id for the criteria exists or not
            const criteriaRuleIds = lastCriteriaRuleId.current[criteriaToAddRule];
            let ruleId = 1;
            if(!criteriaRuleIds) {
                lastCriteriaRuleId.current[criteriaToAddRule] = ruleId;
            } else {
                lastCriteriaRuleId.current[criteriaToAddRule] = lastCriteriaRuleId.current[criteriaToAddRule] + 1
                ruleId = lastCriteriaRuleId.current[criteriaToAddRule];
            }

            const newCriteriaGroups = criteriaGroups.map(group => {
                if(group.id === criteriaToAddRule) {
                    const totalElements = group.rules.length;
                    group.rules.push({id: ruleId, order: totalElements + 1, value: name, showRule: showRuleWhen});
                }
                return group;
            });
            setCriteriaGroups(newCriteriaGroups);
        } else {
            const newCriteriaGroups = criteriaGroups.map(group => {
                if(group.id === criteriaToAddRule) {
                    group.rules = group.rules.map(rule => {
                        if(ruleToEdit && rule.id === ruleToEdit.id) {
                            rule.value = name;
                            rule.showRule = showRuleWhen;
                        }
                        return rule;
                    })
                }
                return group;
            });
            setCriteriaGroups(newCriteriaGroups);
        }
        handleRuleModalClose();
    }

    const handleRuleModalClose = () => {
        setIsRuleCreateModalShown(false);
        setCriteriaToAddRule(undefined)
        setRuleModalMode(ModalMode.Add);
    }

    const deleteCriteriaGroup = (id) => {
        const criteriaGroupToDelete = criteriaGroups.filter(g => g.id === id)[0];
        let newCriteriaGroups = criteriaGroups.filter(g => g.id !== id);
        newCriteriaGroups = newCriteriaGroups.map(group => {
            if(group.order > criteriaGroupToDelete.order) {
                group.order -= 1;
            }
            return group;
        })
        setCriteriaGroups(newCriteriaGroups);
    }

    const deleteRule = (criteriaId, ruleId) => {
        const newCriteriaGroups = criteriaGroups.map(group => {
            if(group.id === criteriaId) {
                const ruleToDelete = group.rules.filter(rule => rule.id === ruleId)[0];
                group.rules = group.rules.filter(r => r.id !== ruleId);
                group.rules = group.rules.map(rule => {
                    if(rule.order > ruleToDelete.order) {
                        rule.order -= 1;
                    }
                    return rule;
                })
            } 
            return group;
        });
        setCriteriaGroups(newCriteriaGroups);
    }

    return (
        <>
            <div>
                <div className="modal-overlay">
                    <div className={`modal modal-md ${theme.getThemeClass()}`}>
                        <div className="modal-header">
                            <div className="title">{t("create_playbook_modal_heading")}</div>
                            <div className="close-btn" onClick={handleCloseModal}>
                                <MdClose />
                            </div>
                        </div>
                        <div className="modal-body">
                            {
                                isFirstStep ? 
                                <>
                                    <div className="form-group">
                                        <div className="box" onClick={() => setIsEmojiBoxShown(!isEmojiBoxShown)}>
                                            <div className="value">
                                                {selectedEmoji}
                                            </div>
                                            <div className="label">{t("create_playbook_emoji_field")}*</div>
                                        </div>
                                        <div className={`emoji-picker ${isEmojiBoxShown ? 'show' : ''}`}>
                                            <EmojiPicker onEmojiClick={e => setSelectedEmoji(e.emoji)}/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>{t("create_playbook_name_field")}*</label>
                                        <input type="text" name="playbookName" placeholder={t("create_playbook_name_placeholder")} value={playbookName} onChange={e => setPlaybookName(e.currentTarget.value)}/>
                                    </div>
                                    <div className="form-group">
                                        <label>{t("create_playbook_description_field")}</label>
                                        <input type="text" name="playbookDescription" placeholder={t("create_playbook_description_placeholder")} value={playbookDescription} onChange={e => setPlaybookDescription(e.currentTarget.value)}/>
                                    </div>
                                </> : 
                                <div className="trading-playbook">
                                    <div className="header">
                                        <div className="title">{t("create_playbook_rules_heading")}</div>
                                        <div className="primary-btn" onClick={addCriteriaGroup}>
                                            <MdAdd /> {t("create_playbook_create_group")}
                                        </div>
                                    </div>
                                    <div className="criteria-groups">
                                        <SortableContainer items={criteriaGroups}
                                            id={'criteria-group'}
                                            getItemHeight={getItemHeight}
                                            scrollableContainerClass={'criteria-groups'}
                                            renderItem={(item, index) => {
                                                return (
                                                    <div className="criteria-item" key={index}>
                                                        <div className="title">
                                                            {item.title}
                                                            <div className="actions">
                                                                <div className="action" onClick={() => editCriteria(item)}>
                                                                    <MdEdit />
                                                                    {t("edit_action")}
                                                                </div>
                                                                <div className="action delete" onClick={() => deleteCriteriaGroup(item.id)}>
                                                                    <MdDelete /> 
                                                                    {t("delete_action")}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="rules">
                                                            <SortableContainer items={item.rules}
                                                                id={item.itemId}
                                                                getItemHeight={() => 55}
                                                                grabIconInMiddle={true}
                                                                scrollableContainerClass={'criteria-groups'}
                                                                renderItem={(rule, index) => {
                                                                    return (
                                                                        <div className="rule" key={index}>
                                                                            {rule.value}
                                                                            <div className="horizontal-container">
                                                                                <div className="primary-btn" onClick={() => editRule(item.id, rule.id)}>
                                                                                    <MdEdit />
                                                                                </div>
                                                                                <div className="danger-btn" onClick={() => deleteRule(item.id, rule.id)}>
                                                                                    <MdDelete />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }}  updateItems={items => {
                                                                    updateRules(item.id, items);
                                                                }}/>
                                                            <div className="text-btn" onClick={() => addNewRule(item.id)}>
                                                                <MdAdd /> {t("create_playbook_create_rule")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }}  updateItems={items => {
                                                updateCriteriaGroups(items);
                                            }}/>
                                    </div>
                                </div>
                            }
                            {
                                error ? 
                                <div className="error">{error}</div> : <></>
                            }
                        </div>
                        <div className="modal-footer">
                            <button className="danger-btn" onClick={handleCloseModal}>
                                {t("cancel")}
                            </button>
                            {isLoading ? (
                                <Loader height={20} barWidth={3} />
                            ) : (
                                isFirstStep ? 
                                <button className={`primary-btn`} onClick={onNextStep}>
                                    {t("next_action")}
                                    <div className="next-arrow">
                                        <MdArrowForward />
                                    </div>
                                </button> : 
                                <button className={`primary-btn`} onClick={handleSave}>
                                    {t("save")}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {
                isCriteriaCreateModelShown ? 
                <CriteriaGroupAddModal criteriaToEdit={criteriaToEdit} 
                    mode={criteriaModalMode}
                    handleCloseModal={handleCriteriaCloseModal}
                    handleSaveModal={handleCriteriaSaveModal}
                /> : <></>
            }
            {
                isRuleCreateModalShown ? 
                <RuleAddModal handleCloseModal={handleRuleModalClose} 
                    handleSaveModal={handleRuleModalSave}
                    ruleToEdit={ruleToEdit}
                    ruleMode={ruleModalMode}
                /> : <></>
            }
        </>
    )
}

export default PlaybookCreateModal;