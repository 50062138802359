import { IoCloudUploadOutline, IoFileTrayFull } from "react-icons/io5";
import { MdChevronRight, MdClose, MdNotifications } from "react-icons/md";
import "../../App.scss";
import Select from "../components/selectcontrol";
import "../styles/addpage.scss";
import "../styles/common.scss";
import {useDispatch, useSelector} from "react-redux";
import ATASLogo from '../../assets/images/add-trade/atas-logo.jpg';
import ATAS1 from '../../assets/images/add-trade/atas-1.png';
import ATAS2 from '../../assets/images/add-trade/atas-2.png';
import tickSizeData from '../data/tick-data.json';
import createHeader from "../../redux/header/createHeader";
import useSubAccounts from "../hooks/useSubAccounts";
import { useEffect, useState } from "react";
import Brokers from "../enums/brokers";
import Loader from '../components/Loader';
import { toast } from "react-toastify";
import moment from 'moment';
import {sendPost} from '../../common/request';
import Urls from '../../common/links';
import momentTimeZone from 'moment-timezone';
import {getTimezoneOffset} from "../utils/date";
import {useTranslation} from "react-i18next";
import {useTheme} from "../Providers/ThemeContext";

function AddTrade() {

  const [file, setFile] = useState(undefined);
  const {t} = useTranslation();
  const accounts = useSelector(state => state.user?.user);
  const subAccounts = useSubAccounts();
  const subAccountNames = subAccounts ? subAccounts.map(acc => acc.name) : [];
  const [selectedSubAccount, setSelectedSubAccount] = useState();
  const dispatch = useDispatch();
  const brokers = [
    Brokers.Atas,
    Brokers.CQG,
    Brokers.Rithmic,
    Brokers.NinjaTrader
  ];
  const [selectedBroker, setSelectedBroker] = useState(Brokers.Atas);
  const timeZones = momentTimeZone.tz.names().map(timezone => {
    return {
      timezone: timezone,
      offset: getTimezoneOffset(timezone)
    }
  })
  const timeZoneNames = timeZones.map(tz => tz.timezone);
  const [selectedTimeZone, setSelectedTimeZone] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  const requiredFields = {
    [Brokers.Atas]: ['Id', 'SecurityId', 'OpenTime', 'OpenPrice', 'OpenVolume', 'CloseTime', 'ClosePrice', 'CloseVolume', 'PnL', 'Commission'],
    [Brokers.Rithmic]: ['Order Number', 'Symbol', 'Status', 'Buy/Sell', 'Qty To Fill', 'Avg Fill Price', 'Update Time (WEDT)', "Commission Fill Rate"],
    [Brokers.CQG]: ['Symbol', 'Status', 'B/S', 'Qty', 'AvgFillP', 'PlaceT'],
    [Brokers.NinjaTrader]: ['ID', 'Instrument', 'Action', 'Quantity', 'Price', 'Time'],
  };

  const fieldMapping = {
    [Brokers.Rithmic]: {
      'Order Number': 'Id',
      Symbol: 'SecurityId',
      Status: 'Status',
      'Buy/Sell': 'Type',
      'Avg Fill Price': 'Price',
      'Qty To Fill': 'Volume',
      'Update Time (WEDT)': 'Time',
      "Commission Fill Rate": "Commission"
    } ,
    [Brokers.CQG]: {
      Symbol: "SecurityId",
      Status: "Status",
      'B/S': "Type",
      Qty: 'Volume',
      'AvgFillP': 'Price',
      'PlaceT': 'Time'
    },
    [Brokers.NinjaTrader]: {
      ID: 'Id',
      Instrument: "SecurityId",
      Action: "Type",
      Quantity: 'Volume',
      Price: 'Price',
      Time: 'Time'
    },
  }

  const parseCSVData = (line) => {
    const regex = /"([^"]*)"/g;
    const cleanedValues = line.match(regex);
    return cleanedValues.map((value) => value.replaceAll(",", " ").replace(/"/g, "").trim());
  }

  dispatch(createHeader(t("add_trade_btn"), true, true));

  useEffect(() => {
    if(accounts && accounts.user) {
      const index = timeZoneNames.indexOf(accounts.user.timezone);
      if(index >= 0) {
        setSelectedTimeZone(timeZones[index]);
      }
    } else {
      setSelectedTimeZone(timeZones[0])
    }
  }, [accounts])

  useEffect(() => {
    if(subAccounts) {
      setSelectedSubAccount(subAccounts[0].name)
    }
  }, [subAccounts])

  const parseAtasFile = (csvText) => {
    const lines = csvText.split("\n");

    if (lines.length < 4) {
      // Ensure there are enough lines in the CSV
      console.error("Invalid cdb format");
      return [];
    }

    const headers = lines[2].split(",").map((header) => header.trim());

    if (!headers[0].includes('Columns: "Id"')) {
      console.error("Invalid cdb format");
      return [];
    }
    const parsedData = [];

    for (let i = 3; i < lines.length; i++) {
      const values = lines[i].split(",");
      if (values.length !== headers.length) {
        continue;
      }

      const entry = {};
      for (let j = 0; j < headers.length; j++) {
        const cleanedHeader = headers[j]
          .replace(/"/g, "")
          .replace(/Columns: /g, "");
        entry[cleanedHeader] = values[j].trim().replace(/"/g, "");
      }
      parsedData.push(entry);
    }
    return parsedData;
  };

  const parseRithmicFile = (csvText) =>   {
    const lines = csvText.split("\n");
    if (lines.length < 5) {
      console.error("Invalid CSV format");
      return [];
    }

    const headers = lines[5]
      .replace(/"/g, "")
      .split(",")
      .map((header) => header.trim());
    // Using the correct position of headers
    const completedOrders = [];
    for (let i = 6; i < lines.length; i++) {
      const line = lines[i].trim();
      const entry = {};

      if (line) {

        const values = parseCSVData(line);
        if (values.length !== headers.length) {
          continue;
        }

        for (let j = 0; j < headers.length; j++) {
          entry[headers[j]] = values[j];
        }

        completedOrders.push(entry);
      }
    }
    return completedOrders;
  };

  const parseCQGFile = (csvText) => {
    const lines = csvText.split("\n");

    // Extracting headers
    const headerStartIndex = 2;
    const headers = lines[headerStartIndex]
      .replace(/"/g, "")
      .split(";")
      .map((header) => header.trim());

    // remove spaces from headers
    headers.forEach((header, index) => {
      headers[index] = header.replace(/\s+/g, "");
    });
    // Extract date from the first line
    const dateLine = lines[0].trim();
    const dateMatch = dateLine.match(
      /(\d{1,2}[./]\d{1,2}[./]\d{1,2}) \d{1,2}:\d{1,2}:\d{1,2}/
    );
    const reportDate = dateMatch ? dateMatch[1] : null;

    // Using the correct position of headers
    const completedOrders = [];
    for (let i = headerStartIndex + 1; i < lines.length - 1; i++) {
      const line = lines[i].trim();
      const entry = {};

      if (line) {
        const cleanedLine = line.replace(/"/g, ""); // Replace double quotes
        const values = cleanedLine.split(";").map((value) => value.trim());

        if (values.length !== headers.length) {
          continue;
        }

        for (let j = 0; j < headers.length; j++) {
          entry[headers[j]] = values[j];
        }
        // Add date and time to the field
        const date = moment(`${reportDate} ${entry['PlaceT']}`, 'DD.MM.YYYY HH:mm:ss');
        if(!date.isValid()) {
          throw new Error(`Invalid Report Date Found ${reportDate} ${entry['PlaceT']}`);
        }
        entry["PlaceT"] = date.format('MM/DD/YYYY HH:mm:ss');
        completedOrders.push(entry);
      }
    }

    return completedOrders;
  };

  const parseNinjaFile = (csvText) => {
    const lines = csvText.split("\n");

    if (lines.length < 2) {
      console.error("Invalid CSV format");
      return [];
    }

    // Extracting headers
    const headers = lines[0].split(",").map((header) => header.trim());

    // Using the correct position of headers
    const completedOrders = [];

    for (let i = 1; i < lines.length - 1; i++) {
      const line = lines[i].trim();
      const entry = {};

      if (line) {
        const values = line.split(',').map(v => v.trim())

        if (values.length !== headers.length) {
          continue;
        }

        for (let j = 0; j < headers.length; j++) {
          if(headers[j] === 'Commission') {
            entry[headers[j]] = parseFloat(values[j].replace("$", ""))
          } else {
            entry[headers[j]] = values[j];
          }
        }

        completedOrders.push(entry);
      }
    }

    return completedOrders;
  };

  const loadFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = (e) => {
        const content = e.target.result;
        resolve({content: content});
      };

      fileReader.readAsText(file);
    })
  }

  const formatData = (data, broker, timeZone) => {
    if(broker === Brokers.Atas) {

      const entries = data.map(entry => {

        const fieldNames = Object.keys(entry);
        const allowedFieldNames = requiredFields[broker];
        const numberFields = ['OpenPrice', 'ClosePrice', 'PnL', 'Commission'];
        const dateFields = ['OpenTime', 'CloseTime']
        for(const fieldName of fieldNames) {
          if(!allowedFieldNames.includes(fieldName)) {
            delete entry[fieldName];
          }
          else {
            if(numberFields.includes(fieldName)) {
              entry[fieldName] = parseFloat(entry[fieldName]);
            }
            else if (dateFields.includes(fieldName)) {
              const date = moment(entry[fieldName], "MM/DD/YYYY HH:mm:ss");
              if(!date.isValid()) {
                throw new Error(`Invalid Date Found at ${fieldName} with value "${entry[fieldName]}"`);
              }
              entry[fieldName] = date.format("MM/DD/YYYY HH:mm:ss");
            }
          }
        }

        entry['ProfitTarget'] = 0;
        entry['StopLoss'] = 0;
        entry['Side']= entry['OpenVolume'] > 0 ? 'LONG' : 'SHORT';
        return entry;
      });
      return entries;
    }
    else if(broker === Brokers.Rithmic) {

      const entries = data.map(entry => {
        const newEntry = {};
        const fieldNames = Object.keys(entry);
        const allowedFieldNames = requiredFields[broker];
        const fieldNameMapping = fieldMapping[broker];
        const numberFields = ['Price', 'Volume', "Commission"]
        for(const fieldName of fieldNames) {
          if(!allowedFieldNames.includes(fieldName)) {
            delete entry[fieldName];
          } else {
            const mappedFieldName = fieldNameMapping[fieldName];
            let value = entry[fieldName];
            if(mappedFieldName === 'Type') {
              value = value === 'B' ? 'Buy' : 'Sell';
            } else if(mappedFieldName === 'Time') {
              const date = moment(value, ["DD/MM/YYYY HH:mm:ss", "DD/MM/YYYY HH:mm", "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm"]);
              if(!date.isValid()) {
                throw new Error(`Invalid Date Found at ${fieldName} with value ${value}`);
              }
              value = date.format('MM/DD/YYYY HH:mm:ss');
            } else if(numberFields.includes(mappedFieldName)) {
              value = parseFloat(parseFloat(value).toFixed(2));
            }
            newEntry[mappedFieldName] = value;
          }
        }

        newEntry['ProfitTarget'] = 0;
        newEntry['StopLoss'] = 0;
        newEntry['Side']= '';
        return newEntry;
      });

      return entries.filter(entry => entry.Status === 'Filled');
    }
    else if(broker === Brokers.CQG && timeZone) {

      const entries = data.map((entry, index) => {
        const newEntry = {};
        const fieldNames = Object.keys(entry);
        const allowedFieldNames = requiredFields[broker];
        const fieldNameMapping = fieldMapping[broker];
        const numberFields = ['Price', 'Volume']
        for(const fieldName of fieldNames) {
          if(!allowedFieldNames.includes(fieldName)) {
            delete entry[fieldName];
          } else {
            const mappedFieldName = fieldNameMapping[fieldName];
            let value = entry[fieldName];
            if(mappedFieldName === 'Type') {
              value = value === 'BUY' ? 'Buy' : 'Sell';
            } else if(mappedFieldName === 'Price') {
              value = value.replaceAll(',', '.');
              value = parseFloat(value)
            } else if(numberFields.includes(mappedFieldName)) {
              value = parseFloat(value);
            } else if(mappedFieldName === 'Time') {
              const date = moment.tz(value, "MM/DD/YYYY HH:mm:ss", timeZone.timezone).tz(moment.tz.guess());
              if(!date.isValid()) {
                throw new Error(`Invalid Date Found at ${fieldName} with value ${value}`);
              }
              value = date.format('MM/DD/YYYY HH:mm:ss');
            }
            newEntry[mappedFieldName] = value;
          }
        }

        newEntry['Id'] = index + 1;
        newEntry['ProfitTarget'] = 0;
        newEntry['StopLoss'] = 0;
        newEntry['Side']= '';
        return newEntry;
      });

      return entries.filter(entry => entry.Status === 'Filled');
    }
    else if(broker === Brokers.NinjaTrader) {

      const entries = data.map(entry => {
        const newEntry = {};
        const fieldNames = Object.keys(entry);
        const allowedFieldNames = requiredFields[broker];
        const fieldNameMapping = fieldMapping[broker];
        const numberFields = ['Price', 'Volume']
        for(const fieldName of fieldNames) {
          if(!allowedFieldNames.includes(fieldName)) {
            delete entry[fieldName];
          } else {
            const mappedFieldName = fieldNameMapping[fieldName];
            let value = entry[fieldName];
            if(mappedFieldName === 'Time') {
              const date = moment(value, 'DD.MM.YYYY HH:mm:ss');
              if(!date.isValid()) {
                throw new Error(`Invalid Date Found at ${fieldName} with value ${value}`);
              }
              value = date.format('MM/DD/YYYY HH:mm:ss');
            } else if(numberFields.includes(mappedFieldName)) {
              value = parseFloat(value);
            }
            newEntry[mappedFieldName] = value;
          }
        }

        newEntry['ProfitTarget'] = 0;
        newEntry['StopLoss'] = 0;
        newEntry['Side']= '';
        return newEntry;
      });

      return entries;
    } else {
      return data;
    }
  }

  const parseFile = async (file, broker) => {
    const content = await loadFileContent(file);
    let data = undefined;
    if(broker === Brokers.Atas && file.name.endsWith('.cdb')) {
      data = parseAtasFile(content.content);
    } else if(broker === Brokers.Rithmic && file.name.endsWith('.csv')) {
      data = parseRithmicFile(content.content);
    } else if(broker === Brokers.CQG && file.name.endsWith('.csv')) {
      data = parseCQGFile(content.content);
    } else if(broker === Brokers.NinjaTrader && file.name.endsWith('.csv')) {
      data = parseNinjaFile(content.content);
    } else {
      toast.error('Invalid File Type. Please upload the specified file type for a broker')
      return [];
    }

    if(!data) {
      console.error('No Data Found from the file')
      return [];
    } else {
      return data;
    }
  }

  const handleBrowseClick = (e) => {
    const parent = e.currentTarget.parentNode;
    const fileInput = parent.querySelector('input[type="file"]');
    if(fileInput) {
      fileInput.click();
    }
  }

  const handleFileUpload = (e) => {
    const fileInput = e.currentTarget;
    if(fileInput.files.length > 0) {
      const file = fileInput.files[0];
      setFile(file);
    }
  }

  const removeFile = () => {
    setFile(undefined);
  }

  const convertToTrades = (formattedData) => {
    const buyTradesToSettle = {};
    const sellTradesToSettle = {};
    const orderedEntries = formattedData.sort((a, b) => {
      const t1 = moment(a.Time, 'MM/DD/YYYY HH:mm:ss').toDate();
      const t2 = moment(b.Time, 'MM/DD/YYYY HH:mm:ss').toDate();
      const timeDifference = t1.getTime() - t2.getTime();

      if(timeDifference === 0) {
        return parseInt(a.Id) - parseInt(b.Id)
      }
      else {
        return timeDifference;
      }
    });
    const trades = [];
    //Going over each entry
    for(const entry of orderedEntries) {
      const symbol = entry.SecurityId;

      //If the trade is buy
      if(entry.Type === 'Buy') {
        
        //Checking if we have any trade to settle
        //If no trade to settle against. Put the trade in the list
        if(!sellTradesToSettle[symbol] || sellTradesToSettle[symbol].length === 0) {
          if(!buyTradesToSettle[symbol]) {
            buyTradesToSettle[symbol] = [];
          }
          buyTradesToSettle[symbol].push(entry);
          continue;
        }

        let sharesToSettle = entry.Volume;
        let index = 0;
        let tradesSettled = 0;
        //If there are any sell trades available
        for(const sellTrade of sellTradesToSettle[symbol]) {

          const trade = {
            OpenId: sellTrade.Id,
            SecurityId: entry.SecurityId,
            Status: entry.Status,
            Type: entry.Type,
            ProfitTarget: entry.ProfitTarget,
            StopLoss: entry.StopLoss,
            Side: entry.Side,
            CloseTime: entry.Time,
            ClosePrice: entry.Price,
          };

          if(sharesToSettle === 0) {
            break;
          }
          const availableShares = sellTrade.Volume;
          const settledShares = sharesToSettle <= availableShares ? sharesToSettle : availableShares;
          trade.CloseId = entry.Id;
          trade.CloseVolume = settledShares
          trade.OpenVolume = -1 * settledShares;
          trade.OpenPrice = sellTrade.Price;
          trade.OpenTime = sellTrade.Time;
          trade.Side = 'SHORT';
          trade.PnL = (sellTrade.Price - entry.Price) * settledShares;
          trade.Commission = (entry.Commission ? (entry.Commission * settledShares) : 0) + (sellTrade.Commission ? (sellTrade.Commission * settledShares) : 0) ;
          trades.push(trade);

          if(sharesToSettle === availableShares) {
            sellTradesToSettle[symbol].shift();
            for(let i = 0; i < tradesSettled; i += 1) {
              sellTradesToSettle[symbol].shift()
            }
            tradesSettled = 0;
            break;
          } else if(sharesToSettle > availableShares) {
            tradesSettled += 1;
            if(sellTradesToSettle[symbol].length === tradesSettled) {
              let totalSharesSettled = 0;
              for(let i = 0; i < tradesSettled - 1; i += 1) {
                totalSharesSettled += sellTradesToSettle[symbol][i].Volume
                buyTradesToSettle[symbol].shift()
              }
              totalSharesSettled += settledShares;
              sellTradesToSettle[symbol].shift();
              tradesSettled = 0;
              const updatedEntry = {...entry}
              updatedEntry.Volume -= totalSharesSettled;
              buyTradesToSettle[symbol].push(updatedEntry);
            } else {
              sharesToSettle -= settledShares;
            }
          } else if(sharesToSettle < availableShares) {
            sellTradesToSettle[symbol][index].Volume -= settledShares;
            for(let i = 0; i < tradesSettled; i += 1) {
              sellTradesToSettle[symbol].shift()
            }
            tradesSettled = 0;
            break;
          }
          index += 1;
        }

      } else {

        //Checking if we have any trade to settle
        //If no trade to settle against. Put the trade in the list
        if(!buyTradesToSettle[symbol] || buyTradesToSettle[symbol].length === 0) {
          if(!sellTradesToSettle[symbol]) {
            sellTradesToSettle[symbol] = [];
          }
          sellTradesToSettle[symbol].push(entry)
          continue;
        }

        let sharesToSettle = entry.Volume;
        let tradesSettled = 0;
        let index = 0;
        //If there are any sell trades available
        for(const buyTrade of buyTradesToSettle[symbol]) {

          const trade = {
            OpenId: buyTrade.Id,
            SecurityId: entry.SecurityId,
            Status: entry.Status,
            Type: entry.Type,
            ProfitTarget: entry.ProfitTarget,
            StopLoss: entry.StopLoss,
            Side: entry.Side,
            CloseTime: entry.Time,
            ClosePrice: entry.Price,
          };

          if(sharesToSettle === 0) {
            break;
          }
          const availableShares = buyTrade.Volume;
          const settledShares = sharesToSettle <= availableShares ? sharesToSettle : availableShares;
          trade.CloseId = entry.Id;
          trade.CloseVolume = -1 * settledShares
          trade.OpenVolume = settledShares;
          trade.OpenPrice = buyTrade.Price;
          trade.OpenTime = buyTrade.Time;
          trade.Side = 'LONG';
          trade.PnL = (entry.Price - buyTrade.Price) * settledShares;
          trade.Commission = (entry.Commission ? (entry.Commission * settledShares) : 0) + (buyTrade.Commission ? (buyTrade.Commission * settledShares) : 0) ;
          trades.push(trade);

          if(sharesToSettle === availableShares) {
            buyTradesToSettle[symbol].shift();
            for(let i = 0; i < tradesSettled; i += 1) {
              buyTradesToSettle[symbol].shift()
            }
            tradesSettled = 0;
            break;
          } else if(sharesToSettle > availableShares) {
            tradesSettled += 1;
            if(buyTradesToSettle[symbol].length === tradesSettled) {
              let totalSharesSettled = 0;
              for(let i = 0; i < tradesSettled - 1; i += 1) {
                totalSharesSettled += buyTradesToSettle[symbol][i].Volume
                buyTradesToSettle[symbol].shift()
              }
              totalSharesSettled += settledShares;
              buyTradesToSettle[symbol].shift();
              tradesSettled = 0;
              const updatedEntry = {...entry}
              updatedEntry.Volume -= totalSharesSettled;
              sellTradesToSettle[symbol].push(updatedEntry);
            } else {
              sharesToSettle -= settledShares;
            }
          } else if(sharesToSettle < availableShares) {
            buyTradesToSettle[symbol][index].Volume -= settledShares;
            for(let i = 0; i < tradesSettled; i += 1) {
              buyTradesToSettle[symbol].shift()
            }
            tradesSettled = 0;
            break;
          }
          index += 1;
        }

      }
    }
    return trades;
  }

  const adjustTradeValues = (trades) => {
    return trades.map(trade => {

      const symbol = trade.SecurityId;
      const keys = Object.keys(tickSizeData);

      for(const indexSymbol of keys) {
        if(symbol.startsWith(indexSymbol)) {

          let tickData = tickSizeData[indexSymbol];
          trade.PnL = (trade.PnL / tickData.tickSize) * tickData.tickValue;
        }
      }
      return trade;
    });
  }

  const uploadTrades = async (trades) => {

    const selectedAccountIndex = subAccountNames.indexOf(selectedSubAccount);
    const subAccountId = subAccounts[selectedAccountIndex].id;

    const response = await sendPost(Urls.UploadTrades, {
      subUserId: subAccountId,
      TimeZone: selectedTimeZone.timezone,
      data: trades
    }, true, 'application/json', true);

    if(response.error) {
      toast.error(response.error);
    } else {
      toast.success('Trades Added Successfully');
    }
  }

  const handleAddTradeClick = async () => {
    setIsLoading(true);
    let formattedData = undefined;
    let data = undefined;
    try {
      data = await parseFile(file, selectedBroker);
      formattedData = formatData(data, selectedBroker, selectedTimeZone);
      console.log(formattedData)
    }
    catch (error) {
      toast.error(error.message);
      console.log(error)
      setIsLoading(false)
      return;
    }
    let trades = []
    if(selectedBroker !== Brokers.Atas) {
      trades = convertToTrades(formattedData);
      console.log(trades)
      trades = adjustTradeValues(trades);
    } else {
      trades = data;
    }
    await uploadTrades(trades);
    setIsLoading(false);
  }

  return (
    <div className="page-body">
      <div className={`add-page ${theme.getThemeClass()}`}>
        <div className="controls">
          <div className="input-group">
            <label>{t("add_trade_page.fields.select_account")}</label>
            <Select
              onChange={index => setSelectedSubAccount(subAccountNames[index])}
              options={subAccountNames}
              selectedIndex={subAccountNames.indexOf(selectedSubAccount)}/>
          </div>
          <div className="input-group">
            <label>{t("add_trade_page.fields.select_broker")}</label>
            <Select
              options={brokers}
              selectedIndex={brokers.indexOf(selectedBroker)}
              onChange={(index) => {setSelectedBroker(brokers[index])}}
            />
          </div>

          <div className="input-group">
            <label>{t("add_trade_page.fields.timezone")}</label>
            <div className="note">
              {t("add_trade_page.fields.timezone_message")}
            </div>
            <Select
              options={timeZones}
              selectedIndex={timeZoneNames.indexOf(selectedTimeZone?.timezone)}
              onChange={(index) => setSelectedTimeZone(timeZones[index])}
              onOptionRender={(item) => {
                return (
                  <div>
                    ({item.offset}) {item.timezone}
                  </div>
                )
              }}
              onSelectedOptionRender={(item) => {
                return (
                  <div>
                    ({item.offset}) {item.timezone}
                  </div>
                )
              }}
              />
          </div>

          <div className="input-group">
            {file === undefined ? (
              <div>
                <label>{t("add_trade_page.fields.upload.title")}</label>
                <div className="upload-ctrl">
                  <input
                    type="file"
                    onChange={handleFileUpload}
                    accept={selectedBroker === Brokers.Atas ? ".cdb" : '.csv'}
                    style={{ display: "none" }}
                    id="fileInput"
                  />
                  <IoCloudUploadOutline onClick={handleBrowseClick} />
                  <div className="title">
                    {file ? file.name : t("add_trade_page.fields.upload.description")}
                  </div>
                  <div className="btn btn-secondary-outline" onClick={handleBrowseClick}>
                    {t("add_trade_page.fields.upload.button")}
                  </div>
                </div>
              </div>
            ): (
              <div>
                <h2>Uploaded File</h2>
                <div className="file-item">
                  <div className="icon">
                    <IoFileTrayFull />
                  </div>
                  <div className="name">
                    {file ? file.name : ''}
                  </div>
                  <div className="close-icon" onClick={removeFile}>
                    <MdClose />
                  </div>
                </div>
              </div>
            )}
          </div>
          {
            isLoading ? 
            <Loader height={25} barWidth={3}/> :
            <div className="btn btn-primary w-100" onClick={handleAddTradeClick}>
              {t("add_trade_page.fields.add_button")}
            </div>  
          }
        </div>
        <div className="vr"></div>
        <div>
          {selectedBroker === Brokers.Rithmic ? (
            <div className="guide">
              <div className="name">{selectedBroker}</div>
              <div className="note">Help Me Import</div>
              <div className="title">
                How to import trades from Rithmic R Traders
              </div>
              <div className="note">
                To Import your trades from Rithmic R Trader, follow these
                steps!
              </div>
              <div className="steps">
                <div className="step">
                  <MdChevronRight />
                  <div>
                    In R | Trader, go to Recent Orders (or Order History for
                    older trades).
                  </div>
                </div>
                <div className="step">
                  <MdChevronRight />
                  <div>
                    Choose the account you want to export, and select a date.
                  </div>
                </div>
                <div className="step">
                  <MdChevronRight />
                  <div>
                    Right-click anywhere in the column headings in the
                    Completed Orders section, click Add/Remove columns, the
                    necessary columns you need to add, are: "Account",
                    "Status", "Remarks", "Buy/Sell", "Qty To Fill", "Qty
                    Filled", "Symbol", "Avg Fill Price", "Order Number",
                    "Update Time" (for commissions could be "commission fill
                    rate" or "commission") and click OK.
                  </div>
                </div>
                <div className="step">
                  <MdChevronRight />
                  <div>
                    Export as a CSV file. Save the file to your desktop.
                  </div>
                </div>
                <div className="step">
                  <MdChevronRight />
                  <div>
                    Here on the import window, click "Upload File", choose the
                    file you downloaded above, and get your trades imported!
                  </div>
                </div>
              </div>
            </div>
          ) : selectedBroker === Brokers.CQG ? (
            <div className="guide">
              <div className="name">{selectedBroker}</div>
              <div className="note">Help Me Import</div>
              <div className="title">
                How to import trades from {selectedBroker} Desktop to
                TradeZella
              </div>
              <div className="note">
                To Import your trades from CQG Desktop, follow these steps!
              </div>
              <div className="steps">
                <div className="step">
                  <MdChevronRight />
                  <div>
                    In CQG Desktop, find the "Orders Filled"
                    widget, and click "Manage Widget", or the three dot icon,
                    in the upper right corner.
                  </div>
                </div>
                <div className="step">
                  <MdChevronRight />
                  <div>
                    Click "Download orders in view" from the dropdown menu
                  </div>
                </div>
                <div className="step">
                  <MdChevronRight />
                  <div>Save the file in CSV format to your desktop</div>
                </div>
                <div className="step">
                  <MdChevronRight />
                  <div>
                    Here on the the import window, click "Upload File", choose
                    the file you downloaded above, and get your trades
                    imported!
                  </div>
                </div>
              </div>
            </div>
          ) : selectedBroker === Brokers.Atas ? (
            <div className="guide">
              <div className="broker-logo">
                <img src={ATASLogo} alt="ATAS"/>
              </div>
              <div className="title">
                How to import trades from Atas Broker
              </div>
              <div className="note">Info: If you have any questions, please do not hesitate to contact us</div>
              <div className="steps">
                <div className="step">
                  <MdChevronRight />
                  <div>Go to the Atas folder in your Documents and open Database folder</div>
                </div>
                <div className="image">
                  <img src={ATAS1} alt="Atas Step 1"/>
                </div>
                <div className="step">
                  <MdChevronRight />
                  <div>Import the <strong>HistoryMYTrade.cdb</strong> file</div>
                </div>
                <div className="image">
                  <img src={ATAS2} alt="Atas Step 2"/>
                </div>
                <div className="step">
                  <MdChevronRight />
                  <div>Don’t forget to set your <strong>Time Zone</strong></div>
                </div>
              </div>
            </div>
          ) : selectedBroker === Brokers.NinjaTrader ? (
            <div className="guide">
              <div className="name">{selectedBroker}</div>
              <div className="note">Help Me Import</div>
              <div className="title">
                How to import trades from Atas Broker{selectedBroker}
              </div>
              <div className="note">
                To Import your trades from Ninja Traders, follow these steps!
              </div>
              <div className="steps">
                <div className="step">
                  <MdChevronRight />
                  <div>
                    In NinjaTrader's Control Center, go to the Account Performance Tab
                  </div>
                </div>
                <div className="step">
                  <MdChevronRight />
                  <div>
                    Generate a performance history for the account and
                    Instrument you want.
                  </div>
                </div>
                <div className="step">
                  <MdChevronRight />
                  <div>
                    When Ninja Traders has finished, and you see data in the
                    Summary, switch to the Executions tab. You see a list of
                    the trade executions you want to import into Tradingprozess
                  </div>
                </div>
                <div className="step">
                  <div className="font-bold mt-4 mb-2">Note</div>
                </div>
                <div className="step">
                  <MdChevronRight />
                  <div>
                    For Ninja Trade 8-instead of the Account performance tab,
                    instead go to the Trader Summary then the Execution tab.{" "}
                    <br />
                    Please Export your file into a CSV file format instead of
                    EXCEL.
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="guide">
              <div className="name">Rithmic R Trader</div>
              <div className="note">Help Me Import</div>
              <div className="title">
                How to import trades from Rithmic R Traders
              </div>
              <div className="note">
                To Import your trades from Rithmic R Trader, follow these
                steps!
              </div>
              <div className="steps">
                <div className="step">
                  <MdChevronRight />
                  <div>
                    In R | Trader, go to Recent Orders (or Order History for
                    older trades).
                  </div>
                </div>
                <div className="step">
                  <MdChevronRight />
                  <div>
                    Choose the account you want to export, and select a date.
                  </div>
                </div>
                <div className="step">
                  <MdChevronRight />
                  <div>
                    Right-click anywhere in the column headings in the
                    Completed Orders section, click Add/Remove columns, the
                    necessary columns you need to add, are: "Account",
                    "Status", "Remarks", "Buy/Sell", "Qty To Fill", "Qty
                    Filled", "Symbol", "Avg Fill Price", "Order Number",
                    "Update Time" (for commissions could be "commission fill
                    rate" or "commission") and click OK.
                  </div>
                </div>
                <div className="step">
                  <MdChevronRight />
                  <div>
                    Export as a CSV file. Save the file to your desktop.
                  </div>
                </div>
                <div className="step">
                  <MdChevronRight />
                  <div>
                    Here on the import window, click "Upload File", choose the
                    file you downloaded above, and get your trades imported!
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddTrade;
