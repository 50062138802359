import "../styles/no-subscription-display.scss";
import {useNavigate} from "react-router-dom";

const NoSubscriptionDisplay = () => {
    const navigate = useNavigate();
    return (
        <div className="card-container">
            <div className="card">
                <div className="info">Oh!!!! It seems that you do not have an active subscription<br/>Please <strong>Purchase a Subscription</strong> to Use the Platform</div>
                <div className="buttons">
                    <div className="primary-outline-btn" onClick={() => navigate('/pricing')}>
                        View Plans
                    </div>
                    <div className="secondary-btn" onClick={() => navigate(-1)}>
                        Go Back
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoSubscriptionDisplay;