//Storing the token
function storeToken(token) {
    localStorage.setItem('token', token)
}

function getToken() {
    return localStorage.getItem('token');
}

function deleteToken() {
    return localStorage.removeItem('token');
}

export  {storeToken, getToken, deleteToken}