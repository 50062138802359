import {useCallback, useEffect, useRef, useState} from "react";
import "../styles/playbook.scss";
import Loader from '../components/Loader';
import {MdAdd, MdChevronLeft, MdChevronRight, MdContentCopy, MdDelete, MdEdit, MdMenu} from 'react-icons/md'
import Checkbox from '../components/checkbox';
import PlaybookCreateModal from "../components/Modal/PlaybookCreateModal";
import {sendDelete, sendGet, sendPost} from '../../common/request';
import Urls from '../../common/links';
import PlaybookItem from "../components/PlaybookItem";
import ModalMode from '../utils/modal-mode';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "../Providers/ThemeContext";
import createHeader from "../../redux/header/createHeader";
import useSubAccount from "../hooks/useSubAccount";
import {toast} from "react-toastify";
import ActionPermission from "../components/permissions/ActionPermission";
import permissions from "../data/Permissions.json";

function TradePage() {

    const [isLoading, setIsLoading] = useState(false);
    const [isPlaybookCreateModalShown, setIsPlaybookCreateModalShown] = useState(false);
    const [playbookModalMode, setPlaybookModalMode] = useState(ModalMode.Add);
    const [playbooks, setPlaybooks] = useState([]);
    const [playbooksToShow, setPlaybooksToShow] = useState([]);
    const [playbookToEdit, setPlaybookToEdit] = useState(undefined);
    const [selections, setSelections] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [isBulkActionVisible, setIsBulActionVisible] = useState(false);
    const resultsToShow = 9;
    const maxPagesToShow = 5;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState([]);
    const [pagesToShow, setPagesToShow] = useState([]);
    const {t} = useTranslation();
    const menuPosition = useRef({x: 0, y: 0});
    const [isActionMenuShown, setIsActionMenuShown] = useState(false);
    const [menuPlaybook, setMenuPlaybook] = useState(undefined);
    const theme = useTheme();
    const subAccount = useSubAccount();

    const dispatch = useDispatch();
    dispatch(createHeader(t("playbook_page_heading"), true, false));

    useEffect(() => {
        const selectedElements = selections.filter(s => s);

        if(selectedElements.length > 0 && playbooksToShow.length > 0) {
            setIsBulActionVisible(true);
        } else {
            setIsBulActionVisible(false);
        }

        if(selectedElements.length === playbooksToShow.length && playbooksToShow.length > 0) {
            setIsAllSelected(true);
        } else {
            setIsAllSelected(false);
        }
    }, [selections, playbooksToShow])

    const loadPlaybooks = useCallback(async () => {

        if(!subAccount) {
            return;
        }

        setIsLoading(true);
        const response = await sendGet(Urls.GetAllPlaybooks(subAccount.id), true);
        if(response.error) {
            console.log(response.error)
        } else {
            const playbooks = response.playbooks.sort((a, b) => a.id - b.id);
            setPlaybooks(playbooks);

            //Finding the number of pages
            const pages = Math.ceil(response.playbooks.length / resultsToShow);

            const allPages = [];
            for(let i = 1; i <= pages; i+= 1){
                allPages.push(i);
            }
            setTotalPages(allPages);
        }
        setIsLoading(false);
    }, [subAccount])

    useEffect(() => {
        loadPlaybooks();
    }, [loadPlaybooks]);

    useEffect(() => {
        const middle = Math.ceil(maxPagesToShow / 2);
        const pagesBeforeMiddle = totalPages.filter((page, index) => index + 1 < middle);
        const pagesAfterMiddle = totalPages.filter((page, index) => index + 1 >= middle);

        if(pagesBeforeMiddle.length === 0) {
            setPagesToShow(pagesAfterMiddle.filter((p, index) => index < 5));
        } else if(pagesBeforeMiddle.length > 0 && pagesAfterMiddle.length > 1) {
            setPagesToShow([...pagesBeforeMiddle.slice(pagesBeforeMiddle.length > 2 ? pagesBeforeMiddle.length - 2 : 0), ...pagesAfterMiddle.slice(0, pagesAfterMiddle.length > 3 ? 3 : pagesAfterMiddle.length)])
        } else if(pagesAfterMiddle.length === 0) {
            setPagesToShow(pagesBeforeMiddle.slice(pagesBeforeMiddle.length > 5 ? pagesBeforeMiddle.length - 5 : 0))
        }

    }, [totalPages])

    const getResultEnd = useCallback(() => {
        const resultsCanBeShown = currentPage * resultsToShow;
        return resultsCanBeShown > playbooks.length ? playbooks.length : resultsCanBeShown;
    }, [currentPage, playbooks])

    useEffect(() => {
        const startPoint = (currentPage - 1) * resultsToShow + 1;
        const endPoint = getResultEnd();
        const playbooksToShow = playbooks.filter((p, index) => {
            const number = index + 1;
            return number >= startPoint && number <= endPoint;
        })

        if(playbooksToShow.length === 0 && playbooks.length > 0 && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }

        setSelections(playbooksToShow.map(p => false));
        setPlaybooksToShow(playbooksToShow);
    }, [playbooks, currentPage, getResultEnd])

    const addPlaybook = () => {
        setPlaybookToEdit(undefined);
        setPlaybookModalMode(ModalMode.Add);
        setIsPlaybookCreateModalShown(true);
    }

    const duplicatePlaybook = async (playbook) => {
        setIsLoading(true);
        const newPlaybook = {
            ...playbook
        }
        newPlaybook.name = `${playbook.name} (Copy)`;
        const response = await sendPost(Urls.CreatePlaybook, newPlaybook, true, 'application/json', true);
        if(response.error) {
            console.log(response.error);
            toast.error(response.error)
        } else {
            await loadPlaybooks();
        }
        setIsLoading(false);
    }

    const editPlaybook = (playbook) => {
        setPlaybookToEdit({
            name: playbook.name,
            description: playbook.description,
            id: playbook.id,
            icon: playbook.icon,
            criteriaGroups: playbook.criteriaGroups.map(group => {
                return {
                    id: group.id,
                    title: group.title,
                    itemId: group.itemId,
                    rules: group.rules.map(rule => {
                        return {
                            id: rule.id,
                            value: rule.value,
                            showRule: rule.showRule
                        };
                    })
                };
            })
        });
        setPlaybookModalMode(ModalMode.Edit);
        setIsPlaybookCreateModalShown(true);
    }

    const deletePlaybook = async (playbookId) => {

        if(!subAccount) {
            return;
        }

        setIsLoading(true);
        const response = await sendDelete(Urls.DeletePlaybook(playbookId, subAccount.id), undefined, true);
        if(response.error) {
            console.log(response.error);
        } else {
            await loadPlaybooks();
        }
        setIsLoading(false);
    }

    const handleCloseModal = () => {
        setIsPlaybookCreateModalShown(false);
    }

    const handleSaveModal = async () => {
        await loadPlaybooks();
        setIsPlaybookCreateModalShown(false);
    }
    
    const selectPlaybookItem = (index, value) => {
        const newSelections = selections.map((s, i) => i === index ? value : s);
        setSelections(newSelections);
    }

    const selectAllChange = (value) => {
        setSelections(selections.map(s => value));
    }

    const onBulkDelete = async () => {

        if(!subAccount) {
            return;
        }

        const ids = [];
        selections.forEach((s, index) => {
            if(s) {
                ids.push(playbooksToShow[index].id);
            }
        });
        setIsLoading(true);
        const response = await sendDelete(Urls.BulkDeletePlaybook(subAccount.id), {ids: ids}, true);
        if(response.error) {
            console.log(response.error);
        } else {
            await loadPlaybooks();
        }
        setIsLoading(false);
    }

    const changePage = (page) => {
        if(page > 0 && page <= totalPages.length) {
            setCurrentPage(page);
        }
    }

    const onTogglePlaybookMenu = (x, y, playbook) => {
        if(menuPlaybook) {
            if(menuPlaybook.id === playbook.id) {
                if(isActionMenuShown) {
                    setMenuPlaybook(undefined);
                    setIsActionMenuShown(false);
                } else {
                    setMenuPlaybook(playbook)
                    menuPosition.current = {x: x - 75, y: y + 20}
                    setIsActionMenuShown(true);
                }
            } else {
                menuPosition.current = {x: x - 75, y: y + 20}
                setMenuPlaybook(playbook)
            }
        } else {
            setMenuPlaybook(playbook)
            menuPosition.current = {x: x - 75, y: y + 20}
            setIsActionMenuShown(true);
        }
    }

    return (
        <>
            <div className={`menu ${isActionMenuShown ? 'show' : ''}`} style={{top: `${menuPosition.current.y}px`, left: `${menuPosition.current.x}px`}} id='playbook-menu'>
                <ActionPermission className='primary-btn' onClick={() => {setIsActionMenuShown(false); editPlaybook(menuPlaybook);}} permission={permissions.PLAYBOOK.EDIT}>
                    <MdEdit/> {t("edit_action")}
                </ActionPermission>
                <ActionPermission className="primary-btn" onClick={() => {setIsActionMenuShown(false); duplicatePlaybook(menuPlaybook);}} permission={permissions.PLAYBOOK.DUPLICATE}>
                    <MdContentCopy/> {t("duplicate_action")}
                </ActionPermission>
                <ActionPermission className="delete-btn" onClick={() => {setIsActionMenuShown(false); deletePlaybook(menuPlaybook.id);}} permission={permissions.PLAYBOOK.DELETE}>
                    <MdDelete /> {t("delete_action")}
                </ActionPermission>
            </div>
            {
                isPlaybookCreateModalShown ? 
                <PlaybookCreateModal handleCloseModal={handleCloseModal} 
                    handleSaveModal={handleSaveModal} 
                    playbook={playbookToEdit} mode={playbookModalMode} /> : <></>
            }
            {
                isLoading ? 
                <Loader text={'Hold Tight! We are loading the data'} fullScreen={true}/> : 
                <div className={`page playbook-page ${theme.getThemeClass()}`}>
                    <div className="header">
                        <div className="horizontal-container">
                            <div className="title">
                            </div>
                        </div>
                        <div className="horizontal-container">
                            {
                                isBulkActionVisible ? 
                                <div className="danger-btn me-1" onClick={onBulkDelete}>
                                    {t("playbook_bulk_delete")}
                                </div> : <></>
                            }
                            <ActionPermission onClick={addPlaybook} className={'primary-btn'} permission={permissions.PLAYBOOK.CREATE}>
                                <MdAdd/>
                                {t("playbook_create_button")}
                            </ActionPermission>
                        </div>
                    </div>
                    <div className="page-body">
                        <div className="table-container">
                        <div className={`table ${theme.getThemeClass()}`}>
                                <div className="header">
                                    <div className="cell checkbox">
                                        <Checkbox value={isAllSelected} onChange={selectAllChange}/>
                                    </div>
                                    <div className="cell">
                                        {t("playbook_column_title")}
                                    </div>
                                    <div className="cell">
                                        {t("playbook_column_trades")}
                                    </div>
                                    <div className="cell">
                                        {t("playbook_column_total_net_pnl")}
                                    </div>
                                    <div className="cell">
                                        {t("playbook_column_win_rate")}
                                    </div>
                                    <div className="cell">
                                        {t("playbook_column_expectancy")}
                                    </div>
                                    <div className="cell actions">
                                    </div>
                                </div>
                                {
                                    playbooksToShow.length > 0 ? 
                                    playbooksToShow.map((playbook, index) => {
                                        return (
                                            <PlaybookItem playbook={playbook} key={index} selected={selections[index]}
                                                onSelect={(v) => selectPlaybookItem(index, v)}
                                                onToggleMenu={onTogglePlaybookMenu} />
                                        )
                                    }) : 
                                    <div className="empty-message mt-2">
                                        <p>No Playbook found. Please create a new one using <strong>Create Playbook</strong> button</p>
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            playbooksToShow.length > 0 ? 
                            <div className="pagination">
                                <div className="result-display">
                                    {t("playbook_pages_result")}: {((currentPage - 1) * resultsToShow + 1)} - {getResultEnd()} of {playbooks.length} {t("playbooks")}
                                </div>
                                <div className="pages">
                                    <div className={`page-nav-btn prev ${currentPage === 1 ? 'disabled' : ''}`} onClick={() => changePage(currentPage - 1)}>
                                        <MdChevronLeft />
                                    </div>
                                    {
                                        pagesToShow.map((page, index) => {
                                            return (
                                                <div className={`page-btn ${page === currentPage ? 'active' : ''}`} key={index} onClick={() => changePage(page)}>
                                                    {page}
                                                </div>
                                            )
                                        })
                                    }
                                    <div className={`page-nav-btn next ${currentPage === totalPages.length ? 'disabled' : ''}`} onClick={() => changePage(currentPage + 1)}>
                                        <MdChevronRight />
                                    </div>
                                </div>
                            </div>: <></>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default TradePage;