import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const ViewPermission = ({permission, children}) => {

    const {user} = useSelector((state) => state.user)
    const navigate = useNavigate();

    return (
        <>
            {
                user && user.user && user.user.subscription && user.user.subscription.permissions.includes(permission) ?
                    children :
                    <div className="card-container auto">
                        <div className="card">
                            <div className="info">Oh!!!! It seems that you do not have permission to view this resource.<br/>Please <strong>Upgrade</strong> your subscription or <strong>Go Back</strong>
                            </div>
                            <div className="buttons">
                                <div className="primary-outline-btn" onClick={() => navigate('/pricing')}>
                                    Change Plan
                                </div>
                                <div className="secondary-btn" onClick={() => navigate(-1)}>
                                    Go Back
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default ViewPermission;