import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { sendGet, sendPost } from "../../../common/request";
import Urls from "../../../common/links";
import Loader from "../../components/Loader";
import moment from "moment";
import currencyFormat from '../../../common/format';
import {MdDelete} from 'react-icons/md';
import DeleteConfirmationModal from "../../components/Modal/DeleteConfirmationModal";
import { useTranslation } from "react-i18next";
import {useTheme} from "../../Providers/ThemeContext";
import {getNetROI} from "../../../common/methods";
import useSubAccount from "../../hooks/useSubAccount";

function PlaybookExecutions() {
    const [isLoading, setIsLoading] = useState(false);
    const {id} = useParams();
    const [trades, setTrades] = useState([]);
    const dateFormat = 'MMM DD YYYY';
    const [isDeleteConfirmationShown, setIsDeleteConfirmationShown] = useState(false);
    const [tradeToDelete, setTradeToDelete] = useState(-1);
    const {t} = useTranslation();
    const theme = useTheme();
    const subAccount = useSubAccount();

    const loadTrades = useCallback(async () => {

        if(!subAccount) {
            return;
        }

        setIsLoading(true);
        const response = await sendGet(Urls.GetPlaybook(id, subAccount.id), true);
        if(response.error) {
            console.log(response.error);
        } else {
            console.log(response.playbook.trades)
            setTrades(response.playbook.trades);
        }
        setIsLoading(false);
    }, [id, subAccount]);

    useEffect(() => {
        loadTrades();
    }, [loadTrades]);

    const getDate = (date) => {
        return moment(date).format(dateFormat);
    }

    const deleteTrade = (id) => {
        setTradeToDelete(id);
        setIsDeleteConfirmationShown(true);
    }

    const handleCloseModal = () => {
        setIsDeleteConfirmationShown(false);
        setTradeToDelete(-1);
    }

    const confirmDeleteModal = async () => {
        setIsLoading(true);
        const response = await sendPost(Urls.DetachPlaybookTrade(id, tradeToDelete), {}, true, 'application/json', true);
        if(response.error) {
            console.log(response.error);
        } else {
            loadTrades();
        }
        handleCloseModal();
        setIsLoading(false);
    }

    return (
        <>
            {
                isDeleteConfirmationShown ? 
                <DeleteConfirmationModal message={t("playbook_trade_detach_confirmation_message")}
                    handleCloseModal={handleCloseModal} handleConfirmModal={confirmDeleteModal}
                /> : <></>
            }
            {
                isLoading ? 
                <Loader fullScreen={true}/> : 
                <div className="inner-page-container executed-trades-page">
                    <div className={`table ${theme.getThemeClass()}`}>
                        <div className="header">
                            <div className="cell">{t("playbook_executions_column_open_date")}</div>
                            <div className="cell">{t("playbook_executions_column_symbol")}</div>
                            <div className="cell">{t("playbook_executions_column_status")}</div>
                            <div className="cell">{t("playbook_executions_column_close_date")}</div>
                            <div className="cell">{t("playbook_executions_column_entry_price")}</div>
                            <div className="cell">{t("playbook_executions_column_exit_price")}</div>
                            <div className="cell">{t("playbook_executions_column_net_pnl")}</div>
                            <div className="cell">{t("playbook_executions_column_net_roi")}</div>
                            <div className="cell"></div>
                        </div>
                        {
                            trades.length === 0 ? 
                            <div className="empty-message">No trades found for the playbook. Please attach the playbook to trades to view them here</div> :
                            trades.map((trade, index) => {
                                return (
                                    <div className="row">
                                        <div className="cell">{getDate(trade.data.OpenTime)}</div>
                                        <div className="cell">{trade.data.SecurityId}</div>
                                        <div className={`cell`}>
                                            <div className={`status ${trade.data.PnL > 0 ? 'positive' : (trade.data.PnL < 0 ? 'negative' : '')}`}>
                                                {trade.data.PnL > 0 ? 'WIN' : (trade.data.PnL < 0 ? 'LOSS' : 'BREAK EVEN')}
                                            </div>
                                        </div>
                                        <div className="cell">{getDate(trade.data.CloseTime)}</div>
                                        <div className="cell">{currencyFormat.format(trade.data.OpenPrice)}</div>
                                        <div className="cell">{currencyFormat.format(trade.data.ClosePrice)}</div>
                                        <div className={`cell ${trade.data.PnL >= 0 ? 'positive' : 'negative'}`}>{currencyFormat.format(trade.data.PnL)}</div>
                                        <div className={`cell ${getNetROI(trade.data) >= 0 ? 'positive' : 'negative'}`}>{getNetROI(trade.data)}%</div>
                                        <div className="cell">
                                            <div className="danger-text-btn" onClick={() => deleteTrade(trade.id)}>
                                                <MdDelete />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default PlaybookExecutions;