import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {sendPost} from "../../common/request";
import Urls from '../../common/links';
import "../styles/payment-screen.scss";
import Packages from "../data/packages.js";
import {useParams} from "react-router-dom";
import Loader from "../components/Loader";
import UserContext from "../contexts/UserContext";

const stripePromise = loadStripe("pk_test_51LclBYJ91W15khFNocy5OikmAptfxp4rVQTs7iJEYoRMQINlcdLTTki3oDYWHZnhwHGv2eWXYVyhkzLAEcfk854600P1Ys3CoJ");

const CheckoutForm = ({price, period, paymentIntent, packageName, purpose}) => {

    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);

    const onSubmit = async (event) => {
        event.preventDefault();

        if(!stripe || !elements) {
            return;
        }

        setLoading(true)
        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/confirm?paymentIntent=${paymentIntent}&packageName=${packageName}&period=${period}&purpose=${purpose}`
            }
        });
        setLoading(false)
        if(result.error) {
            console.log(result.error);
        }
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="price-row">
                <div className="label">Subscription</div>
                <div className="value">
                    <span className='price'>${price.toLocaleString()}</span> per {period}
                </div>
            </div>
            <PaymentElement/>
            <button disabled={!stripe} className={`${loading ? 'no-hover' : ""}`}>
                {
                    loading ?
                        <Loader height={20} width={30} /> :
                        'Submit'
                }
            </button>
        </form>
    )
}

const PaymentPage = () => {

    const [options, setOptions] = useState({
        appearance: {
            theme: 'night',
            labels: 'floating',
            variables: {
                colorPrimary: '#00ffb3'
            }
        }
    });
    const {packageName, period, purpose} = useParams();
    const [price, setPrice] = useState(0);
    const [paymentIntent, setPaymentIntent] = useState(undefined);
    const loading = useRef(false);
    const userContext = useContext(UserContext);

    const createPaymentIntent = useCallback(async () => {
        if(loading.current) {
            return;
        }

        loading.current = true;
        //TODO: Check for token before sending the request and do not show anything if no token found
        const packageDetails = Packages.filter(pack => pack.name === packageName)[0];
        if(packageDetails) {
            const price = packageDetails.price[period];
            setPrice(price)
            const sessionResponse = await sendPost(Urls.CreatePaymentIntent, {
                price: price * 100,
            }, true, 'application/json', true);
            loading.current = false;
            setOptions({
                ...options,
                clientSecret: sessionResponse.client_secret,
            });
            console.log(sessionResponse.paymentIntent)
            setPaymentIntent(sessionResponse.paymentIntent)
        }
    }, [])

    useEffect(() => {
        if(userContext.user) {
            createPaymentIntent();
        }
    }, [createPaymentIntent, userContext]);

    return (
        <div className='payment-screen'>
            <div className="payment-form">
                {
                    options.clientSecret ?
                        <Elements stripe={stripePromise} options={options}>
                            <CheckoutForm price={price} packageName={packageName} period={period} paymentIntent={paymentIntent} purpose={purpose} />
                        </Elements> : <></>
                }
            </div>
        </div>
    )
}

export default PaymentPage;