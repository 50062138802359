import CTABackground from '../../images/cta-background.png';
import {MdEast} from "react-icons/md";

const CTASection = () => {
    return (
        <div className="cta-section front">
            <img src={CTABackground} alt="CTABackground" className='background' />
            <div className="content">
                <div className="title">
                    Join <span className='primary'>Trading</span> <span className='secondary'>Prozess</span> Now
                </div>
                <div className="description">Analyze your trades and gain insights from the community to improve your trading setups and make more money</div>
                <div className="button filled-btn gradient">
                    Register <MdEast />
                </div>
            </div>
        </div>
    )
}

export default CTASection;