import { MdInfoOutline } from "react-icons/md";
import "../../App.scss";
import { useState } from "react";
import {useTheme} from "../Providers/ThemeContext";

function InfoToolTip({message, isLeft}) {
    let [isTooltipShown, setIsTooltipShown] = useState(false);
    const theme = useTheme();
    let onMouseEnter = () => {
        setIsTooltipShown(true);
    }

    let onMouseLeave = () => {
        setIsTooltipShown(false);
    }

    return (
        <div className={`tooltip-container ${theme.getThemeClass()}`}>
            <div className={`tooltip ${isLeft ? 'left' : 'center'} ${isTooltipShown ? 'show' : 'hide'}`}>
                <div className="message">
                    {message}
                </div>
                <svg className="tooltip-point">
                    <path d="M0 0 L7 6 L15 0 Z"/>
                </svg>
            </div>
            <div className="icon" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <MdInfoOutline />
            </div>
        </div>
    )
}

export default InfoToolTip;