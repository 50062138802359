import '../styles/outer.scss';
import LoginImage from '../images/LoginImage.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import {sendPost} from '../../common/request';
import links from '../../common/links';
import Loader from '../components/Loader';


function ResetPasswordPage() {
    let navigation = useNavigate();
    let parmas = useParams();

    let [newPassword, setNewPassword] = useState('');
    let [confirmNewPassowrd, setConfirmNewPassword] = useState('');
    let [error, setError] = useState('');
    let [isLoading, setIsLoading] = useState(false);

    let resetPassword = async () => {
        if(!parmas.email) {
            setError('Invalid Email');
            return;
        } else if(!parmas.code) {
            setError('Invalid Code');
            return;
        }
        else if(!newPassword) {
            setError('Invalid Password');
            return;
        } else if(newPassword != confirmNewPassowrd) {
            setError('Passwords do not match');
            return;
        }

        setIsLoading(true);
        let response = await sendPost(links.ResetPassword, {email: parmas.email, code: parmas.code, password: newPassword}, false);
        if(response.error){
            setError(response.error);
        } else {
            navigation("/login");
        }
        setIsLoading(false);
    }

    return (
        <div className="page-outer">
            <div className='body'>
                <div className='content'>
                    <div className='rect'></div>
                    <img src={LoginImage} />
                </div>
                <div className='controls'>
                    <div className='title'>
                        Reset Password
                    </div>
                    <div className='input-group'>
                        <label>New Password</label>
                        <input type='password' placeholder='Enter new password' value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                    </div>
                    <div className='input-group'>
                        <label>Confirm Password</label>
                        <input type='password' placeholder='Re-enter new password' value={confirmNewPassowrd} onChange={e => setConfirmNewPassword(e.target.value)} />
                    </div>
                    {
                        error ? 
                        <div className='mt-1 mb-3 error'>
                            {error}
                        </div> : <></>
                    }
                    <div className='input-group'>
                        <div className='btn btn-primary' onClick={resetPassword}>
                            {
                                isLoading ? <Loader color="white" height={20} barWidth={3}/> : 'Reset Password'
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordPage;