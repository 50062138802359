import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SettingsSidebar from "../components/SettingsSidebar";
import "../styles/InnerPage.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { deleteToken } from "../../common/token";
import { useDispatch } from "react-redux";
import createHeader from '../../redux/header/createHeader';

function Settings() {
  let { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigation = useNavigate();
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  let logout = () => {
    deleteToken();
    navigation("/login");
  };
  const dispatch = useDispatch();
  dispatch(createHeader(t("settings_page"), true, false));

  return (
    <div className="inner-page">
      <div className="inner-page-body">
        <div className="inner-side-bar">
          <SettingsSidebar />
        </div>
        <div className="inner-page-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Settings;
