import {useDispatch, useSelector} from "react-redux";
import {setPermissionErrorDisplay} from "../../../redux/permission/action";

const ActionPermission = ({children, className, permission, onClick}) => {

    const {user} = useSelector(state => state.user);
    const dispatch = useDispatch();

    const onComponentClick = () => {
        if(user && user.user && user.user.subscription && user.user.subscription.permissions.includes(permission)) {
            onClick();
        }
        else {
            dispatch(setPermissionErrorDisplay(true));
        }
    }

    return (
        <div onClick={onComponentClick} className={className}>
            {children}
        </div>
    )
}

export default ActionPermission;