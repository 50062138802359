import React, { useCallback, useEffect, useState } from "react";
import { MdUpload, MdOutlinePerson, MdClose } from "react-icons/md";
import Select from "../components/selectcontrol";
import { toast } from "react-toastify";
import { sendGet, sendPost } from "../../common/request";
import Urls from '../../common/links';
import ImagePlaceholder from "../images/image-placeholder.jpg";
import useSubAccount from "../hooks/useSubAccount";
import Loader from "./Loader";
import {useTranslation} from "react-i18next";

export default function AddGroup({ handleChatPage, handleGroupAdd }) {
  const [selectedCustomIndices, setSelectedCustomIndices] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [groupImage, setGroupImage] = useState(undefined);
  const [groupName, setGroupName] = useState('');
  const [groupPrivacy, setGroupPrivacy] = useState('');
  const [fileName, setFileName] = useState(null);
  const [users, setUsers] = useState([]);
  const privacy = ["Private", "Public"];
  const subAccount = useSubAccount();
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslation();

  const loadUsers = useCallback(async () => {
    const response = await sendGet(Urls.GetUsers, true);
    if(response.error) {
      toast.error(response.error);
    } else {
      setUsers(response.users.filter(user => user.id !== subAccount.UserId));
    }
  }, [subAccount])

  useEffect(() => {
    loadUsers();
  }, [loadUsers])

  const handleCreateGroup = async () => {
    setIsLoading(true);
    // Gather user IDs and names from members
    const userIds = selectedCustomIndices.map((index) => users[index].id);

    // Check if group name is entered
    if (!groupName) {
      toast.error("Please enter a group name.");
      return;
    }

    // Check if group privacy is selected
    if (!groupPrivacy) {
      toast.error("Please select group visibility.");
      return;
    }

    let formData = new FormData();
    formData.append("file", groupImage);
    formData.append('name', groupName);
    formData.append('visibility', groupPrivacy);
    formData.append('members', userIds);
    const response = await sendPost(Urls.CreateGroup, formData, true, 'multipart/form-data', false)

    if(response.error) {
      toast.error(response.error);
    } else {
      toast.success('Group Created Successfully');
      handleGroupAdd();
    }
    setIsLoading(false);
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setGroupImage(file)
      setSelectedImage(URL.createObjectURL(file));
      setFileName(file.name);
    }
  };

  const onPrivacyChange = (index) => {
    setGroupPrivacy(privacy[index]);
  }

  const handleRemoveImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className="main-group-container">
      <div className="add-group-container">
        <h1 className="add-group-heading">{t("community.addNewGroup.header")}</h1>
        <div>
          <label className="group-settings-label">{t("community.addNewGroup.groupImage")}</label>
          <div className="upload-group-image" onClick={handleRemoveImage}>
            {selectedImage ? (
              <div className="image-container">
                <img
                  src={selectedImage}
                  alt="Group"
                  className="group-image-preview"
                />
                <p className="name-hidden">{fileName}</p>
                <button className="remove-image-btn">
                  <MdClose />
                </button>
              </div>
            ) : (
              <label htmlFor="fileUpload" className="upload-label">
                <MdUpload /> {t("community.addNewGroup.uploadText")}
              </label>
            )}
            <input
              className="hidden"
              type="file"
              id="fileUpload"
              name="fileUpload"
              onChange={handleImageChange}
            />
          </div>
        </div>
        <div className="input-group">
            <label>{t("community.addNewGroup.groupName.name")}</label>
            <input
              type="text"
              placeholder={t("community.addNewGroup.groupName.placeholder")}
              value={groupName}
              onChange={(t) => setGroupName(t.target.value)}
            />
          </div>
        <div>
          <label className="group-settings-label" htmlFor="group-privacy">
            {t("community.addNewGroup.visibility")}
          </label>

          <Select options={privacy} allowMultiple={false} onChange={onPrivacyChange} selectedIndex={privacy.indexOf(groupPrivacy)} />
        </div>
        <div>
          <label className="group-settings-label">{t("community.addNewGroup.users")}</label>
          <Select
            options={users}
            selectedIndex={selectedCustomIndices}
            allowMultiple={true}
            onChange={(indices) => setSelectedCustomIndices(indices)}
            itemName="Users"
            onSearch={(options, searchText) => {
              return options.filter(option => option.firstName.toLowerCase().includes(searchText) || option.lastName.toLowerCase().includes(searchText) || option.email.toLowerCase().includes(searchText));
            }}
            // selectedOptionBackground={"tag-bg-custom"}
            isSearchable={true}
            onOptionRender={(option) => {
              return (
                <div className="horizontal-containerss">
                  <img className="drop-img" src={option.profileImage ?? ImagePlaceholder} alt="" />
                  <div className="details">
                    <p className="name">{`${option.firstName} ${option.lastName}`}</p>
                    <span className="email ">{option.email}</span>
                  </div>
                </div>
              );
            }}
            onSelectedOptionRender={(option) => {
              return (
                  <div className="horizontal-containerss ">
                  <MdOutlinePerson />
                  {`${option.firstName} ${option.lastName}`}
                </div>
              );
            }}
          />
        </div>
        <div className="button-container">
          <button className="primary-btn" onClick={handleCreateGroup}>
            {
              isLoading ? 
              <Loader color="white" height={20} barWidth={3} /> :
              t("community.addNewGroup.createBtn")
            }
          </button>
          <button className="danger-btn" onClick={handleChatPage}>
            {t("community.addNewGroup.cancelBtn")}
          </button>
        </div>
      </div>
    </div>
  );
}
