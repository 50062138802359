import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  RadialLinearScale,
  Tooltip,
  Title,
  LogarithmicScale
} from "chart.js";
import { useEffect, useState } from "react";
import { Bar, Doughnut, Line, Radar } from "react-chartjs-2";
import {
  MdAdd,
} from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import "../../App.scss";
import ExpandedCalendar from "../components/expandedcalendar";
import "../styles/common.scss";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import InfoToolTip from "../components/InfoToolTip";
import DataTooltip from "../components/ToolTip";
import { useTranslation } from "react-i18next";
import links from "../../common/links";
import Loader from "../components/Loader";
import createHeader from "../../redux/header/createHeader";
import {sendGet} from "../../common/request";
import languageData from "../data/languageData.json";
import currencyFormat from '../../common/format';
import {useTheme} from "../Providers/ThemeContext";

ChartJS.register(
  ArcElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  BarElement,
  Filler,
  RadialLinearScale,
  Tooltip,
  LogarithmicScale
);

function Dashboard() {
  // const [selectedAccount, setSelectedAccount] = useState("");
  const selectedAccount = useSelector(
    (state) => state.accounts.selectedAccounts
  );
  let { t } = useTranslation();
  // const [accounts, setAccounts] = useState([]);
  const accounts = useSelector((state) => state?.user?.user);
  const [economicCalendarSettings, setEconomicCalendarSettings] = useState(undefined);
  const [data, setData] = useState([]);
  const [totalPnl, setTotalPnl] = useState(0);
  const [positivePnl, setPositivePnl] = useState(0);
  const [negativePnl, setNegativePnl] = useState(0);
  const [numberOfPositiveDays, setNumberOfPositiveDays] = useState(0);
  const [numberOfNegativeDays, setNumberOfNegativeDays] = useState(0);
  const [numberOfBreakEvenDays, setNumberOfBreakEvenDays] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const selectedDate = useSelector((state) => state.date.selectedDate);
  const endDate = useSelector((state) => state.date.endDate);
  const theme = useTheme();
  const [dailyProfit, setDailyProfit] = useState([]);
  const [dailyLoss, setDailyLoss] = useState([]);
  let navigation = useNavigate();
  const [goalData, setGoalData] = useState({})
  const green = "#29D6A8";
  const red = "#ec787d";

  const dispatch = useDispatch();
  dispatch(createHeader(t("dashboard_page"), true, false))

  let UsDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  let profitFactorData = {
    labels: [""],
    datasets: [
      {
        data: [
          positivePnl !== 0
            ? (positivePnl / (positivePnl + Math.abs(negativePnl))) * 100
            : 0,
          negativePnl !== 0
            ? (negativePnl / (positivePnl + Math.abs(negativePnl))) * 100
            : positivePnl == 0
            ? 100
            : 0,
        ],
        backgroundColor: [green, red],
        circumference: 180,
        rotation: 270,
        borderColor: theme.theme === 'dark' ? '#111013' : '#FFF'
      },
    ],
  };

  let daysWinData = {
    labels: [""],
    datasets: [
      {
        data: [
          numberOfPositiveDays !== 0
            ? (numberOfPositiveDays /
                (numberOfPositiveDays + numberOfNegativeDays)) *
              100
            : 0,
          numberOfNegativeDays !== 0
            ? (numberOfNegativeDays /
                (numberOfPositiveDays + numberOfNegativeDays)) *
              100
            : numberOfPositiveDays == 0
            ? 100
            : 0,
        ],
        backgroundColor: [green, red],
        circumference: 180,
        rotation: 270,
        borderColor: theme.theme === 'dark' ? '#111013' : '#FFF'
      },
    ],
  };

  const subUserId = accounts?.user?.subAccounts?.filter(
      (account) => account?.name === selectedAccount
    )[0]?.id
      ? accounts?.user?.subAccounts?.filter(
          (account) => account?.name === selectedAccount
        )[0]?.id
      : accounts?.user?.subAccounts?.[0]?.id;

  const loadEconomicCalendarSettings = async () => {
    const response = await sendGet(links.GetEconomicCalendar, true);
    if(response.error) {
      console.log(response.error)
    } else {
      setEconomicCalendarSettings(response.settings)
    }
  }

  useEffect(() => {
    async function fetchSubAccounts() {
      setIsLoading(true);
      try {
        let token = localStorage.getItem("token");
        const response = await fetch(links.GetTrades(subUserId), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();

        let dailyProfitObj = {};
        let dailyLossObj = {};
        let days = [];
        // Step 2: Calculate daily profit and loss
        data.forEach((item) => {
          const closeDate = moment(item.data.CloseTime).format("DD/MM/YYYY");
          const pnl = parseFloat(item.data.PnL);
          if (!isNaN(pnl)) {
            if (!days.includes(closeDate)) days.push(closeDate);

            if (pnl > 0) {
              // Positive PnL
              dailyProfitObj[closeDate] =
                (dailyProfitObj[closeDate] || 0) + pnl;
            } else if (pnl < 0) {
              // Negative PnL
              dailyLossObj[closeDate] = (dailyLossObj[closeDate] || 0) + pnl;
            }
          }
        });

        // Filter data for the specific subUserId
        const numberOfPositiveDays = [];
        const numberOfNegativeDays = [];
        const numberOfBreakEvenDays = [];
        const NewSelecteddate = new Date(selectedDate);
        const NewEndDate = new Date(endDate);
        days.map((t) => {
          let date = moment(t, "DD/MM/YYYY").toDate();
          if (date >= NewSelecteddate && date <= NewEndDate) {
            let profit = dailyProfitObj[t];
            let loss = dailyLossObj[t];
            if (profit && !loss) {
              numberOfPositiveDays.push(t);
            } else if (!profit && loss) {
              numberOfNegativeDays.push(t);
            } else {
              if (profit + loss > 0) {
                numberOfPositiveDays.push(t);
              } else if(profit + loss < 0) {
                numberOfNegativeDays.push(t);
              } else {
                numberOfBreakEvenDays.push(t);
              }
            }
          }
        });

        const userData = data.filter((item) => item.subUserId === subUserId);

        if (data) {
          setData(data);
          let positivePnl = 0;
          let negativePnl = 0;

          const totalPnls = data.reduce((total, item) => {
            const openDates = new Date(item?.data?.OpenTime).getTime();
            const closeDates = new Date(item?.data?.CloseTime).getTime();
            let NewEndDate = new Date(endDate).getTime();
            const NewSelecteddate = new Date(selectedDate).getTime();

            if (endDate == undefined) {
              return total;
            } else if (NewEndDate == NewSelecteddate) {
              let date = new Date(endDate);
              date.setHours(24);
              NewEndDate = date.getTime();
            }

            if (
              item?.data &&
              item?.data?.PnL !== undefined &&
              openDates >= NewSelecteddate &&
              closeDates <= NewEndDate
            ) {
              const pnl = parseFloat(item?.data?.PnL);
              // Check if pnl is a number before adding to total
              if (!isNaN(pnl)) {
                if (pnl > 0) {
                  positivePnl += pnl;
                } else {
                  negativePnl += pnl;
                }
                return total + pnl;
              } else {
                console.error("Invalid PnL value:", pnl);
                return total;
              }
            } else {
              console.error(
                "PnL is not available or not in the selected range:",
                item.data
              );
              return total;
            }
          }, 0);

          const dailyProfitArray = Object.entries(dailyProfitObj).map(
            ([day, pnl]) => ({
              day,
              pnl,
            })
          );

          const dailyLossArray = Object.entries(dailyLossObj).map(
            ([day, pnl]) => ({
              day,
              pnl,
            })
          );

          setNumberOfPositiveDays(numberOfPositiveDays.length);
          setNumberOfNegativeDays(numberOfNegativeDays.length);
          setNumberOfBreakEvenDays(numberOfBreakEvenDays.length)
          profitFactorData = {
            labels: [""],
            datasets: [
              {
                data: [
                  positivePnl !== 0 ? positivePnl : 100,
                  negativePnl !== 0 ? negativePnl : 100,
                ],
                backgroundColor: ["#FF9326", "#DEDDDF"],
                circumference: 180,
                rotation: 270,
              },
            ],
          };

          daysWinData = {
            labels: [""],
            datasets: [
              {
                data: [
                  numberOfPositiveDays.length !== 0
                    ? numberOfPositiveDays.length
                    : 100,
                  numberOfNegativeDays.length !== 0
                    ? numberOfNegativeDays.length
                    : 100,
                ],
                backgroundColor: ["#FF9326", "#DEDDDF"],
                circumference: 180,
                rotation: 270,
              },
            ],
          };

          setPositivePnl(positivePnl);
          setNegativePnl(negativePnl);
          setTotalPnl(parseFloat(totalPnls.toFixed(2)));
          setDailyProfit(dailyProfitArray.sort((d1, d2) => {
            return moment(d1.day, "DD/MM/YYYY").toDate() - moment(d2.day, "DD/MM/YYYY").toDate()
          }));
          setDailyLoss(dailyLossArray.sort((d1, d2) => {
            return moment(d1.day, "DD/MM/YYYY").toDate() - moment(d2.day, "DD/MM/YYYY").toDate()
          }));
        } else {
          // If no data is found for the subUserId, set totalPnl to 0
          setTotalPnl(0);
          setPositivePnl(0);
          setNegativePnl(0);
          setNumberOfPositiveDays(0);
          setNumberOfNegativeDays(0);
          setDailyProfit([]);
          setDailyLoss([]);
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching sub accounts:", error);
      } finally {
        setIsLoading(false);
      }
    }

    loadEconomicCalendarSettings();
    fetchSubAccounts();
  }, [
    accounts?.user?.subAccounts,
    data?.length,
    data?.subUserId,
    selectedAccount,
    subUserId,
    selectedDate,
    endDate,
  ]);

  let donutData = {
    labels: [""],
    datasets: [
      {
        data: [
          positivePnl !== 0
            ? (positivePnl / (positivePnl + negativePnl)) * 100
            : 0,
          negativePnl !== 0
            ? (negativePnl / (positivePnl + negativePnl)) * 100
            : positivePnl == 0
            ? 100
            : 0,
        ],
        backgroundColor: [green, red],
        borderColor: theme.theme === 'dark' ? '#111013' : '#FFF'
      },
    ],
  };

  // let calendarData = { "2023-11-5": 45,  "2023-11-12": -10}
  const calendarData = data.reduce((calendarData, item) => {
    const closeDate = new Date(item.data.CloseTime).toLocaleDateString();
    const pnl = parseFloat(item.data.PnL);
    if (!isNaN(pnl)) {
      calendarData[closeDate] = (calendarData[closeDate] || 0) + pnl;
    }
    return calendarData;
  }, {});

  const gridColor = theme.theme === 'dark' ? "#4A484F" : '#DEDDDF';
  let lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tension: 0.3,
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
          tickColor: gridColor,
        },
      },
      y: {
        border: {
          color: gridColor
        },
        grid: {
          color: gridColor,
          tickColor: gridColor,
        },
        ticks: {
          callback: function(value, index, ticks) {
            return currencyFormat.format(value);
          }
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const NewSelecteddate = new Date(selectedDate);
  const NewEndDate = new Date(endDate);

  const primary = "#00ffb3";

  let labels = dailyProfit
      .filter(day => {
        const date = moment(day.day, "DD/MM/YYYY").toDate()
        return date >= NewSelecteddate && date <= NewEndDate
      })
      .map(entry => entry.day);

  dailyLoss
      .filter(day => {
        const date = moment(day.day, "DD/MM/YYYY").toDate()
        return date >= NewSelecteddate && date <= NewEndDate
      })
      .forEach(day => {
        if(!labels.includes(day.day)) {
          labels.push(day.day);
        }
      });

  labels = labels.sort((d1, d2) => {
    return moment(d1, "DD/MM/YYYY").toDate() - moment(d2, "DD/MM/YYYY").toDate();
  })

  const p = {};
  const n = {};
  dailyProfit?.forEach((day) => {
    p[day.day] = day.pnl;
  });
  dailyLoss?.forEach((day) => {
    n[day.day] = day.pnl;
  });

  let lineData;
  lineData = {
    labels,
    datasets: [
      {
        label: "Balance",
        data: labels.map((day) => {
            return p[day] || 0;
          }),
        borderColor: green,
        pointBackgroundColor: green,
      },
      {
        label: "Deposit/Withdrawal",
        data: labels.map((day) => {
          return n[day] || 0;
        }),
        borderColor: red,
        pointBackgroundColor: red,
      },
    ],
  };

  let barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
          tickColor: gridColor
        },
      },
      y: {
        grace: "1",
        border: {
          color: gridColor
        },
        grid: {
          color: gridColor,
          tickColor: gridColor,
        },
        ticks: {
          callback: function(value, index, ticks) {
            return currencyFormat.format(value);
          }
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const formatData = (labels, p, n) => {
    const data = [];
    for (let i = 0; i < labels.length; i++) {
      const profit = p[labels[i]]
      const loss = n[labels[i]];
      data.push((profit || 0) + (loss || 0));
    }
    return data;
  };
  const barChartData = {
    labels,
    datasets: [
      {
        label: "Daily P",
        data: formatData(labels, p, n),

        backgroundColor: [],
        borderRadius: 5,
      },
    ],
  };

  // Dynamically set the background color based on positive or negative value
  barChartData.datasets[0].backgroundColor = barChartData.datasets[0].data.map(
    (value) => (value > 0 ? green : red)
  );

  let radarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      r: {
        min: 0,
        max: 100,
        grace: 1,
        beginAtZero: true,
        angleLines: {
          color: gridColor
        },
        grid: {
          color: gridColor,
        },
        ticks: {
          display: false,
          stepSize: 20,
        },
        pointLabels: {
          color: gridColor,
          font: {
            size: 14,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const netCumulativeData = {
    labels,
    datasets: [
      {
        label: "Balance",
        data: formatData(labels, p, n),
        borderColor: primary,
        pointBackgroundColor: primary,
      },
    ],
  };

  // Calculate the cumulative sum to start from the top
  const cumulativeDatas = netCumulativeData.datasets[0].data.reduce(
    (acc, value, index) => {
      acc.push(index === 0 ? value : value + acc[index - 1]);
      return acc;
    },
    []
  );

  netCumulativeData.datasets[0].data = cumulativeDatas;

  const areaData = {
    labels,
    datasets: [
      {
        label: "Balance",
        data: formatData(labels, p, n),
        borderColor: red,
        backgroundColor: "rgba(255, 0, 0, 0.2)",
        pointBackgroundColor: red,
        fill: true,
      },
    ],
  };

  // Calculate the cumulative sum to start from the top
  areaData.datasets[0].data = areaData.datasets[0].data.reduce(
      (acc, value, index) => {
        const updatedValue = Math.min(value, 0);
        const previousValue = Math.min(acc[index - 1], 0);
        acc.push(index === 0 ? updatedValue : updatedValue + previousValue);
        return acc;
      },
      []
  );

  // calculate win percentage between positivePnl and negativePnl
  const winPercentage = (
    positivePnl === 0
      ? 0
      : negativePnl === 0
      ? 100
      : (positivePnl / (positivePnl + Math.abs(negativePnl))) * 100
  ).toFixed(0);

  let mapToRange = (val, in_min, in_max, out_min, out_max) => {
    return (((val - in_min) * (out_max - out_min)) / (in_max - in_min)) + out_min;
  };

  const avgWin = numberOfPositiveDays > 0 ? positivePnl / numberOfPositiveDays : positivePnl;
  const avgLoss  = numberOfNegativeDays > 0 ? Math.abs(negativePnl) / numberOfNegativeDays : Math.abs(negativePnl);
  let averageWinLoss = avgLoss > 0 ? avgWin / avgLoss : avgWin;
  const MAX_AVERAGE_WIN_LOSS = 20
  const ratio = averageWinLoss;
  averageWinLoss = Math.max(parseFloat(((averageWinLoss / MAX_AVERAGE_WIN_LOSS) * 100).toFixed(2)), 0);
  let profitFactor = negativePnl === 0 ? 0 : Math.abs(positivePnl / negativePnl);
  const MAX_PROFIT_FACTOR = 20;
  profitFactor = Math.max(parseFloat(((profitFactor / MAX_PROFIT_FACTOR) * 100).toFixed(2)), 0);

  const radarData = {
    labels: ["Avg Win/Loss", "Profit Factor", "Win%"],
    datasets: [
      {
        label: "",
        data: [
          averageWinLoss,
          profitFactor,
          winPercentage,
        ],
        backgroundColor:
          positivePnl >= 0
            ? "rgba(41, 214, 168, 0.2)"
            : "rgba(236, 120, 125, 0.2)",
        borderColor:
          positivePnl >= 0 ? "rgba(41, 214, 168, 1)" : "rgba(41, 214, 168, 1)",
        borderWidth: 1,
      },
    ],
  };

  let score = 0;
  score = ((averageWinLoss + profitFactor + winPercentage) / 3).toFixed(2);

  const filteredData = data.filter((item) => {
    const closeDate = new Date(item.data.CloseTime);
    let calendarSelectedDate = new Date(selectedDate);
    let calendarEndDate = new Date(endDate);
    return closeDate >= calendarSelectedDate && closeDate <= calendarEndDate;
  });

  const dateCounts = {};

  data.forEach((item) => {
    const openDate = moment(item.data.OpenTime).format("DD/MM/YYYY");
    const closeDate = moment(item.data.CloseTime).format("DD/MM/YYYY");

    if (
      (new Date(openDate) >= new Date(selectedDate) &&
        new Date(openDate) <= new Date(endDate)) ||
      (new Date(closeDate) >= new Date(selectedDate) &&
        new Date(closeDate) <= new Date(endDate))
    ) {
      // Increment count for open date
      dateCounts[openDate] = (dateCounts[openDate] || 0) + 1;

      // Increment count for close date
      dateCounts[closeDate] = (dateCounts[closeDate] || 0) + 1;
    }
  });

  function getDataLengthPerDate(data) {
    const dateCounts = {};
    data.forEach((item) => {
      // const openDate = new Date(item.data.OpenTime).toLocaleDateString();
      const closeDate = new Date(item.data.CloseTime).toLocaleDateString();
      // // Increment count for open date
      // dateCounts[openDate] = (dateCounts[openDate] || 0) + 1;
      // Increment count for close date
      dateCounts[closeDate] = (dateCounts[closeDate] || 0) + 1;
    });
    return dateCounts;
  }

  // Example usage
  const dataLengthPerDate = getDataLengthPerDate(data);

  const sortedData = data
    .filter((item) => item?.data?.PnL !== undefined)
    .sort((a, b) => {
      const dateA = new Date(a?.data?.OpenTime).setHours(0, 0, 0, 0);
      const dateB = new Date(b?.data?.OpenTime).setHours(0, 0, 0, 0);
      return dateA - dateB;
  });

  const getDailyProfitGoalStatus = async () => {
    const response = await sendGet(links.GetDailyProfitGoalStatus(subUserId), true);
    if(response.error) {
      console.log(response.error)
    }
    else {
      setGoalData(response);
    }
  }

  useEffect(() => {
    if(subUserId) {
      getDailyProfitGoalStatus();
    }
  }, [subUserId]);

  return (
    <>
      {isLoading ? (
        <Loader
          fullScreen={true}
          text={"Hang Tight! We are loading the data"}
        />
      ) : (
          <div className={`page-body ${theme.getThemeClass()}`}>
            <div className="flex-horizontal">
              <div className='dashboard-header'>
                <div className="header">
                  {t("welcome_text")} {accounts?.user?.lastName}
                </div>
                {
                  goalData.tradesCount ?
                      <div className="message">
                        Today, you made {goalData.tradesCount} trades. Your daily profit goal of {currencyFormat.format(goalData.dailyProfitGoal)} has {goalData.goalAchieved ? 'been achieved' : 'not been achieved'}
                      </div> : <></>
                }
              </div>
              <div className="btn btn-primary" onClick={() => navigation("/addtrade")}>
                <MdAdd/>
                {t("add_trade_btn")}
              </div>
            </div>
            {/* Top 5 Components */}
            <div className="grid grid-col-3 mt-2 top-row">
              <div className="grid grid-row-2">
                <div className="stat-item item">
                  <div className="label">
                    {t("net_p&l_heading")}
                    <InfoToolTip message={t("net_p&l_tooltip")}/>
                    <DataTooltip message={t("net_p&l_total_trades")}>
                      <div className="dark">
                        {filteredData?.length ? filteredData?.length : 0}
                      </div>
                    </DataTooltip>
                  </div>
                  <div
                      className={`value ${
                          totalPnl !== undefined
                              ? totalPnl >= 0
                                  ? "green"
                                  : "red"
                              : ""
                      }`}
                  >
                    {currencyFormat.format(totalPnl)}
                  </div>
                </div>
                <div className="stat-item item">
                  <div className="label">
                    {t("average_win_loss_heading")}
                    <InfoToolTip message={t("average_win_loss_tooltip")}/>
                  </div>
                  <div className="flex-horizontal">
                    <div
                        className={`value ${
                            ratio !== undefined ? (ratio >= 0 ? "green" : "red") : ""
                        }`}
                    >
                      {ratio.toFixed(2)}
                    </div>
                    <div className="bar">
                      <div
                          className={`fill-bar ${
                              ratio !== undefined
                                  ? ratio >= 0
                                      ? "green"
                                      : "red"
                                  : ""
                          }`}
                          style={{
                            width: `${winPercentage}%`,
                          }}
                      ></div>
                      <div className="flex-horizontal mnt-3 chart-label">
                        <div>{currencyFormat.format(positivePnl)}</div>
                        <div>{currencyFormat.format(negativePnl)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="stat-item item">
                <div className="chart-item-container">
                  <div className="chart-item">
                    <Doughnut
                        data={donutData}
                        options={{
                          cutout: 80,
                          plugins: {
                            legend: {
                              display: false,
                            },
                          },
                        }}
                    />
                    <div className="legend">
                      <div className="label">
                        {t("trade_win_percentage_heading")}{" "}
                        <InfoToolTip
                            message={t("trade_win_percentage_tooltip")}
                        />
                      </div>
                      <div
                          className={`value ${
                              winPercentage !== undefined
                                  ? winPercentage >= 0
                                      ? "green"
                                      : "red"
                                  : ""
                          }`}
                      >
                        {winPercentage !== 0 || winPercentage !== "NaN"
                            ? winPercentage
                            : 0}
                        %
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-row-2">
                <div className="stat-item item">
                  <div className="flex-horizontal">
                    <div className="flex-vertical">
                      <div className="label">
                        {t("profit_factor_heading")}
                        <InfoToolTip message={t("profit_factor_tooltip")}/>
                      </div>
                      <div
                          className={`value ${
                              positivePnl !== undefined && negativePnl !== 0
                                  ? (positivePnl / negativePnl).toFixed(2) >= 0
                                      ? "green"
                                      : "red"
                                  : "gray"
                          }`}
                      >
                        {positivePnl !== undefined && negativePnl !== 0
                            ? (positivePnl / Math.abs(negativePnl)).toFixed(2)
                            : 'N/A'}
                      </div>
                    </div>
                    <div className="chart-item-container">
                      <div className="flex-vertical">
                        <div className="chart-item-sm">
                          <Doughnut
                              data={profitFactorData}
                              options={{
                                cutout: 26,
                                maintainAspectRatio: false,
                                responsive: true,
                                plugins: {
                                  legend: {
                                    display: false,
                                  },
                                },
                              }}
                              height={"70px"}
                              width={"150px"}
                          />
                        </div>
                        <div className="flex-horizontal px-1">
                          <DataTooltip
                              message={t("positive_number_of_days_tooltip")}
                          >
                            <div className="chart-label green">
                              {numberOfPositiveDays ? numberOfPositiveDays : 0}
                            </div>
                          </DataTooltip>
                          <DataTooltip message={t("break_even_tooltip")}>
                            <div className="chart-label">{numberOfBreakEvenDays ? numberOfBreakEvenDays : 0}</div>
                          </DataTooltip>
                          <DataTooltip
                              message={t("negative_number_of_days_tooltip")}
                              left={true}
                          >
                            <div className="chart-label red">
                              {numberOfNegativeDays ? numberOfNegativeDays : 0}
                            </div>
                          </DataTooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="stat-item item">
                  <div className="flex-horizontal">
                    <div className="flex-vertical">
                      <div className="label">
                        {t("days_win_heading")}
                        <InfoToolTip message={t("days_win_tooltip")}/>
                      </div>
                      <div
                          className={`value ${
                              numberOfNegativeDays !== undefined &&
                              numberOfPositiveDays !== undefined
                                  ? numberOfPositiveDays >= numberOfNegativeDays
                                      ? "green"
                                      : "red"
                                  : ""
                          }`}
                      >
                        {numberOfPositiveDays ? Math.round(((numberOfPositiveDays / (numberOfPositiveDays + numberOfNegativeDays)) * 100) * 100) / 100 : 0}%
                      </div>
                    </div>
                    <div className="chart-item-container">
                      <div className="flex-vertical">
                        <div className="chart-item-sm">
                          <Doughnut
                              data={daysWinData}
                              options={{
                                cutout: 26,
                                maintainAspectRatio: false,
                                responsive: true,
                                plugins: {
                                  legend: {
                                    display: false,
                                  },
                                },
                              }}
                              height={"70px"}
                              width={"150px"}
                          />
                        </div>
                        <div className="flex-horizontal px-1">
                          <DataTooltip
                              message={t("positive_number_of_days_tooltip")}
                          >
                            <div className="chart-label green">
                              {numberOfPositiveDays ? numberOfPositiveDays : 0}
                            </div>
                          </DataTooltip>
                          <DataTooltip message={t("break_even_tooltip")}>
                            <div className="chart-label">{numberOfBreakEvenDays ? numberOfBreakEvenDays : 0}</div>
                          </DataTooltip>
                          <DataTooltip
                              message={t("negative_number_of_days_tooltip")}
                              left={true}
                          >
                            <div className="chart-label red">
                              {numberOfNegativeDays ? numberOfNegativeDays : 0}
                            </div>
                          </DataTooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Daily P&L, PNL and Score */}
            <div className="grid grid-col-3 grid-expand-1 center-row mt-2">
              <div className="item">
                <div className="flex-horizontal item-header">
                  <div className="heading">
                    {t("account_balance_heading")}{" "}
                    <InfoToolTip message={t("account_balance_tooltip")}/>
                  </div>
                  <div className="legend">
                    <div className="legend-item">
                      <div className="color red"></div>
                      {t("deposit_withdrawl_legend")}
                    </div>
                    <div className="legend-item">
                      <div className="color green"></div>
                      {t("balance_legend")}
                    </div>
                  </div>
                </div>
                <div className="chart">
                  <Line
                      data={lineData}
                      options={lineOptions}
                  />
                </div>
              </div>
              <div className="item">
                <div className="flex-horizontal item-header">
                  <div className="heading">
                    {t("daily_pnl_heading")}{" "}
                    <InfoToolTip message={t("daily_pnl_tooltip")}/>
                  </div>
                </div>
                <div className="chart">
                  <Bar
                      data={barChartData}
                      options={barChartOptions}
                  />
                </div>
              </div>
              <div className="item">
                <div className="flex-horizontal item-header">
                  <div className="heading">
                    {t("score_heading")}{" "}
                    <InfoToolTip message={t("score_tooltip")}/>
                  </div>
                  <div className="score">{score}</div>
                </div>
                <div className="chart">
                  <Radar
                      data={radarData}
                      options={radarOptions}
                  />
                </div>
              </div>
            </div>
            {/* Positions, Net Cumulative P&L and Drawdown */}
            <div className="grid grid-col-2 bottom-row mt-2 ">
              <div className="item list">
                <div className="flex-horizontal item-header">
                  <div className="heading">
                    {t("recently_closed_positions_heading")}
                  </div>
                </div>
                <div className={`table table-col-3`}>
                  <div className="header">
                    <div className="cell">
                      {t("recently_closed_positions_close_date")}
                    </div>
                    <div className="cell">
                      {t("recently_closed_positions_symbol")}
                    </div>
                    <div className="cell">
                      {t("recently_closed_positions_net_p&l")}
                    </div>
                  </div>
                  <div className="rows">
                    {sortedData?.map((item) => {
                      const NewSelecteddate = new Date(selectedDate).setHours(
                          0,
                          0,
                          0,
                          0
                      );
                      const NewEndDate = new Date(endDate).setHours(0, 0, 0, 0);

                      const days = [];
                      let currentDate = new Date(NewSelecteddate);

                      while (currentDate <= NewEndDate) {
                        days.push(moment(currentDate).format("DD/MM/YYYY"));
                        currentDate.setDate(currentDate.getDate() + 1);
                      }

                      const openDates = new Date(item?.data?.OpenTime);

                      if (
                          item?.data &&
                          item?.data?.PnL !== undefined &&
                          moment(openDates).format("DD/MM/YYYY") !== "Invalid date" &&
                          days.includes(moment(openDates).format("DD/MM/YYYY"))
                      ) {
                        return (
                            <div key={item.id} className="row" onClick={() => navigation(`/trade/${item.id}`)}>
                              <div className="cell">
                                {moment(item?.data?.CloseTime).format("DD/MM/YYYY")}
                              </div>
                              <div className="cell">{item?.data?.SecurityId}</div>
                              <div
                                  className={`cell ${
                                      item?.data?.PnL > 0 ? "green" : "red"
                                  }`}
                              >
                                {UsDollar.format(item?.data?.PnL)}
                              </div>
                            </div>
                        );
                      }

                      return null;
                    })}
                  </div>
                </div>
              </div>
              <div className="charts grid grid-row-2">
                <div className="item">
                  <div className="flex-horizontal item-header">
                    <div className="heading">
                      {t("net_cumulative_p&l_heading")}{" "}
                      <InfoToolTip message={t("net_cumulative_p&l_tooltip")}/>
                    </div>
                  </div>
                  <div className="chart">
                    <Line
                        data={netCumulativeData}
                        options={lineOptions}
                    />
                  </div>
                </div>
                <div className="item">
                  <div className="flex-horizontal item-header">
                    <div className="heading">
                      {t("drawdown_heading")}{" "}
                      <InfoToolTip message={t("drawdown_tooltip")}/>
                    </div>
                  </div>
                  <div className="chart">
                    <Line
                        data={areaData}
                        options={lineOptions}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="item mt-2">
              <ExpandedCalendar
                  title={t("calendar_title")}
                  data={calendarData}
                  dataLengthPerDate={dataLengthPerDate}
              />
            </div>
            <div className="grid grid-col-2 mt-2 last-row">
              {
                economicCalendarSettings && accounts ?
                    <div className="economic-calendar">
                      <div className="item">
                        <iframe id="frame"
                                src={`https://sslecal2.investing.com?columns=exc_flags,exc_currency,exc_importance,exc_actual,exc_forecast,exc_previous&importance=${economicCalendarSettings.newsImportance.map(imp => imp.id).join(',')}&features=datepicker,timezone,timeselector,filters&countries=${economicCalendarSettings.countries.map(country => country.id).join(',')}&calType=day&timeZone=16&lang=${languageData[accounts.user.language]}`}
                                width="650" height="467" allowTransparency="true">
                        </iframe>
                        <div className="poweredBy" style={{fontFamily: "Arial, Helvetica, sans-serif"}}>
                      <span style={{fontSize: "11px", color: "#333333", textDecoration: "none"}}>
                        Der Wirtschaftskalender wird Ihnen von
                        <a href="https://de.investing.com/" rel="nofollow" target="_blank"
                           style={{fontSize: "11px", color: "#06529D", fontWeight: "bold"}}
                           className="underline_link">Investing.com Deutschland
                        </a>, dem führenden Finanzportal, zur Verfügung gestellt.
                      </span>
                        </div>
                      </div>
                    </div> : <></>
              }
              <div className="coming-soon item">
                <div className="text">
                  <div className="content">
                    New Feature Coming Soon! 🚀✨
                  </div>
                </div>
              </div>
            </div>
          </div>
      )}
    </>
  );
}

export default Dashboard;
