import Laptop from "../../images/Laptop.png";

const LaptopSection = () => {
    return (
        <div className="laptop-section front">
            <div className="laptop-image">
                <img src={Laptop} alt="Laptop"/>
            </div>
        </div>
    )
}

export default LaptopSection