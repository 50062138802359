import React, { useEffect, useState } from "react";
import ImagePlaceholder from "../images/image-placeholder.jpg";
import Urls from '../../common/links';
import {sendDelete} from '../../common/request';
import { toast } from "react-toastify";
import useSubAccount from "../hooks/useSubAccount";
import {useTranslation} from "react-i18next";

export default function GroupMembers({selectedGroup, onUserRemove, onlineUsers}) {
  const [closeMembers, setCloseMembers] = useState(true);
  const [members, setMembers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const subAccount = useSubAccount();
  const {t} = useTranslation();

  useEffect(() => {
    if(selectedGroup && selectedGroup.Members) {
      setMembers(selectedGroup.Members);
    }
  }, [selectedGroup])

  const closeMember = () => {
    setCloseMembers(false);
  };

  const removeMember = async (id) => {
    const response = await sendDelete(Urls.RemoveUserFromGroup(selectedGroup.id, id), {}, true);
    if(response.error) {
      toast.error(response.error);
    } else {
      toast.success('User removed from the group')
      onUserRemove();
    }
    setMembers(members.filter((member) => member.id !== id));
  };

  useEffect(() => {
    if(selectedGroup && subAccount) {
      if(selectedGroup.adminId === subAccount.UserId) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
  }, [selectedGroup, subAccount])

  if (!closeMembers) return null;

  return (
    <div className="main-members-settings">
      <div className="members-settings">
        <div className="members-header">
          <p className="members-numbers">{members.length} {t("community.chat.members")}</p>
          <span className="close-members-settings" onClick={closeMember}></span>
        </div>
        <div className="members-container">
          {members.map((member) => (
            <div key={member.id} className="user-member">
              <div className="user-name-message">
                <div className="user-image-container">
                  <img
                      className="user-image"
                      src={member.profileImage ?? ImagePlaceholder}
                      alt={`${member.firstName}'s profile`}
                  />
                  {
                    onlineUsers && onlineUsers.includes(member.id) ?
                        <div className="live-tag"></div> : <></>
                  }
                </div>
                <p className="member-name">{member.firstName} {member.lastName}</p>
              </div>
              {member.id === selectedGroup?.adminId ? (
                  <button className="admin">{t("community.settings.members.admin")}</button>
              ) : (
                isAdmin ? 
                <button className="remove-btn" onClick={() => removeMember(member.id)} >
                  {t("community.settings.members.removeUser")}
                </button> : <></>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
