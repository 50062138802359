import React, { useEffect, useState } from "react";
import { MdSearch, MdAdd } from "react-icons/md";
import ImagePlaceholder from "../images/image-placeholder.jpg";
import useSubAccount from "../hooks/useSubAccount";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {useTranslation} from "react-i18next";

export default function User({
    handleNewChat,
    handleUserSelect,
    users,
    selectedUser,
    onlineUsers
}) {
  const {t} = useTranslation();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const handleUserClick = (user) => {
    setSelectedUserId(user.id);
    handleUserSelect(user);
  };

  useEffect(() => {
    if(selectedUser) {
      setSelectedUserId(selectedUser.id)
    }
  }, [selectedUser]);

  const subAccount = useSubAccount();

  return (
    <div className="user-com">
      <div className="btns-width">
        <button className="new-chat-btn" onClick={handleNewChat}>
          <MdAdd /> {t("community.chatBar.newChatButton")}
        </button>
      </div>

      <div className="users-list">
        {
          users.length > 0 ?
            users.map((chat) => {
              let profileImage = ImagePlaceholder;
              let otherUserId = -1;
              let name = '--';
              if(subAccount) {
                if(chat.Sender && chat.Sender.id !== subAccount.UserId) {
                  otherUserId = chat.Sender.id;
                  if(chat.Sender.profileImage) {
                    profileImage = chat.Sender.profileImage;
                  }

                  if(chat.Sender.firstName || chat.Sender.lastName) {
                    name = `${chat.Sender.firstName} ${chat.Sender.lastName}`;
                  } else {
                    name = chat.Sender.username;
                  }
                } else if(chat.Receiver && chat.Receiver.id !== subAccount.UserId) {
                  otherUserId = chat.Receiver.id;
                  if(chat.Receiver.profileImage) {
                    profileImage = chat.Receiver.profileImage;
                  }

                  if(chat.Receiver.firstName || chat.Receiver.lastName) {
                    name = `${chat.Receiver.firstName} ${chat.Receiver.lastName}`;
                  } else {
                    name = chat.Receiver.username
                  }
                }
              }

              return (
                <div
                  className={
                    "user" + (selectedUserId === chat.id ? " active-user" : "")
                  }
                  key={chat.id}
                  onClick={() => handleUserClick(chat)} >
                  <div className="user-name-message">
                    <div className="user-image-container">
                      <LazyLoadImage className="user-image" src={profileImage} alt="" />
                        {
                            onlineUsers && onlineUsers.includes(otherUserId) ?
                                <div className="live-tag"></div> : <></>
                        }
                    </div>
                      <div className="name-message-container">
                      <p className="user-name">{name}</p>
                      {
                        chat.LastMessage?
                        <p className="user-message">{chat.LastMessage.text}</p> : <></>
                      }
                    </div>
                  </div>
                  <div className="time-message">
                  {
                    chat.unreadMessages > 0 ?
                    <p className="message-number-circle">
                      <span className="message-numbers">{chat.unreadMessages}</span>
                    </p> : <></>
                  }
                  </div>
                </div>
              )
            }) : 
            <div className="empty-message">No Chat Found</div>
        }
      </div>
    </div>
  );
}
