import React from "react";
import Report from "../components/report";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTitle } from "../../redux/header/action";
import {useTranslation} from "react-i18next";

const Reports = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation()
  dispatch(setTitle(t("reports_page")))

  return (
    <div className={`page-body report-page`}>
      <div className={`report-container`}>
        <Report />
      </div>
      <div className={`inner-page-content`}>
        <Outlet />
      </div>
    </div>
  );
};

export default Reports;
