import {useEffect, useRef, useState} from "react";
import Loader from "../../components/Loader";
import Select from "../../components/selectcontrol";
import countryData from "../../data/countryData.json"
import {sendGet, sendPost} from "../../../common/request";
import Urls from '../../../common/links';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {useTheme} from "../../Providers/ThemeContext";

const EconomicCalendar = () => {
    let [economicCalendarSettings, setEconomicCalendarSettings] = useState({});
    let [isLoading, setIsLoading] = useState(false);
    const [countryOptions, setCountryOptions] = useState([])
    const newsTypes = ['Simple', 'Normal', 'Important'];
    const previousCountryIndices = useRef([]);
    const previousNewsImportanceIndices = useRef([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedNewsImportance, setSelectedNewsImportance] = useState([]);
    const [canSave, setCanSave] = useState(false);
    const {t} = useTranslation();
    const theme = useTheme();

    const loadEconomicCalendarSettings = async () => {
        setIsLoading(true);
        const response = await sendGet(Urls.GetEconomicCalendar, true);
        if(response.error) {
            toast.error(response.error);
        } else {
            setEconomicCalendarSettings(response.settings);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        setCountryOptions(Object.keys(countryData));
        loadEconomicCalendarSettings().then(() => {});
    }, []);

    useEffect(() => {
        if(economicCalendarSettings && economicCalendarSettings.countries && economicCalendarSettings.newsImportance) {
            const countriesList = Object.keys(countryData);
            previousCountryIndices.current = economicCalendarSettings.countries.map(country => countriesList.indexOf(country.name));
            setSelectedCountries(previousCountryIndices.current);
            previousNewsImportanceIndices.current = economicCalendarSettings.newsImportance.map(newsImportance => newsTypes.indexOf(newsImportance.name))
            setSelectedNewsImportance(previousNewsImportanceIndices.current);
        }
    }, [economicCalendarSettings]);

    useEffect(() => {
        if(JSON.stringify(selectedCountries) !== JSON.stringify(previousCountryIndices.current) || JSON.stringify(selectedNewsImportance) !== JSON.stringify(previousNewsImportanceIndices.current)) {
            setCanSave(true);
        } else {
            setCanSave(false);
        }
    }, [selectedCountries, selectedNewsImportance]);

    const onDiscard = () => {
        setSelectedCountries(previousCountryIndices.current);
        setSelectedNewsImportance(previousNewsImportanceIndices.current);
    }

    const onSave = async () => {
        setIsLoading(true);
        const response = await sendPost(Urls.UpdateEconomicCalendar, {
            countries: selectedCountries.map(country => ({name: countryOptions[country], id: countryData[countryOptions[country]]})),
            newsImportance: selectedNewsImportance.map(imp => ({name: newsTypes[imp], id: imp + 1})),
        }, true, 'application/json', true);

        if(response.error) {
            console.log(response.error);
            toast.error(response.error)
        } else {
            setEconomicCalendarSettings(response.settings);
            setCanSave(false);
        }
        setIsLoading(false);
    }

    return (
        <>
            {isLoading ? (
                <Loader fullScreen={true} />
            ) : (
                <div className={`data-page ${theme.getThemeClass()}`}>
                    <div className="data-page-body">
                        <div className="economic-section">
                            <div className="heading">{t("economic_calendar_settings_page_title")}</div>
                            <div className="form">
                                <div className="input-group">
                                    <div className="label">{t("economic_calendar_settings_countries")}</div>
                                    <Select options={countryOptions} isSearchable={true} allowMultiple={true} selectedIndex={selectedCountries} onChange={(indices) => setSelectedCountries(indices)} />
                                </div>
                                <div className="input-group">
                                    <div className="label">{t("economic_calendar_settings_news_importance")}</div>
                                    <Select options={newsTypes} allowMultiple={true} selectedIndex={selectedNewsImportance} onChange={(indices) => setSelectedNewsImportance(indices)} />
                                </div>
                                <div className="action-btn">
                                    <div className={`primary-outline-btn ${canSave ? '' : 'disabled'}`} onClick={onSave}>{t("save_btn_text")}</div>
                                    {
                                        canSave ?
                                            <div className="danger-outline-btn" onClick={onDiscard}>{t("discard_btn_text")}</div> : <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default EconomicCalendar;