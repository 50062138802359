import Checkbox from '../components/checkbox';
import { MdMoreVert } from 'react-icons/md';
import currencyFormat from "../../common/format"
import { useNavigate } from 'react-router-dom';
import ActionPermission from "./permissions/ActionPermission";
import permissions from "../data/Permissions.json";

function PlaybookItem({playbook, selected, onSelect, onToggleMenu}) {

    const selectPlaybook = (value) => {
        if(onSelect) {
            onSelect(value);
        }
    }

    const getPnL = () => {
        if(playbook.trades.length > 0) {
            const pnlList = playbook.trades.map(t => parseFloat(t.data.PnL));
            return pnlList.reduce((a, b) => a + b);
        } else {
            return 0;
        }
    }

    const getWinningRate = () => {
        if(playbook.trades.length > 0) {
            const winningTrades = playbook.trades.filter(p => p.data.PnL > 0).length;
            return ((winningTrades / playbook.trades.length) * 100).toFixed(2);
        } else {
            return 0;
        }
    }

    const getTradeExpectancy = () => {
        if(playbook.trades.length > 0) {
            const winningTrades = playbook.trades.filter(trade => trade.data.PnL > 0).map(trade => {
                trade.data.PnL = parseFloat(trade.data.PnL);
                return trade;
            });
            const losingTrades = playbook.trades.filter(trade => trade.data.PnL < 0).map(trade => {
                trade.data.PnL = parseFloat(trade.data.PnL);
                return trade;
            });
            const winRate = (winningTrades.length / playbook.trades.length) * 100;
            const totalProfit = winningTrades.length === 0 ? 0 : winningTrades.map(t => t.data.PnL).reduce((a, b) => a + b);
            const totalLoss = losingTrades.length === 0 ? 0 : losingTrades.map(t => t.data.PnL).reduce((a, b) => a + b);
            const averageProfitPerTrade = winningTrades.length === 0 ? 0 : (totalProfit / winningTrades.length);
            const averageLossPerTrade = losingTrades.length === 0 ? 0 : (totalLoss / losingTrades.length);
            return ((averageProfitPerTrade * winRate) - (averageLossPerTrade * (1 - winRate))).toFixed(2);

        } else {
            return 0;
        }
    }

    const navigate = useNavigate();

    const onMenuToggleBtnClick = (e) => {
        e.stopPropagation();
        if(onToggleMenu) {
            onToggleMenu(e.clientX, e.clientY, playbook);
        }
    }

    return (
        <ActionPermission className="row clickable" onClick={() => navigate(`/playbook-details/overview/${playbook.id}`)} permission={permissions.PLAYBOOK.DETAILS}>
            <div className="cell checkbox">
                <div className={`${selected ? 'show' : ''}`}>
                    <Checkbox value={selected} onChange={(value) => selectPlaybook(value)}/>
                </div>
            </div>
            <div className="cell lg">
                <div className="horizontal-container">
                    {playbook.icon}
                    {playbook.name}
                </div>
            </div>
            <div className="cell">
                {playbook.trades.length}
            </div>
            <div className={`cell ${getPnL() >= 0 ? 'positive' : 'negative'}`}>
                {currencyFormat.format(getPnL())}
            </div>
            <div className="cell">
                {getWinningRate()}%
            </div>
            <div className="cell">
                {currencyFormat.format(getTradeExpectancy())}
            </div>
            <div className="cell actions">
                <div className='action-btn' onClick={onMenuToggleBtnClick}>
                    <MdMoreVert />
                </div>
            </div>
        </ActionPermission>
    )
}

export default PlaybookItem;