import Navbar from "../components/landing-page/Navbar";
import PricingSection from "../components/landing-page/PricingSection";
import Footer from "../components/landing-page/Footer";
import ScrollToTop from "../components/landing-page/ScrollToTop";

const PricingPage = () => {
    return (
        <div className="website-page pricing-page">
            <ScrollToTop />
            <Navbar currentPage={'pricing'} />
            <div className="sections">
                <PricingSection canViewFeatureComparison={true} />
                <Footer />
            </div>
        </div>
    )
}

export default PricingPage;