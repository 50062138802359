import React, { useState, useEffect, useCallback } from "react";
import { sendGet, sendPost, sendPut } from "../../common/request";
import Urls from "../../common/links";
import RichTextEditor from './RichTextEditor';
import Loader from "./Loader";

const JournalDescription = ({ currentDate, subAccountId }) => {
  const [content, setContent] = useState("");
  const [journalDetails, setJournalDetails] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const fetchJournalDetails = useCallback(async () => {
    setIsLoading(true);
    let response = await sendGet(Urls.GetNoteForDate(currentDate, subAccountId), true);
    if(response.error) {
      console.log(response.error)
    } else {
      setJournalDetails(response.note);
      setContent(response.note.content);
    }
    setIsLoading(false);
  }, [subAccountId, currentDate]);

  useEffect(() => {
    fetchJournalDetails();
  }, [fetchJournalDetails]);

  const updateJournalDetails = async () => {
    setIsLoading(true);
    const todayEntry = { content: content, date: currentDate, subAccountId: subAccountId, type: 'JournalDetails' };
    await sendPost(Urls.CreateOrUpdateNote(journalDetails ? journalDetails.id : ''), todayEntry, true, "application/json", true);
    setIsLoading(false);
  };

  return (
    <div className="loadable">
      {
        isLoading ? 
        <div className="loader-overlay">
          <Loader fullScreen={false}/>
        </div> : <></>
      }
      <RichTextEditor content={content} 
        onContentChange={content => setContent(content)}
        onSaveChanges={updateJournalDetails}
        onDiscardChanges={fetchJournalDetails}
      />
    </div>
  );
};

export default JournalDescription;
