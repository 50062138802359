import AnalyticsDashboardFeature from "./features/AnalyticsDashboardFeature";
import {useEffect, useRef} from "react";
import FeatureRow from "./features/TradingJournalFeature";
import DailyJournalImage from "../../images/feature-images/Daily Journal.png";
import PlaybookImage from "../../images/feature-images/Playbook.png";
import ReportImage from "../../images/feature-images/Report.png";

const FeaturesSection = () => {

    const featuresListRef = useRef(null);
    const featuresContentRef = useRef(null);
    const featuresListBoundingRectRef = useRef(null)
    const featuresContentBoundingRectRef = useRef(null);
    const thumbRef = useRef(null);
    const featuresSectionHeightsRef = useRef({});
    const currentSectionRef = useRef(1);
    const previousScrollRef = useRef(0);
    const scrollBarRef = useRef(null);
    const scrollBarBoundingRectRef = useRef(null);
    const TOP_ADJUSTMENT = 0;
    const STICKY_POSITION = 1680;
    const FEATURES_COUNT = 8;
    const features = [
        {
            title: "Powerful <span class='gradient'>Trade Journaling</span>  Setup",
            description: 'In tradingprozess you will find a very versatile and powerful trading journal which helps you to not only journal trades but test plan and track performance.',
            image: DailyJournalImage
        },
        {
            title: "Focused <span class='gradient'>Reports</span>",
            description: 'In tradingprozess you will find a very versatile and powerful trading journal which helps you to not only journal trades but test plan and track performance.',
            image: ReportImage
        },
        {
            title: "<span class='gradient'>Playbooks</span> for planning and performance tracking",
            description: 'In tradingprozess you will find a very versatile and powerful trading journal which helps you to not only journal trades but test plan and track performance.',
            image: PlaybookImage
        }
    ]

    const performNecessaryOperations = () => {
        featuresListBoundingRectRef.current = featuresListRef.current.getBoundingClientRect();
        featuresContentBoundingRectRef.current = featuresContentRef.current.getBoundingClientRect();
        scrollBarBoundingRectRef.current = scrollBarRef.current.getBoundingClientRect();

        const sections = document.querySelectorAll("[data-section]");
        let currentHeight = STICKY_POSITION;
        for(const section of sections) {
            const boundingRect = section.getBoundingClientRect();
            const number = section.getAttribute("data-section");
            featuresSectionHeightsRef.current[`section-${number}`] = {start: currentHeight, end: currentHeight + boundingRect.height};
            currentHeight += boundingRect.height + 160;
        }
    }

    const setScrollPosition = (current, prev) => {
        document.querySelector(`[data-section-tag='${prev}']`).classList.remove('active');
        document.querySelector(`[data-section-tag='${current}']`).classList.add('active');
        if (thumbRef.current && scrollBarBoundingRectRef.current) {
            const top = (scrollBarBoundingRectRef.current.height / FEATURES_COUNT) * (current - 1) + current + 14;
            thumbRef.current.style.top = `${top}px`;
        }
    }

    useEffect(() => {

        performNecessaryOperations();

        window.addEventListener('scroll', () => {
            if(featuresListRef.current && featuresListBoundingRectRef.current && featuresContentBoundingRectRef.current) {
                const distanceFromBottom = STICKY_POSITION + featuresContentBoundingRectRef.current.height - featuresListBoundingRectRef.current.height - TOP_ADJUSTMENT;
                const scroll = window.scrollY;
                const direction = scroll > previousScrollRef.current ? 'down' : scroll < previousScrollRef.current ? 'up' : '';
                previousScrollRef.current = scroll;

                if(featuresSectionHeightsRef.current) {
                    const currentSectionKey = `section-${currentSectionRef.current}`;
                    if(featuresSectionHeightsRef.current[currentSectionKey].end < scroll && direction === 'down') {
                        if(currentSectionRef.current < Object.keys(featuresSectionHeightsRef.current).length) {
                            setScrollPosition(currentSectionRef.current + 1, currentSectionRef.current);
                            currentSectionRef.current = currentSectionRef.current + 1;
                        }
                    }
                    else if (currentSectionRef.current > 1) {
                        const prevKey = `section-${currentSectionRef.current - 1}`;
                        const height = featuresSectionHeightsRef.current[prevKey].end - featuresSectionHeightsRef.current[prevKey].start;
                        const heightToConsider = (height * 25) / 100;
                        if(featuresSectionHeightsRef.current[prevKey].end - heightToConsider > scroll && direction === 'up') {
                            setScrollPosition(currentSectionRef.current - 1, currentSectionRef.current);
                            currentSectionRef.current = currentSectionRef.current - 1;
                        }
                    }
                }

                if(scroll > STICKY_POSITION && scroll < distanceFromBottom) {
                    featuresListRef.current.style.position = 'fixed';
                    featuresListRef.current.style.top = '100px'
                    featuresListRef.current.style.left = '48px'
                }
                else if (scroll <= STICKY_POSITION) {
                    featuresListRef.current.style.position = 'absolute';
                    featuresListRef.current.style.top = '0px'
                    featuresListRef.current.style.left = '0px'
                }
                else {
                    featuresListRef.current.style.position = 'absolute';
                    featuresListRef.current.style.top = `${featuresContentBoundingRectRef.current.height - featuresListBoundingRectRef.current.height + TOP_ADJUSTMENT}px`
                    featuresListRef.current.style.left = '0px'
                }
            }
        })
    }, []);

    const onFeatureClick = (sectionNumber) => {
        setScrollPosition(sectionNumber, currentSectionRef.current);

        //Getting the section height
        if(featuresSectionHeightsRef.current) {
            const sectionKey = `section-${sectionNumber}`;
            window.scroll({
                top: featuresSectionHeightsRef.current[sectionKey].start,
                behavior: 'smooth'
            })
        }

        currentSectionRef.current = sectionNumber;
    }

    return (
        <div className='features-section front'>
            <div className="content">
                <h1 className="heading">
                    What We Offer?
                </h1>
                <div className="features">
                    <div className="features-list-container" ref={featuresListRef}>
                        <div className="scrollbar" ref={scrollBarRef}>
                            <div className="thumb" ref={thumbRef}></div>
                        </div>
                        <div className="features-list">
                            <div className="feature active" data-section-tag={1} onClick={() => onFeatureClick(1)}>Analytics Dashboard</div>
                            <div className="feature" data-section-tag={2} onClick={() => onFeatureClick(2)}>Daily Journal</div>
                            <div className="feature" data-section-tag={3} onClick={() => onFeatureClick(3)}>Economic Calendar</div>
                            <div className="feature" data-section-tag={4} onClick={() => onFeatureClick(4)}>Reports</div>
                            <div className="feature" data-section-tag={5} onClick={() => onFeatureClick(5)}>Playbooks</div>
                            <div className="feature" data-section-tag={6} onClick={() => onFeatureClick(6)}>Notebooks</div>
                            <div className="feature" data-section-tag={7} onClick={() => onFeatureClick(7)}>Community</div>
                            <div className="feature" data-section-tag={8} onClick={() => onFeatureClick(8)}>Automated AI Alerts</div>
                        </div>
                    </div>
                    <div className="features-content" ref={featuresContentRef}>
                        <AnalyticsDashboardFeature section={1} />
                        {
                            features.map((feature, index) => {
                                return (
                                    <FeatureRow section={index + 2} key={index} title={feature.title} description={feature.description} image={feature.image} inverted={index % 2 !== 0} />
                                )
                            })
                        }
                        <AnalyticsDashboardFeature section={5} />
                        <AnalyticsDashboardFeature section={6} />
                        <AnalyticsDashboardFeature section={7} />
                        <AnalyticsDashboardFeature section={8} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeaturesSection;