import { useSelector } from "react-redux";

const useSubAccount = () => {

    const selectedAccount = useSelector(
        (state) => state.accounts.selectedAccounts
    );

    const accounts = useSelector((state) => state?.user?.user);

    if(accounts && accounts.user && accounts.user.subAccounts) {
        const filteredAccounts = accounts.user.subAccounts.filter(
            (account) => {
                if(account.name) {
                    return account.name === selectedAccount
                } else {
                    return false;
                }
            }
          );
    
        if(filteredAccounts && filteredAccounts.length > 0) {
            return filteredAccounts[0];
        } else if(accounts.user.subAccounts.length > 0) {
            return accounts.user.subAccounts[0];
        } else {
            return undefined;
        }
    } else {
        return undefined;
    }
}

export default useSubAccount;