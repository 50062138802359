import { useState } from "react";
import { sendPost } from "../../../common/request";
import links from "../../../common/links";
import NotificationBar from "../../components/NotificationBar";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import {useTheme} from "../../Providers/ThemeContext";

function SecurityPage() {
  let [currentPassword, setCurrentPassword] = useState("");
  let [newPassword, setNewPassword] = useState("");
  let [confirmNewPassword, setConfirmNewPassword] = useState("");
  let [error, setError] = useState("");
  let [message, setMessage] = useState("");
  let [isMessageDisplayed, setIsMessageDisplayed] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  let { t } = useTranslation();
  const theme = useTheme();

  let discardChanges = () => {
    setNewPassword("");
    setConfirmNewPassword("");
    setCurrentPassword("");
  };

  let changePassword = async () => {
    setIsLoading(true);
    setError("");
    let error = "";
    if (!newPassword || !currentPassword || !confirmNewPassword)
      error = "Invalid Data. Please enter data in all fields";
    else if (newPassword != confirmNewPassword)
      error = "Passwords do not match";

    if (error) {
      setError(error);
      return;
    }

    let response = await sendPost(
      links.ChangePassword,
      { password: currentPassword, newPassword: newPassword },
      true
    );
    if (response.error) {
      setError(response.error);
    } else {
      setMessage("Password Updated Successfully");
      setIsMessageDisplayed(true);
      discardChanges();
    }
    setIsLoading(false);
  };

  return (
    <div className={`data-page ${theme.getThemeClass()}`}>
      {isMessageDisplayed ? (
        <NotificationBar
          message={message}
          showNotification={isMessageDisplayed}
          setShowNotification={setIsMessageDisplayed}
        />
      ) : (
        <></>
      )}
      <div className="page-header">
        <div className="title">
          {t("security_page_heading")}
          <p className="info">{t("security_page_note")}</p>
        </div>
      </div>
      <div className="data-page-body">
        <div className="security-data">
          <div className="fields">
            <div className="single-row">
              <div className="input-group">
                <label className="text-sm">
                  {t("security_page_current_password")}
                </label>
                <input
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  placeholder="Type Current Password"
                  className="input-group-item"
                />
              </div>
            </div>
            <div className="single-row">
              <div className="input-group">
                <label className="text-sm">
                  {t("security_page_new_password")}
                </label>
                <input
                  type="password"
                  placeholder="Type New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="input-group-item"
                />
              </div>
            </div>
            <div className="single-row">
              <div className="input-group">
                <label className="text-sm">
                  {t("security_page_confirm_new_password")}
                </label>
                <input
                  type="password"
                  placeholder="Confirm New Password"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  className="input-group-item"
                />
              </div>
            </div>
            {error ? <div className="error mt-1">{error}</div> : <></>}
          </div>
        </div>
        <div className="action-btn">
          {isLoading ? (
            <Loader height={20} barWidth={3} />
          ) : (
            <div className="primary-outline-btn" onClick={changePassword}>
              {t("change_password_btn_text")}
            </div>
          )}
          <div className="danger-outline-btn" onClick={discardChanges}>
            {t("discard_btn_text")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecurityPage;
