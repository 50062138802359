import bell from "../../images/bell-dynamic-color.png";
import chart from "../../images/chart-dynamic-color.png";
import money from "../../images/money-dynamic-color.png";
import pencil from "../../images/pencil-dynamic-color.png";
import thumbUp from "../../images/thumb-up-dynamic-color.png";

const HeroSection = () => {
    return (
        <div className='hero-section front' id={'home'}>
            <img src={bell} alt="Bell" className="bell bg-item"/>
            <img src={chart} alt="Bell" className="chart bg-item"/>
            <img src={pencil} alt="Bell" className="pencil bg-item"/>
            <img src={money} alt="Bell" className="money bg-item"/>
            <img src={thumbUp} alt="Bell" className="thumbUp bg-item"/>
            <div className="content">
                <h1 className="heading">
                    Trading Analytics<br/>Made Easier
                </h1>
                <p className='description'>
                    Tradingprozess saves your time and money by focusing on analytics which really matter
                </p>
                <div className="horizontal-container">
                    <div className="button outline-btn">
                        View Pricing
                    </div>
                    <div className="button filled-btn">
                        Register Now
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSection;