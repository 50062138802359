import {Line} from "react-chartjs-2";
import {useTheme} from "../../Providers/ThemeContext";
import {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {sendGet} from "../../../common/request";
import Urls from "../../../common/links";
import useSubAccount from "../../hooks/useSubAccount";
import {toast} from "react-toastify";
import moment from "moment";
import currencyFormat from "../../../common/format";

const WinLossDistributionReport = () => {

    const theme = useTheme();
    const [winLineData, setWinLineData] = useState(null);
    const [lossLineData, setLossLineData] = useState(null);
    const {selectedDate: startDate, endDate} = useSelector(state => state.date)
    const [data, setData] = useState({win: {}, loss: {}});
    const gridColor = theme.theme === 'dark' ? "#4A484F" : '#DEDDDF';
    const lineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        tension: 0.3,
        scales: {
            x: {
                grid: {
                    drawOnChartArea: false,
                    tickColor: gridColor,
                },
            },
            y: {
                border: {
                    color: gridColor
                },
                grid: {
                    color: gridColor,
                    tickColor: gridColor,
                },
                ticks: {
                    callback: function(value, index, ticks) {
                        return currencyFormat.format(value);
                    }
                }
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
    };
    const green = "#29D6A8";
    const red = "#ec787d";
    const subAccount = useSubAccount();

    const fetchData = useCallback(async () => {
        if(subAccount) {
            const response = await sendGet(Urls.SearchTrades(subAccount.id, {startDate, endDate}), true);
            if(response.error) {
                toast.error(response.error);
            }
            else if(response.trades && response.trades.length > 0) {
                const newData = {
                    win: {
                        count: 0,
                        pnl: 0,
                        volume: 0,
                        days: [],
                        tradeCount: 0,
                        commission: 0,
                        consecutive: 0,
                        netCumulativeData: [],
                        netCumulativeLabels: [],
                    },
                    loss: {
                        count: 0,
                        pnl: 0,
                        volume: 0,
                        days: [],
                        tradeCount: 0,
                        commission: 0,
                        consecutive: 0,
                        netCumulativeData: [],
                        netCumulativeLabels: [],
                    }
                }
                let lastMode = ''
                let consecutiveCount = 0;
                const trades = response.trades;
                for(const trade of trades) {
                    let mode = '';
                    if(trade.data.PnL > 0) {
                        mode = 'win';
                    }
                    else if (trade.data.PnL < 0) {
                        mode = 'loss';
                    }

                    if(mode === '' || !newData[mode])
                        continue;

                    newData[mode].count += 1;
                    newData[mode].pnl += trade.data.PnL;
                    newData[mode].volume += Math.abs(trade.data.CloseVolume);
                    newData[mode].tradeCount += 1;
                    newData[mode].commission += trade.data.Commission;
                    const closeDate = moment(trade.data.CloseTime).format('DD-MM-YYYY');
                    if(!newData[mode].days.includes(closeDate)) {
                        newData[mode].days.push(closeDate);
                    }

                    // Calculating the Net Cumulative Data
                    if(newData[mode].netCumulativeData.length === 0) {
                        newData[mode].netCumulativeData.push(trade.data.PnL);
                        newData[mode].netCumulativeLabels.push(closeDate);
                    }
                    else {
                        // Finding whether the date exists or not
                        const index = newData[mode].netCumulativeLabels.findIndex(p => p === closeDate);
                        if(index >= 0) {
                            newData[mode].netCumulativeData[index] += trade.data.PnL;
                        }
                        else {
                            newData[mode].netCumulativeData.push(trade.data.PnL);
                            newData[mode].netCumulativeLabels.push(closeDate);
                        }
                    }

                    // Finding Consecutive Trades
                    if(lastMode === '') {
                        newData[mode].consecutive += 1;
                        consecutiveCount += 1;
                    }
                    else if (lastMode === mode) {
                        consecutiveCount += 1;
                        if(consecutiveCount > newData[mode].consecutive) {
                            newData[mode].consecutive = consecutiveCount;
                        }
                    }
                    else {
                        consecutiveCount = 0;
                    }
                    lastMode = mode;
                }

                setWinLineData({
                    labels: newData.win.netCumulativeLabels,
                    datasets: [
                        {
                            label: "Net Cumulative P&L",
                            data: newData.win.netCumulativeData,
                            borderColor: green,
                            pointBackgroundColor: green,
                        },
                    ],
                })
                setLossLineData({
                    labels: newData.loss.netCumulativeLabels,
                    datasets: [
                        {
                            label: "Net Cumulative P&L",
                            data: newData.loss.netCumulativeData,
                            borderColor: red,
                            pointBackgroundColor: red,
                        },
                    ],
                })

                setData(newData);
            }
        }
    }, [subAccount])

    useEffect(() => {
        fetchData();
    }, []);

    const formatDate = (date) => {
        return moment(date).format("MMM DD, YYYY")
    }

    const findAverage = (value, count) => {
        const finalValue = value || 0;
        return count === 0 ? finalValue : finalValue / count;
    }

    return (
        <div className="report-data-page win-loss-distribution-report">
            <div className="content">
                <div className="data">
                    <div className="header">
                        WINS ({data.win.count} Trade{data.win.count > 1 ? 's' : ''} Matched)
                    </div>
                    <div className="stats">
                        <div className="header">
                            <div className="heading">STATISTICS (WINS)</div>
                            <div className="tag-line">({formatDate(startDate)} - {formatDate(endDate)})</div>
                        </div>
                        <div className="horizontal-line"></div>
                        <div className="stat-rows">
                            <div className="row">
                                <div className="row-item">Total P&L</div>
                                <div className="row-item">{currencyFormat.format(data.win.pnl)}</div>
                            </div>
                            <div className="row">
                                <div className="row-item">Average Daily Volume</div>
                                <div className="row-item">{findAverage(data.win.volume, data.win.days?.length || 0).toFixed(2)}</div>
                            </div>
                            <div className="row">
                                <div className="row-item">Average Winning Trade</div>
                                <div className="row-item">{currencyFormat.format(findAverage(data.win.pnl, data.win.days?.length || 0))}</div>
                            </div>
                            <div className="row">
                                <div className="row-item">Average Losing Trade</div>
                                <div className="row-item">N/A</div>
                            </div>
                            <div className="row">
                                <div className="row-item">Number of Winning Trades</div>
                                <div className="row-item">{data.win.count}</div>
                            </div>
                            <div className="row">
                                <div className="row-item">Number of Losing Trades</div>
                                <div className="row-item">0</div>
                            </div>
                            <div className="row">
                                <div className="row-item">Total Commissions</div>
                                <div className="row-item">{currencyFormat.format(data.win.commission)}</div>
                            </div>
                            <div className="row">
                                <div className="row-item">Max Consecutive Wins</div>
                                <div className="row-item">{data.win.consecutive}</div>
                            </div>
                        </div>
                    </div>
                    <div className="chart-container">
                        <div className="heading">
                            DAILY NET CUMULATIVE P&L (WINS) (ALL DATES)
                        </div>
                        <div className="chart">
                            {
                                winLineData && (
                                    <Line
                                        data={winLineData}
                                        options={lineOptions}
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="data">
                    <div className="header">
                        LOSSES ({data.loss.count} Trade{data.loss.count > 1 ? 's' : ''} Matched)
                    </div>
                    <div className="stats">
                        <div className="header">
                            <div className="heading">STATISTICS (LOSSES)</div>
                            <div className="tag-line">({formatDate(startDate)} - {formatDate(endDate)})</div>
                        </div>
                        <div className="horizontal-line"></div>
                        <div className="stat-rows">
                            <div className="row">
                                <div className="row-item">Total P&L</div>
                                <div className="row-item">{currencyFormat.format(data.loss.pnl)}</div>
                            </div>
                            <div className="row">
                                <div className="row-item">Average Daily Volume</div>
                                <div
                                    className="row-item">{findAverage(data.loss.volume, data.loss.days?.length || 0).toFixed(2)}</div>
                            </div>
                            <div className="row">
                                <div className="row-item">Average Winning Trade</div>
                                <div
                                    className="row-item">N/A</div>
                            </div>
                            <div className="row">
                                <div className="row-item">Average Losing Trade</div>
                                <div className="row-item">{currencyFormat.format(findAverage(data.loss.pnl, data.loss.days?.length || 0))}</div>
                            </div>
                            <div className="row">
                                <div className="row-item">Number of Winning Trades</div>
                                <div className="row-item">0</div>
                            </div>
                            <div className="row">
                                <div className="row-item">Number of Losing Trades</div>
                                <div className="row-item">{data.loss.count}</div>
                            </div>
                            <div className="row">
                                <div className="row-item">Total Commissions</div>
                                <div className="row-item">{currencyFormat.format(data.loss.commission)}</div>
                            </div>
                            <div className="row">
                                <div className="row-item">Max Consecutive Losses</div>
                                <div className="row-item">{data.loss.consecutive}</div>
                            </div>
                        </div>
                    </div>
                    <div className="chart-container">
                        <div className="heading">
                            DAILY NET CUMULATIVE P&L (LOSSES) (ALL DATES)
                        </div>
                        <div className="chart">
                            {
                                winLineData && (
                                    <Line
                                        data={lossLineData}
                                        options={lineOptions}
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WinLossDistributionReport