import '../styles/outer.scss';
import LoginImage from '../images/LoginImage.png';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {sendPost} from '../../common/request';
import links from '../../common/links';
import Loader from '../components/Loader';

function ForgotPasswordPage() {
    let navigation = useNavigate();
    let [email, setEmail] = useState('');
    let [error, setError] = useState('');
    let [isLoading, setIsLoading] = useState(false);

    let onSendOTPCode = async () => {
        let emailRegex = /^[0-9A-Za-z-_.].*@[a-zA-Z0-9].*\.[0-9A-Za-z].*$/;
        if(!email || !emailRegex.test(email)) {
            setError('Invalid Email. Email must be of format example@gmail.com');
            return;
        }
        setIsLoading(true);
        console.log('Start Time: ', new Date())
        let response = await sendPost(links.SendTwoFactorCode, {email: email}, false, 'application/json');
        console.log('End Time: ', new Date())
        console.log(response);
        if(response.error) {
            setError(response.error.toString());
        } else {
            navigation(`/two-factor/${email}`);
        }
        setIsLoading(false);
    }

    return (
        <div className="page-outer">
            <div className='body'>
                <div className='content'>
                    <div className='rect'></div>
                    <img src={LoginImage} />
                </div>
                <div className='controls'>
                    <div className='title'>
                        Forgot Password
                    </div>
                    <div className='input-group'>
                        <label>Email Address</label>
                        <input type='email' placeholder='Enter your email address' value={email} onChange={e => setEmail(e.target.value)}/>
                    </div>
                    {
                        error ? 
                        <div className='mt-1 mb-3 error'>
                            {error}
                        </div> : <></>
                    }
                    <div className='input-group'>
                        <div className='btn btn-primary' onClick={onSendOTPCode}>
                            {
                                isLoading ? <Loader color="white" height={20} barWidth={3}/> : 'Continue'
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPasswordPage;