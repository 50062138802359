import Logo from "../../images/logo/logo-dark.png";
import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import UserContext from "../../contexts/UserContext";
import Loader from "../Loader";

const Navbar = ({currentPage}) => {

    const navigate = useNavigate();

    const navigateToPage = (page) => {
        navigate(page);
    }

    const userContext = useContext(UserContext);

    return (
        <div className="navbar-container front">
            <div className="navbar">
                <div className="logo">
                    <img src={Logo} alt="Logo" onClick={() => navigateToPage('/')} />
                </div>
                <div className="nav-buttons">
                    <div className={`nav-button ${currentPage === 'home' ? 'active' : ''}`} onClick={() => navigateToPage('/')}>Home</div>
                    <div className={`nav-button ${currentPage === 'pricing' ? 'active' : ''}`} onClick={() => navigateToPage('/pricing')}>Pricing</div>
                    {
                        userContext.loading ?
                            <Loader height={15} width={30} /> :
                            (
                                userContext.user ?
                                    <div className="nav-button filled" onClick={() => navigateToPage('/dashboard')}>Dashboard</div> :
                                    <>
                                        <div className="nav-button" onClick={() => navigateToPage('/login')}>Login</div>
                                        <div className="nav-button filled" onClick={() => navigateToPage('/register')}>Register</div>
                                    </>
                            )
                    }
                </div>
            </div>
        </div>
    )
}

export default Navbar;