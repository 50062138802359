const initialState = {
  selectedAccounts: [],
};

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_ACCOUNTS":
      return {
        ...state,
        selectedAccounts: action.payload,
      };
    default:
      return state;
  }
}
