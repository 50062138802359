import { useEffect, useState } from "react";
import {
  MdAddCircle,
  MdArrowBack,
  MdChevronLeft,
  MdChevronRight,
  MdSell,
} from "react-icons/md";

import { useNavigate, useParams } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import TagSelectionModal from "../components/Modal/TagSelection_Modal";
import moment from "moment";
import { sendGet, sendPost } from "../../common/request";
import links from "../../common/links";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";
import JournalDescription from "../components/JournalDescription";
import createHeader from "../../redux/header/createHeader";
import currencyFormat from '../../common/format';
import {getNetROI, getRealizedRMultiple} from "../../common/methods";
import {useTheme} from "../Providers/ThemeContext";

const JournalDetails = () => {
  const dispatch = useDispatch();
  const selectedAccount = useSelector(
    (state) => state.accounts.selectedAccounts
  );
  const navigate = useNavigate();
  let { t } = useTranslation();
  const accounts = useSelector((state) => state?.user?.user);
  const [data, setData] = useState([]);
  const [days, setDays] = useState([]);

  let navigation = useNavigate();
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [dateIndex, setDateIndex] = useState(-1);
  const [tradeToAddTag, setTradeToAddTag] = useState("");
  const [tags, setTags] = useState([]);
  const theme = useTheme();

  dispatch(createHeader(t("journal_details_page"), true, true));

  const handleOpenModal = (e, trade) => {
    e.stopPropagation();
    setTradeToAddTag(trade);
    setIsOpen(true);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);


  const handleCloseModal = () => {
    setTradeToAddTag(null);
    setIsOpen(false);
  };

  async function fetchSubAccounts() {
    try {
      let token = localStorage.getItem("token");
      setLoading(true);
      const response = await fetch(links.GetTrades(subUserId), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log('Journal Data: ', data);
      let dataDays = [];
      // Step 2: Calculate daily profit and loss
      data.forEach((item) => {
        const closeDate = moment(item.data.OpenTime).format("DD-MM-YYYY");
        if (!dataDays.includes(closeDate)) {
          dataDays.push(closeDate);
        }
      });
      dataDays = dataDays.sort((a, b) => {
        let date1 = moment(a, "DD-MM-YYYY").toDate();
        let date2 = moment(b, "DD-MM-YYYY").toDate();
        return date1 - date2;
      })
      setDays(dataDays);

      setDateIndex(dataDays.indexOf(journalDate));

      // const userData = data.filter((item) => item.subUserId === subUserId);

      if (data) {
        setData(data);
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching sub accounts:", error);
      setLoading(false);
    }
  }

  let handleAddModal = async (tradeId, tagIds) => {
    setIsModalLoading(true);
    let response = await sendPost(
      links.AssignTags,
      {
        tradeId: tradeId,
        tagIds: tagIds,
      },
      true
    );
    console.log(response);
    await fetchSubAccounts();
    handleCloseModal();
    setIsModalLoading(false);
  };

  let params = useParams();
  const journalDate = params.date;

  let loadTags = async () => {
    let response = await sendGet(links.GetTags, true);
    if (!response.error) {
      setTags(response.tags);
    }
  };

  useEffect(() => {
    loadTags();
  }, []);

  const subUserId = accounts?.user?.subAccounts?.filter(
    (account) => account?.name === selectedAccount
  )[0]?.id
    ? accounts?.user?.subAccounts?.filter(
        (account) => account?.name === selectedAccount
      )[0]?.id
    : accounts?.user?.subAccounts?.[0]?.id;

  useEffect(() => {
    fetchSubAccounts();
  }, [
    accounts?.user?.subAccounts,
    data?.length,
    data?.subUserId,
    selectedAccount,
    subUserId,
    journalDate,
  ]);

  const subAccountsNames =
    accounts?.user?.subAccounts?.map((account) => account.name) || [];

  let total = 0;
  let p = 0;
  let n = 0;
  let numberOfPositiveTrades = 0;
  let numberOfNegativeTrades = 0;
  let commision = 0;
  let PricePnL = 0;
  let CloseVolume = 0;
  let currentDate = new Date();
  let grossPnL = 0;

  const increaseDate = () => {
    if (dateIndex < days.length - 1) {
      let nextDate = days[dateIndex + 1];
      navigation(`/daily-journal/${nextDate}`);
    }
  };

  const decreaseDate = () => {
    if (dateIndex > 0) {
      let nextDate = days[dateIndex - 1];
      navigation(`/daily-journal/${nextDate}`);
    }
  };
  console.log(data)
  data?.map((item, index) => {
    const newDate = moment(item?.data?.OpenTime).format("DD-MM-YYYY");

    if (journalDate === newDate) {
      if (item?.data?.PnL > 0) {
        p+= parseFloat(item?.data?.PnL);
        numberOfPositiveTrades += 1;
        currentDate = new Date(item?.data?.OpenTime);
      }
      if (item?.data?.PnL < 0) {
        numberOfNegativeTrades += 1;
        n+= parseFloat(item?.data?.PnL);
      }
      if (item?.data?.Commission && typeof item?.data?.Commission == "string") {
        commision += parseFloat(item?.data?.Commission?.replace("$", ""));
      }
      if (item?.data?.CloseVolume) {
        CloseVolume += parseFloat(Math.abs(item?.data?.CloseVolume));
      }

      return item?.data?.PnL !== undefined
        ? (total += parseFloat(item?.data?.PnL))
        : total;
    }
  });

  grossPnL = total - commision;

  const filteredData = data.filter(
    (filteredItem) =>
      journalDate == moment(filteredItem.data.OpenTime).format("DD-MM-YYYY")
  );

  filteredData.sort((a, b) => {
    let d1 = moment(a.data.OpenTime).toDate();
    let d2 = moment(b.data.OpenTime).toDate();
    return d1 - d2;
  })

  const green = "#29D6A8";
  const red = "#ec787d";
  const primary = "#FF9326";
  const labels = filteredData.map((item, index) =>
    moment(item?.data?.OpenTime).format("HH:mm")
  );

  const gridColor = theme.theme === 'dark' ? "#4A484F" : '#DEDDDF';
  let lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tension: 0.3,
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
        border: {
          color: gridColor
        },
      },
      y: {
        border: {
          color: gridColor
        },
        grid: {
          color: gridColor,
          tickColor: gridColor
        },
        ticks: {
          callback: function(value, index, ticks) {
            return currencyFormat.format(value);
          }
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };
  const newData = filteredData.map((item) => item?.data?.PnL);
  const areaData = {
    labels: labels,
    datasets: [
      {
        label: "Balance",
        data: newData.map((item) => parseFloat(item)),
        borderWidth: 2,
        borderColor: '#2d90cc',
        pointBackgroundColor: red,
        fill: {
          target: 'origin',
          above: theme.theme === 'dark' ? 'rgba(41, 214, 168, 0.6)' : 'rgba(41, 214, 168, 0.2)',
          below: theme.theme === 'dark' ? 'rgba(236, 120, 125, 0.6)' : 'rgba(236, 120, 125, 0.2)'
        },
        pointRadius: 0,
      },
    ],
  };
  const profitFactor = p !== undefined && n != 0 ? (p / Math.abs(n)).toFixed(2) : 'N/A';

  let getMonth = (date) => {
    let months = [
      t("month_jan"),
      t("month_feb"),
      t("month_mar"),
      t("month_apr"),
      t("month_may"),
      t("month_jun"),
      t("month_jul"),
      t("month_aug"),
      t("month_sep"),
      t("month_oct"),
      t("month_nov"),
      t("month_dec"),
    ];
    return months[date.getMonth()];
  };

  let getDay = (date) => {
    let days = [
      t("day_sunday"),
      t("day_monday"),
      t("day_tuesday"),
      t("day_wednesday"),
      t("day_thursday"),
      t("day_friday"),
      t("day_saturday"),
    ];
    return days[date.getDay()];
  };
  const largeScreen = window.matchMedia("(max-width: 1280px)").matches;
  const mediumScreen = window.matchMedia("(max-width: 1024px)").matches;
  const smallScreen = window.matchMedia("(max-width: 768px)").matches;
  const extraSmallScreen = window.matchMedia("(max-width: 640px)").matches;
  const extraExtraSmallScreen = window.matchMedia("(max-width: 480px)").matches;
  const extraExtraExtraSmallScreen = window.matchMedia("(max-width: 360px)").matches;

  return (
    <>
      {loading ? (
        <Loader fullScreen={true} text={t("loading_text")} />
      ) : (
        <div className="page-body">
          <div className=" w-[100%]"> 
            <div className="flex items-center justify-start mt-4 mb-2 gap-2 w-[100%]">
              <button
                className="
                text-[#989898] text-2xl
                bg-transparent flex items-center justify-center font-noraml cursor-pointer border-none
                "
                onClick={() => navigation(-1)}
              >
                <MdArrowBack />
              </button>
              {dateIndex === 0 ? (
                <></>
              ) : (
                <button
                  className="
                  text-black text-base p-1 rounded-full bg-gray-200 flex items-center justify-center font-noraml cursor-pointer border-none
                  "
                  onClick={() => decreaseDate()}
                >
                  <MdChevronLeft />
                </button>
              )}
              <span className={`flex items-center p-2 font-bold text-xl gap-4 md:text-base sm:text-sm xs:text-xs xss:text-xs ${theme.theme === 'dark' ? 'text-gray-100' : 'text-gray-700'}`}>
                {getDay(currentDate)}, {currentDate.getDate()}{" "}
                {getMonth(currentDate)} {currentDate.getFullYear()}
              </span>
              {dateIndex === days.length - 1 ? (
                <></>
              ) : (
                <button
                  className="
                text-black text-base p-1 rounded-full bg-gray-200 flex items-center justify-center font-noraml cursor-pointer border-none
                "
                  onClick={() => increaseDate()}
                >
                  <MdChevronRight />
                </button>
              )}
            </div>
            <div className="w-full shadow-md rounded-sm w-[100%]">
              <div className="flex flex-col lg:flex-row justify-between p-5 rounded-sm gap-5">
                {/* Data Items */}
                <div className="flex flex-col w-1/2 sm:w-full xs:w-full xss:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 3xl:w-1/2 4xl:w-1/2">
                  {/* PNL */}
                  <div>
                    <p className={`font-bold text-base mb-2 ${theme.theme === 'dark' ? 'text-gray-100' : 'text-gray-700'}`}>
                      {t("journal_details_net_pnl")}
                    </p>
                    <h1
                      className={`${theme.theme === 'dark' ? (total > 0 ? "text-green-300" : "text-red-300") : (total > 0 ? "text-green-400" : "text-red-400")} text-24px font-bold`}
                    >
                      {currencyFormat.format(total)}
                    </h1>
                  </div>
                  {/* Remaining Items */}
                  <div className="flex flex-col gap-5 mt-5 lg:flex-row lg:gap-5 lg:mt-5">
                    <div className={`flex justify-between items-center flex-1 p-2 rounded-lg text-[14px] md:text-[12px] xl:text-base ${theme.theme === 'dark' ? 'bg-gray-700' : 'bg-gray-75'}`}>
                      <div>
                        <div className="mt-5">
                          <p className={`font-bold ${theme.theme === 'dark' ? 'text-gray-200' : 'text-gray-700'}`}>
                            {t("journal_details_total_trades")}
                          </p>
                          <p className={`${theme.theme === 'dark' ? 'text-gray-100' : 'text-black'}`}>
                            {filteredData.length}
                          </p>
                        </div>
                        <div className="mt-5">
                          <p className={`font-bold ${theme.theme === 'dark' ? 'text-gray-200' : 'text-gray-700'}`}>
                            {t("journal_details_win_rate")}
                          </p>
                          <p className={`${theme.theme === 'dark' ? 'text-gray-100' : 'text-black'}`}>
                            {Math.round(((numberOfPositiveTrades / filteredData.length) * 100) * 100) / 100}%
                          </p>
                        </div>
                      </div>

                      <div>
                        <div className="mt-5">
                          <p className={`font-bold ${theme.theme === 'dark' ? 'text-gray-200' : 'text-gray-700'}`}>
                            {t("journal_details_winners")}
                          </p>
                          <p className={`${theme.theme === 'dark' ? 'text-gray-100' : 'text-black'}`}>
                            {numberOfPositiveTrades}
                          </p>
                        </div>
                        <div className="mt-5">
                          <p className={`font-bold ${theme.theme === 'dark' ? 'text-gray-200' : 'text-gray-700'}`}>
                            {t("journal_details_losers")}
                          </p>
                          <p className={`${theme.theme === 'dark' ? 'text-gray-100' : 'text-black'}`}>
                            {numberOfNegativeTrades}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={`flex justify-between items-center flex-1 p-2 rounded-lg text-[14px] md:text-[12px] xl:text-base ${theme.theme === 'dark' ? 'bg-gray-700' : 'bg-gray-75'}`}>
                      <div>
                        <div className="mt-5">
                          <p className={`font-bold ${theme.theme === 'dark' ? 'text-gray-200' : 'text-gray-700'}`}>
                            {t("journal_details_commission")}
                          </p>
                          <p className={`${theme.theme === 'dark' ? 'text-gray-100' : 'text-black'}`}>
                            ${commision.toFixed(2)}
                          </p>
                        </div>
                        <div className="mt-5">
                          <p className={`font-bold ${theme.theme === 'dark' ? 'text-gray-200' : 'text-gray-700'}`}>
                            {t("journal_details_volume")}
                          </p>
                          <p className={`${theme.theme === 'dark' ? 'text-gray-100' : 'text-black'}`}>
                            {CloseVolume}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="mt-5">
                          <p className={`font-bold ${theme.theme === 'dark' ? 'text-gray-200' : 'text-gray-700'}`}>
                            {t("journal_details_gross_pnl")}
                          </p>
                          <p className={`${grossPnL > 0 ? "text-green-300" : "text-red-300"} `} >
                            {grossPnL >= 0
                              ? "$" + grossPnL.toFixed(2)
                              : "-$" + (grossPnL / -1).toFixed(2)}
                          </p>
                        </div>
                        <div className="mt-5">
                          <p className={`font-bold ${theme.theme === 'dark' ? 'text-gray-200' : 'text-gray-700'}`}>
                            {t("journal_details_profit_factor")}
                          </p>
                          <p className={`${profitFactor === 'N/A' ? (theme.theme === 'dark' ? 'text-gray-300' : 'text-gray-500') : profitFactor >= 0 ? 'text-green-300' : "text-red-300"}`}>
                            {profitFactor === 'N/A'
                              ? 'N/A'
                              : profitFactor >= 0
                              ? profitFactor
                              : "-" + (profitFactor / -1)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Line Chart */}
                <div>
                  <Line
                    data={areaData}
                    options={lineOptions}
                    height={`
                  ${
                    largeScreen
                      ? "400px"
                      : mediumScreen
                      ? "500px"
                      : smallScreen
                      ? "600px"
                      : extraSmallScreen
                      ? "600px"
                      : extraExtraSmallScreen
                      ? "600px"
                      : extraExtraExtraSmallScreen
                      ? "600px"
                      : "400px"
                  }
                  
                }`}
                    width={`
                ${
                  largeScreen
                    ? "500px"
                    : mediumScreen
                    ? "600px"
                    : smallScreen
                    ? "600px"
                    : extraSmallScreen
                    ? "600px"
                    : extraExtraSmallScreen
                    ? "600px"
                    : extraExtraExtraSmallScreen
                    ? "600px"
                    : "500px"
                }
                  `}
                  />
                </div>
              </div>
              <div style={{ paddingInline: "20px"}}
              >
                <p style={{ fontSize: "16px", marginBottom: "10px" }} className={`${theme.theme === 'dark' ? 'text-gray-100' : 'text-gray-700'}`}>
                  {t("journal_details_notes_heading")}
                </p>
                <JournalDescription currentDate={currentDate} subAccountId={subUserId}/>
              </div>

              <div className={`rounded-sm shadow-md p-5 mt-5 overflow-hidden overflow-x-auto w-full ${theme.theme === 'dark' ? 'bg-gray-650' : 'bg-white'}`}>
                <div style={{ display: "flex", alignItems: "center", padding: "10px", fontWeight: "bold", minWidth: "1000px"}}
                  className={`border-b ${theme.theme === 'dark' ? 'bg-primary-500 border-b-gray-500' : 'bg-primary-300 border-b-gray-200'}`}>
                  <div style={{ flex: 1 }}>
                    {t("journal_details_column_open_time")}
                  </div>
                  <div style={{ flex: 1 }}>
                    {t("journal_details_column_ticker")}
                  </div>
                  <div style={{ flex: 1 }}>
                    {t("journal_details_column_side")}
                  </div>
                  <div style={{ flex: 2 }}>
                    {t("journal_details_column_instrunment")}
                  </div>
                  <div style={{ flex: 1 }}>
                    {t("journal_details_column_net_pnl")}
                  </div>
                  <div style={{ flex: 1 }}>
                    {t("journal_details_column_net_roi")}
                  </div>
                  <div style={{ flex: 1 }}>
                    {t("journal_details_column_realized_r_multiple")}
                  </div>
                  <div style={{ flex: 1 }}>
                    {t("journal_details_column_Strategy")}
                  </div>
                  <div style={{ flex: 1 }}>
                    {t("journal_details_column_tags")}
                  </div>
                </div>
                {filteredData.map((item, index) => {
                  let time = moment(item.data.OpenTime).format("HH:mm:ss");
                  let setupTags = [];
                  let customTags = [];
                  let mistakeTags = [];
                  if (item.tags && item.tags.length > 0) {
                    setupTags = item.tags.filter((tag) => tag?.type === "Setup");
                    mistakeTags = item.tags.filter(
                      (tag) => tag?.type === "Mistake"
                    );
                    customTags = item.tags.filter(
                      (tag) => tag?.type === "Custom"
                    );
                  }
                  const playbook = item.playbooks && item.playbooks.length > 0 ? item.playbooks[0] : undefined;
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        marginLeft: "10px",
                        padding: "10px",
                        minWidth: "1000px",
                        cursor: 'pointer'
                      }}
                      onClick={() => navigation(`/trade/${item.id}`)}
                      className={`border-b ${theme.theme === 'dark' ? 'text-gray-200 border-b-gray-500' : 'text-gray-700 border-b-gray-200'}`}
                    >
                      <div style={{ flex: 1 }}>{time}</div>
                      <div style={{ flex: 1 }}>
                        {(item?.data?.SecurityId).slice(0, 4)}
                      </div>
                      <div style={{ flex: 1 }}>{item?.data?.Side}</div>
                      <div style={{ flex: 2 }}>{item?.data?.SecurityId}</div>
                      <div className={`${item?.data?.PnL >= 0 ? "text-green-300" : "text-red-300"}`}
                        style={{flex: 1}}
                      >
                        {currencyFormat.format(item?.data?.PnL)}
                      </div>
                      <div style={{ flex: 1 }}>
                        {
                          getNetROI(item?.data)
                        }%
                      </div>
                      <div style={{ flex: 1 }}>
                        {getRealizedRMultiple(item?.data)}R
                      </div>
                      <div style={{ flex: 1 }}>
                        {
                          playbook ?
                              <div className={`transition-all duration-300 ease-in-out cursor-pointer ${theme.theme === 'dark' ? 'text-gray-400 hover:text-gray-200' : 'text-gray-300 hover:text-gray-600'}`} onClick={(e) => {
                                e.stopPropagation();
                                navigate(`/playbook-details/overview/${playbook.id}`)
                              }}>
                                {playbook.name}
                              </div> :
                              <div>
                                -
                              </div>
                        }
                      </div>
                      {item.tags && item.tags.length > 0 ? (
                          <div style={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                          }}>
                          <div
                            style={{
                              marginBottom: "5px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {setupTags.length > 0 ? (
                              <div
                                className={`tag-circle tag-setup`}
                                style={{ position: "relative" }}
                              >
                                <div className="tooltip-container">
                                  {setupTags.map((tag) => {
                                    return (
                                      <div className={`horizontal-container tag tag-setup`}>
                                        <div className="setup">
                                          <MdSell />
                                        </div>
                                        {tag?.name}
                                      </div>
                                    );
                                  })}
                                </div>
                                {setupTags.length}
                              </div>
                            ) : (
                              <></>
                            )}
                            {mistakeTags.length ? (
                              <div className={`tag-circle tag-mistake`}>
                                <div className="tooltip-container">
                                  {mistakeTags.map((tag) => {
                                    return (
                                      <div className={`horizontal-container tag tag-mistake`}>
                                        <div className="mistake">
                                          <MdSell />
                                        </div>
                                        {tag?.name}
                                      </div>
                                    );
                                  })}
                                </div>
                                {mistakeTags.length}
                              </div>
                            ) : (
                              <></>
                            )}
                            {customTags.length ? (
                              <div className={`tag-circle tag-custom`}>
                                <div className="tooltip-container">
                                  {customTags.map((tag) => {
                                    return (
                                      <div className={`horizontal-container tag tag-custom`}>
                                        <div className="custom">
                                          <MdSell />
                                        </div>
                                        {tag?.name}
                                      </div>
                                    );
                                  })}
                                </div>
                                {customTags.length}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div
                            style={{
                              cursor: "pointer",
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                            className='text-primary-500'
                            onClick={(e) => handleOpenModal(e, item)}
                          >
                            <MdAddCircle />
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            flex: 1,
                            cursor: "pointer",
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                          className='text-primary-500'
                          onClick={(e) => handleOpenModal(e, item)}
                        >
                          <MdAddCircle />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      <TagSelectionModal
        isOpen={isOpen}
        tags={tags}
        trade={tradeToAddTag}
        handleCloseModal={handleCloseModal}
        handleAddModal={handleAddModal}
        isLoading={isModalLoading}
      />
    </>
  );
};

export default JournalDetails;
