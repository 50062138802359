import { MdContentCopy } from "react-icons/md";
import {FaFacebookF, FaRegEnvelope, FaTwitter} from 'react-icons/fa';
import Checkbox from "../checkbox";
import Modal from "./Modal";
import { useCallback, useEffect, useState } from "react";
import {toast} from 'react-toastify';
import {sendGet, sendPost} from '../../../common/request';
import Urls from '../../../common/links';
import Loader from '../Loader';

function TradeShareModal({
    tradeId,
    subAccountId,
    isShown,
    onClose
}) {
    
    const [isLoading, setIsLoading] = useState(false);
    const [showNetPNL, setShowNetPNL] = useState(false);
    const [showTradeNotes, setShowTradeNotes] = useState(false);
    const [showTags, setShowTags] = useState(false);
    const [showPlaybooks, setShowPlaybooks] = useState(false);
    const [isTradeShared, setIsTradeShared] = useState(false);
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const url = `${baseUrl}/share/trade/${subAccountId}/${tradeId}`

    const getSharedTrade = useCallback(async () => {
        if(tradeId && subAccountId) {
            const response = await sendGet(Urls.GetSharedTrade(tradeId, subAccountId), false);
            if(response.error) {
                setIsTradeShared(false);
            } else {
                const trade = response.trade;
                setShowNetPNL(trade.showNetPNL);
                setShowTradeNotes(trade.showTradeNotes);
                setShowTags(trade.showTags);
                setShowPlaybooks(trade.showPlaybooks);
                setIsTradeShared(true);
            }
        }
    }, [tradeId, subAccountId])

    useEffect(() => {
        getSharedTrade();
    }, [getSharedTrade, isShown])

    useEffect(() => {
        setShowNetPNL(false);
        setShowTradeNotes(false);
        setShowTags(false);
        setShowPlaybooks(false);
    }, [isShown])

    const copyUrl = () => {
        navigator.clipboard.writeText(url);
        toast.info('Url copied to clipboard')
    }

    const onShareTrade = async () => {
        setIsLoading(true);
        const response = await sendPost(Urls.ShareTrade(tradeId, subAccountId), {
            showNetPNL: showNetPNL,
            showPlaybooks: showPlaybooks,
            showTradeNotes: showTradeNotes,
            showTags: showTags
        }, true, 'application/json', true);
        if(response.error) {
            toast.error(response.error);
        } else {
            toast.success('Trade Shared Successfully');
            onClose(true);
        }
        setIsLoading(false);
    }

    const onStopShareTrade = async () => {
        setIsLoading(true);
        const response = await sendPost(Urls.StopShareTrade(tradeId, subAccountId), {}, true, '', false);
        if(response.error) {
            toast.error(response.error);
        } else {
            toast.success('Trade Stopped from Sharing Successfully');
            onClose(true);
        }
        setIsLoading(false);
    }

    return (
        <Modal isShown={isShown} onClose={onClose} title={'Share Trade'} className={'share-modal'}>
            <Modal.Body>
                <div className="body-content-container">
                    {
                        isLoading ? 
                        <div className="loader-set-container">
                            <Loader text={'Hold Tight! We are loading your data'}/>
                        </div> : <></>
                    }
                    <div className="heading">Control your privacy</div>
                    <div className="sub-heading">What do you wish to share</div>
                    <div className="privacy-elements-container">
                        <Checkbox title={'Net P&L'} value={showNetPNL} onChange={value => setShowNetPNL(value)}/>
                        <Checkbox title={'Trade Notes'} value={showTradeNotes} onChange={value => setShowTradeNotes(value)}/>
                        <Checkbox title={'Tags'} value={showTags} onChange={value => setShowTags(value)}/>
                        <Checkbox title={'Playbooks'} value={showPlaybooks} onChange={value => setShowPlaybooks(value)}/>
                    </div>
                    <div className="link-container">
                        <div className="heading">Trade Link</div>
                        <div className="link-item">
                            <div className="text">{url}</div>
                            <div className="icon" onClick={copyUrl}>
                                <MdContentCopy />
                            </div>
                        </div>
                    </div>
                    <div className="social-media-container">
                        <div className="heading">Social Media</div>
                        <div className="social-media-icons">
                            <div className="social-media-icon facebook">
                                <FaFacebookF />
                            </div>
                            <div className="social-media-icon twitter">
                                <FaTwitter />
                            </div>
                            <div className="social-media-icon email">
                                <FaRegEnvelope />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="footer">
                    <div className="button-container">
                        {
                            isTradeShared ? 
                            <div className="danger-btn" onClick={onStopShareTrade}>
                                Stop Sharing
                            </div> : <div></div>
                        }
                        <div className="horizontal-container">
                            <div className="secondary-btn" onClick={() => onClose(false)}>
                                Cancel
                            </div>
                            <div className="primary-btn" onClick={onShareTrade}>
                                Share
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default TradeShareModal;