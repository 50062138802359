const Send = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.24961 7.90019L19.8621 4.36269C24.6246 2.77519 27.2121 5.37519 25.6371 10.1377L22.0996 20.7502C19.7246 27.8877 15.8246 27.8877 13.4496 20.7502L12.3996 17.6002L9.24961 16.5502C2.11211 14.1752 2.11211 10.2877 9.24961 7.90019Z"
                stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.6367 17.0627L17.1117 12.5752" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>

    )
}

export default Send;