import {useEffect} from "react";

const ScrollToTop = () => {

    useEffect(() => {
        window.scroll({top: 0, behavior: 'instant'});
    }, []);

    return (<></>)
}

export default ScrollToTop