import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import links from "../../../common/links";
import { sendGet } from "../../../common/request";
import { useTranslation } from "react-i18next";
import currencyFormat from "../../../common/format";
import {useTheme} from "../../Providers/ThemeContext";

const SetupsReport = () => {
  const selectedAccount = useSelector(
    (state) => state.accounts.selectedAccounts
  );
  const selectedDate = useSelector((state) => state.date.selectedDate);
  const endDate = useSelector((state) => state.date.endDate);
  const accounts = useSelector((state) => state?.user?.user);
  const [data, setData] = useState([]);
  const [TotalPnl, setTotalPnl] = useState(0);
  const [dailyProfit, setDailyProfit] = useState([]);
  let { t } = useTranslation();
  const theme = useTheme();
  const [objectData, setObjectData] = useState({
    Total_PNL: TotalPnl,
    Average_Daily_volume: 0,
    Average_Winning_Trade: 0,
    Average_Losing_Trade: 0,
    Total_Number_of_Trades: 0,
    Winning_Trades: 0,
    Losing_Trades: 0,
    Breakeven_Trades: 0,
    Max_Consecutive_Winning_Trades: 0,
    Max_Consecutive_Losing_Trades: 0,
    Total_Commission: 0,
    Total_Fees: 0,
    Total_Swap: 0,
    Largest_Profit: 0,
    Largest_Loss: 0,
    Average_Holding_Time_All_Trades: 0,
    Average_Holding_Time_Winning_Trades: 0,
    Average_Holding_Time_Losing_Trades: 0,
    Average_Holding_Time_Scratch_Trades: 0,
    Average_Trade_PnL: 0,
    Profit_Factor: 0,
    Open_Trades: 0,
    Total_Trading_Days: 0,
    Winning_Days: 0,
    Losing_Days: 0,
    Breakeven_Days: 0,
    Logged_Days: 0,
    Max_Consecutive_Losing_Days: 0,
    Max_Consecutive_Winning_Days: 0,
    Average_Daily_PnL: 0,
    Average_Daily_Winning_PnL: 0,
    Average_Daily_Losing_PnL: 0,
    Largest_Profitable_Day: 0,
    Largest_Loss_Day: 0,
    Average_Planned_R_Multiple: 0,
    Average_Realized_R_Multiple: 0,
    Total_Expectancy: 0,
  });

  const [tagData, setTagData] = useState([]);

  const subUserId = accounts?.user?.subAccounts?.filter(
    (account) => account?.name === selectedAccount
  )[0]?.id
    ? accounts?.user?.subAccounts?.filter(
        (account) => account?.name === selectedAccount
      )[0]?.id
    : accounts?.user?.subAccounts?.[0]?.id;

  useEffect(() => {
    async function fetchSubAccounts() {
      try {
        let token = localStorage.getItem("token");
        const response = await fetch(links.GetTrades(subUserId), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();

        let dailyProfitObj = {};
        let dailyLossObj = {};
        let days = [];
        // Step 2: Calculate daily profit and loss
        data.forEach((item) => {
          const closeDate = new Date(item.data.CloseTime).toDateString();
          const pnl = parseFloat(item.data.PnL);
          if (!isNaN(pnl)) {
            if (!days.includes(closeDate)) days.push(closeDate);

            if (pnl > 0) {
              // Positive PnL
              dailyProfitObj[closeDate] =
                (dailyProfitObj[closeDate] || 0) + pnl;
            } else if (pnl < 0) {
              // Negative PnL
              dailyLossObj[closeDate] = (dailyLossObj[closeDate] || 0) + pnl;
            }
          }
        });

        // Filter data for the specific subUserId
        const numberOfPositiveDays = [];
        const numberOfNegativeDays = [];
        const NewSelecteddate = new Date(selectedDate);
        const NewEndDate = new Date(endDate);
        days.map((t) => {
          let date = moment(t, "DD/MM/YYYY").toDate();
          if (date >= NewSelecteddate && date <= NewEndDate) {
            let profit = dailyProfitObj[t];
            let loss = dailyLossObj[t];
            if (profit && !loss) {
              numberOfPositiveDays.push(t);
            } else if (!profit && loss) {
              numberOfNegativeDays.push(t);
            } else {
              if (profit + loss >= 0) {
                numberOfPositiveDays.push(t);
              } else {
                numberOfNegativeDays.push(t);
              }
            }
          }
        });

        const userData = data.filter((item) => item.subUserId === subUserId);

        if (data) {
          setData(data);
          let positivePnl = 0;
          let negativePnl = 0;

          const TotalPnls = data.reduce((Total, item) => {
            const openDates = new Date(item?.data?.OpenTime);

            const NewSelecteddate = new Date(selectedDate);
            const NewEndDate = new Date(endDate);

            if (
              item?.data &&
              item?.data?.PnL !== undefined &&
              openDates >= NewSelecteddate &&
              openDates <= NewEndDate
            ) {
              const pnl = parseFloat(item?.data?.PnL);
              // Check if pnl is a number before adding to Total
              if (!isNaN(pnl)) {
                if (pnl > 0) {
                  positivePnl += pnl;
                } else {
                  negativePnl += pnl;
                }
                return Total + pnl;
              } else {
                console.error("Invalid PnL value:", pnl);
                return Total;
              }
            } else {
              console.error("PnL is not available:", item.data);
              return Total;
            }
          }, 0);

          const dailyProfitArray = Object.entries(dailyProfitObj).map(
            ([day, pnl]) => ({
              day,
              pnl,
            })
          );

          const dailyLossArray = Object.entries(dailyLossObj).map(
            ([day, pnl]) => ({
              day,
              pnl,
            })
          );

          setTotalPnl(TotalPnls);
          console.log(dailyProfitArray);
          setDailyProfit(dailyProfitArray);
        } else {
          // If no data is found for the subUserId, set TotalPnl to 0
          setTotalPnl(0);
          setDailyProfit([]);
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching sub accounts:", error);
      }
    }

    fetchSubAccounts();
  }, [
    accounts?.user?.subAccounts,
    data?.length,
    data?.subUserId,
    selectedAccount,
    subUserId,
    selectedDate,
    endDate,
  ]);

  useEffect(
    () => {
      let highPnL = 0;
      let lowPnL = 0;
      let gethighPnLDate = 0;
      let getlowPnLDate = 0;
      let TotalPnL = 0;
      let average = 0;
      let TotalPnls = 0;
      let Average_Daily_volume = 0;
      let Average_Daily_Winning_PnL = 0;
      let Average_Daily_Losing_PnL = 0;
      let Total_Number_of_Trades = 0;
      let Max_Consecutive_Winning_Trades = 0;
      let Max_Consecutive_Losing_Trades = 0;
      let Total_Commission = 0;
      let Largest_Profit = 0;
      let Average_Holding_Time_All_Trades = 0;
      let Average_Holding_Time_Winning_Trades = 0;
      let Average_Holding_Time_Losing_Trades = 0;
      let Average_Trade_PnL = 0;
      let Profit_Factor = 0;
      let Open_Trades = 0;
      let Total_Trading_Days = 0;
      let Winning_Days = 0;
      let Losing_Days = 0;
      let Average_Daily_pnl = 0;
      let daily_Winning_PnL = 0;
      let daily_Loss_PnL = 0;

      data.map((item) => {
        const openDates = new Date(item?.data?.OpenTime);
        const NewSelecteddate = new Date(selectedDate);
        const NewEndDate = new Date(endDate);
        if (
          item?.data &&
          item?.data?.PnL !== undefined &&
          openDates >= NewSelecteddate &&
          openDates <= NewEndDate
        ) {
          const pnl = parseFloat(item?.data?.PnL);
          // Check if pnl is a number before adding to Total
          TotalPnls += pnl;
          const CloseVolume = parseFloat(item?.data?.CloseVolume);
          const OpenVolume = parseFloat(item?.data?.OpenVolume);
          Average_Daily_volume += CloseVolume - OpenVolume;
          Total_Commission += parseFloat(item?.data?.Commission);
          Largest_Profit = Math.max(Largest_Profit, pnl);

          Average_Holding_Time_All_Trades +=
            (new Date(item?.data?.CloseTime).getTime() -
              new Date(item?.data?.OpenTime).getTime()) /
            1000 /
            60 / // Convert milliseconds to minutes
            60; // Convert minutes to hours

          Average_Trade_PnL += pnl;
          Profit_Factor += pnl > 0 ? pnl : 0;

          Open_Trades += item?.data?.CloseTime ? 0 : 1;
          Total_Trading_Days += 1;

          Average_Daily_pnl += pnl / Total_Trading_Days;

          if (pnl > 0) {
            Average_Daily_Winning_PnL++;
          } else {
            Average_Daily_Losing_PnL++;
          }

          Total_Number_of_Trades++;

          daily_Winning_PnL += pnl > 0 ? 1 : 0 / Total_Trading_Days;

          daily_Loss_PnL += pnl < 0 ? 1 : 0 / Total_Trading_Days;

          if (openDates.getDate() === NewSelecteddate.getDate()) {
            if (pnl > 0) {
              Max_Consecutive_Winning_Trades++;
              Average_Holding_Time_Winning_Trades +=
                (new Date(item?.data?.CloseTime).getTime() -
                  new Date(item?.data?.OpenTime).getTime()) /
                1000 /
                60 / // Convert milliseconds to minutes
                60; // Convert minutes to hours
              Winning_Days++;
            } else {
              Max_Consecutive_Losing_Trades++;
              Average_Holding_Time_Losing_Trades +=
                (new Date(item?.data?.CloseTime).getTime() -
                  new Date(item?.data?.OpenTime).getTime()) /
                1000 /
                60 / // Convert milliseconds to minutes
                60; // Convert minutes to hours
              Losing_Days++;
            }
          }

          if (!isNaN(pnl)) {
            if (pnl > highPnL) {
              highPnL = pnl;
              gethighPnLDate = item?.data?.CloseTime;
            }
            if (pnl < lowPnL) {
              lowPnL = pnl;
              getlowPnLDate = item?.data?.CloseTime;
            }
            TotalPnL += pnl;
          } else {
            console.error("Invalid PnL value:", pnl);
          }
        } else {
          console.error("PnL is not available:", item.data);
        }
      });
      let averagewin = Total_Number_of_Trades / Average_Daily_Winning_PnL;
      let averageloss = Total_Number_of_Trades / Average_Daily_Losing_PnL;

      average = TotalPnL / data.length;
      setObjectData({
        ...objectData,
        TotalPnl: TotalPnls >= 0 ? "$" + TotalPnls : "-$" + TotalPnls / -1,
        Average_Daily_volume: Average_Daily_volume.toFixed(2),
        Average_Winning_Trade:
          averagewin >= 0
            ? "$" + averagewin.toFixed(2)
            : "-$" + averagewin.toFixed(2) / -1,
        Average_Losing_Trade:
          averageloss >= 0
            ? "$" + averageloss.toFixed(2)
            : "-$" + averageloss.toFixed(2) / -1,
        Total_Number_of_Trades: Total_Number_of_Trades,
        Winning_Trades: Average_Daily_Winning_PnL,
        Losing_Trades: Average_Daily_Losing_PnL,
        Max_Consecutive_Losing_Trades: Max_Consecutive_Losing_Trades,
        Max_Consecutive_Winning_Trades: Max_Consecutive_Winning_Trades,
        Total_Commission:
          Total_Commission >= 0
            ? "$" + Total_Commission
            : "-$" + Total_Commission / -1,
        Largest_Profit:
          Largest_Profit >= 0
            ? "$" + Largest_Profit
            : "-$" + Largest_Profit / -1,
        Largest_Loss: lowPnL >= 0 ? "$" + lowPnL : "-$" + lowPnL / -1,
        Average_Holding_Time_All_Trades:
          Average_Holding_Time_All_Trades.toFixed(2) + " " + "minutes",
        Average_Holding_Time_Winning_Trades:
          Average_Holding_Time_Winning_Trades.toFixed(2) + " " + "minutes",
        Average_Holding_Time_Losing_Trades:
          Average_Holding_Time_Losing_Trades.toFixed(2) + " " + "minutes",
        Average_Trade_PnL: Average_Trade_PnL.toFixed(2)
          ? Profit_Factor > 0
            ? "$" + Profit_Factor.toFixed(2)
            : "-$" + Profit_Factor.toFixed(2) / -1
          : Profit_Factor.toFixed(2) > 0
          ? "$" + Profit_Factor.toFixed(2)
          : "-$" + Profit_Factor.toFixed(2) / -1,
        Average_Holding_Time_Scratch_Trades: "N/A",
        Open_Trades: Open_Trades,
        Total_Trading_Days: Total_Trading_Days,
        Winning_Days: Winning_Days,
        Losing_Days: Losing_Days,
        Average_Daily_PnL:
          Average_Daily_pnl.toFixed(2) > 0
            ? "$" + Average_Daily_pnl.toFixed(2)
            : "-$" + Average_Daily_pnl.toFixed(2) / -1,
        Average_Daily_Winning_PnL:
          daily_Winning_PnL.toFixed(2) > 0
            ? "$" + daily_Winning_PnL.toFixed(2)
            : "-$" + daily_Winning_PnL.toFixed(2) / -1,
        Average_Daily_Losing_PnL:
          daily_Loss_PnL.toFixed(2) > 0
            ? "$" + daily_Loss_PnL.toFixed(2)
            : "-$" + daily_Loss_PnL.toFixed(2) / -1,
        Largest_Profitable_Day: new Date(gethighPnLDate).toLocaleString(
          "default",
          {
            day: "numeric",
            month: "long",
          }
        ),
        Largest_Loss_Day: new Date(getlowPnLDate).toLocaleString("default", {
          day: "numeric",
          month: "long",
        }),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  useEffect(() => {
    let loadTagData = async () => {
      let response = await sendGet(links.TagTrades, true);
      if (!response.error) {
        let accumulatedTagData = [];
        for (let tag of response.tags) {
          let profit = 0;
          let loss = 0;
          let volume = 0;
          let winning = 0;
          let count = 0;
          if (tag?.type != "Setup") continue;

          for (let trade of tag.trades) {
            let closeTime = Date.parse(trade.data.CloseTime);
            //If the trade lies in the selected time then
            if (closeTime >= selectedDate && closeTime <= endDate) {
              let pnl = parseFloat(trade.data.PnL);
              if (pnl > 0) {
                profit += pnl;
                winning += 1;
              } else if (pnl < 0) loss += pnl;

              volume += Math.abs(parseFloat(trade.data.CloseVolume));
              count += 1;
            }
          }

          if (count != 0) {
            //Creating an entry
            accumulatedTagData.push({
              name: tag.name,
              count: count,
              total: profit + loss,
              profit: profit,
              loss: loss,
              volume: volume,
              winning: (winning / tag.trades.length) * 100,
            });
          }
        }
        setTagData(accumulatedTagData);
      }
    };

    loadTagData();
  }, [
    accounts?.user?.subAccounts,
    data?.length,
    data?.subUserId,
    selectedAccount,
    subUserId,
    selectedDate,
    endDate,
  ]);

  const green = "#29D6A8";
  const red = "#ec787d";
  const primary = "#FF9326";
  const labels = dailyProfit
    .map((day) => {
      if (new Date(day.day).getMonth() === new Date(selectedDate).getMonth()) {
        return day.day;
      }
      return null; // Return null for days that don't match the condition
    })
    .filter((label) => label !== null); // Filter out null values

  let formatData = (data) => {
    let formattedData = [];
    data.forEach((item) => {
      if (item.data) {
        const closeDate = new Date(item.data.CloseTime).toDateString();
        const pnl = parseFloat(item.data.PnL);
        if (!isNaN(pnl)) {
          formattedData.push({
            day: closeDate,
            pnl,
          });
        }
      }
    });
    return formattedData;
  };

  const formattedData = formatData(data);

  const accumulatedData = labels.map((label) => {
    const dayData = formattedData.filter(
      (day) => day.day === label && day.pnl !== 0
    );
    return dayData.reduce((sum, day) => sum + day.pnl, 0);
  });

  const barChartData = {
    labels: labels.map((label) => {
      // get day of the week
      const day = new Date(label).toDateString();
      return day;
    }),
    datasets: [
      {
        label: "Daily P",
        indexAxis: "x",
        data: accumulatedData,
        borderRadius: 5,
      },
    ],
  };

  // Dynamically set the background color based on positive or negative value
  barChartData.datasets[0].backgroundColor = barChartData.datasets[0].data.map(
    (value) => (value > 0 ? green : red)
  );

  let purple = "#00df9d";

  function isDateWithinRange(date, startDate, endDate) {
    // Set hours, minutes, seconds, and milliseconds to 0 for accurate comparison
    date.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    return date >= startDate && date <= endDate;
  }
  const gridColor = theme.theme === 'dark' ? "#4A484F" : '#DEDDDF';
  const barSecondHighChartOptionsSetup = {
    chart: {
      type: "bar",
      backgroundColor: theme.theme === 'dark' ? "transparent" : 'white'
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: tagData.map((tag) => tag.name),
      title: {
        text: "SetupsReport",
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        }
      },
      labels: {
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        }
      },
      lineColor: gridColor
    },
    yAxis: {
      title: {
        text: "Accumulated PnL",
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        }
      },
      gridLineColor: gridColor,
      labels: {
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        },
        formatter: (data) => {
          return currencyFormat.format(data.value)
        }
      }
    },
    series: [
      {
        showInLegend: false,
        data: tagData.map((tag) => tag.total),
        color: green,
        negativeColor: red,
        borderColor: "transparent"
      },
    ],
  };

  const barHighChartOptionsSetup = {
    chart: {
      type: "bar",
      backgroundColor: theme.theme === 'dark' ? "transparent" : 'white'
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: tagData.map((tag) => tag.name),
      title: {
        text: "SetupsReport",
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        }
      },
      labels: {
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        }
      },
      lineColor: gridColor
    },
    yAxis: {
      title: {
        text: "Number of Trades",
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        }
      },
      gridLineColor: gridColor,
      labels: {
        style: {
          color: theme.theme === 'dark' ? "#DEDDDF" : 'black',
        }
      }
    },
    series: [
      {
        showInLegend: false,
        data: tagData.map((tag) => ({
          y: tag.count,
        })),
        color: purple,
        borderColor: "transparent"
      },
    ],
  };

  return (
    <div className="w-full flex flex-col items-center pt-4">
      <div className="items-center w-11/12 justify-center m-0 sm:m-2 md:m-4 p-0">
        <div className="flex mb-4 w-full items-center">
          <div className="flex flex-col mt-1 w-full md:flex-col sm:flex-col xs:flex-col lg:flex-row xl:flex-row 2xl:flex-row gap-4">
            <div
                className={`mb-4 md:mb-0 w-[100%] rounded-md p-4 ${theme.theme === 'dark' ? 'bg-transparent' : 'bg-white'}`}
                style={{boxShadow: theme.theme === 'dark' ? "rgba(255, 255, 255, 0.1) 0px 0px 16px" : "rgba(17, 17, 26, 0.1) 0px 0px 16px"}}>
              <h3 className={`text-base font-bold ${theme.theme === 'dark' ? 'text-gray-100' : 'text-black'}`}>
                {t("setups_report_distribution_heading")} <br/> (All Trades)
              </h3>
              <div className="high-chart">
                <HighchartsReact
                    containerProps={{style: {width: "100%"}}}
                    highcharts={Highcharts}
                    options={barHighChartOptionsSetup}
                />
              </div>
            </div>
            <div
                className={`mb-4 md:mb-0 w-[100%] md:mr-2 rounded-md p-4 ${theme.theme === 'dark' ? 'bg-transparent' : 'bg-white'}`}
                style={{boxShadow: theme.theme === 'dark' ? "rgba(255, 255, 255, 0.1) 0px 0px 16px" : "rgba(17, 17, 26, 0.1) 0px 0px 16px"}}>
              <h3 className={`text-base font-bold ${theme.theme === 'dark' ? 'text-gray-100' : 'text-black'}`}>
                {t("setups_report_performance_heading")} <br/> (All Setups)
              </h3>
              <div className="high-chart">
                <HighchartsReact
                    containerProps={{style: {width: "100%"}}}
                    highcharts={Highcharts}
                    options={barSecondHighChartOptionsSetup}
                />
              </div>
            </div>
          </div>
        </div>
        <div
            className={`rounded-md p-4 gap-4 w-full overflow-hidden ${theme.theme === 'dark' ? 'transparent' : 'bg-white'}`}
            style={{boxShadow: theme.theme === 'dark' ? "rgba(255, 255, 255, 0.1) 0px 0px 16px" : "rgba(17, 17, 26, 0.1) 0px 0px 16px"}}>
          <h1 className={`text-lg font-bold ${theme.theme === 'dark' ? 'text-gray-100' : 'text-gray-800'}`}>
            {t("setups_report_summary_heading")}
          </h1>
          <hr className={`border-t my-2 ${theme.theme === 'dark' ? 'border-gray-500' : 'border-gray-200'}`}/>
          <div className="rounded-sm p-5 mt-5 overflow-hidden overflow-x-auto w-full">
            <div
                style={{display: "flex", alignItems: "center", padding: "10px", fontWeight: "bold", minWidth: "1000px"}}
                className={`bg-primary-base border-b ${theme.theme === 'dark' ? 'border-b-gray-500' : 'border-b-gray-200'}`}>
              <div className="flex-1">{t("setups_report_label_setup")}</div>
              <div className="flex-1">{t("setups_report_label_net_profit")}</div>
              <div className="flex-1">{t("setups_report_label_winning")}</div>
              <div className="flex-1">{t("setups_report_label_total_profit")}</div>
              <div className="flex-1">{t("setups_report_label_total_loss")}</div>
              <div className="flex-1">{t("setups_report_label_trades")}</div>
              <div className="flex-1">{t("setups_report_label_volume")}</div>
            </div>
            {tagData.map((tag, index) => (
                <div key={index}
                     className={`flex items-center justify-center border-b ${theme.theme === 'dark' ? 'border-b-gray-500 text-gray-100' : 'border-b-gray-200 text-gray-700'} ${theme.theme === 'dark' ? (index % 2 === 0 ? "bg-gray-600" : "bg-gray-650") : (index % 2 === 0 ? "bg-white" : "bg-gray-75")}`}
                     style={{
                       display: "flex",
                       padding: '10px',
                       minWidth: "1000px",
                       cursor: 'pointer',
                     }}>
                  <div className="flex-1">{tag.name}</div>
                  <div
                      className={`flex-1 ${theme.theme === 'dark' ? (tag?.total >= 0 ? "text-green-200" : "text-red-200") : (tag?.total >= 0 ? "text-green-400" : "text-red-400")} font-bold`}>
                    {currencyFormat.format(tag?.total)}
                  </div>
                  <div className="flex-1">{tag.winning.toFixed(2)}%</div>
                  <div
                      className={`flex-1 ${theme.theme === 'dark' ? (tag.profit > 0 ? "text-green-200" : "text-gray-100") : (tag.profit > 0 ? "text-green-400" : "text-black")}`}>
                    {currencyFormat.format(tag?.profit)}
                  </div>
                  <div className={`flex-1 ${theme.theme === 'dark' ? (tag.loss >= 0 ? "text-gray-100" : "text-red-200") : (tag.loss >= 0 ? "text-black" : "text-red-400")}`}>
                    {currencyFormat.format(tag?.loss)}
                  </div>
                  <div className="flex-1">{tag.count}</div>
                  <div className="flex-1">{tag.volume.toFixed(2)}</div>
                </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupsReport;
