import { MdClose } from "react-icons/md";
import "../../styles/modal.scss";
import { createContext } from "react";
import {useTheme} from "../../Providers/ThemeContext";

const BodyContext = createContext(null);
const FooterContext = createContext(null);

const Body = ({children}) => {
    return (
        <BodyContext.Provider value={children}>
            <div className="modal-body">
                {children}
            </div>
        </BodyContext.Provider>
    )
}

const Footer = ({children}) => {
    return (
        <FooterContext.Provider value={children}>
            <div className="modal-footer">
                {children}
            </div>
        </FooterContext.Provider>
    )
}

const Modal = ({
  isShown,
  onClose,
  title,
  children,
  className
}) => {

    const theme = useTheme();

  return (
    <div className={`modal-component-container ${isShown ? 'show' : 'hide'}`}>
        <div className="modal-overlay" onClick={() => onClose(false)}>
        </div>
        <div className={`modal ${className ? className : ''} ${theme.getThemeClass()}`}>
            <div className="modal-header">
                <div className="title">
                    {title}
                </div>
                <div className="close-btn" onClick={() => onClose(false)}>
                    <MdClose />
                </div>
            </div>
            {children}
        </div>
    </div>
  );
};

Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
