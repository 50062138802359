import { Outlet, useNavigate, useParams } from "react-router-dom";
import "../../styles/playbook-layout.scss";
import PlaybookPage from '../../enums/playbook-pages';
import { useState } from "react";
import { MdChevronLeft, MdMenu } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { hideTitle } from "../../../redux/header/action";
import {useTheme} from "../../Providers/ThemeContext";

function PlaybookLayout() {

    const [currentPage, setCurrentPage] = useState(PlaybookPage.Overview);
    const {id} = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const changePage = (page) => {
        setCurrentPage(page);

        if(page === PlaybookPage.Overview) {
            navigate(`/playbook-details/overview/${id}`);
        } else if(page === PlaybookPage.Rules) {
            navigate(`/playbook-details/rules/${id}`);
        } else if(page === PlaybookPage.ExecutedTrades) {
            navigate(`/playbook-details/executions/${id}`);
        }
    }
    const dispatch = useDispatch();
    dispatch(hideTitle())
    const theme = useTheme();

    return (
        <>
            <div className="page-header">
                <div className="horizontal-container">
                    <div className="back-btn" onClick={() => navigate(-1)}>
                        <div className="icon-container">
                            <MdChevronLeft/>
                        </div>
                    </div>
                    <div className="title">{t("playbook_layout_heading")}</div>
                    <div className="sidebar-expand-btn">
                        <MdMenu/>
                    </div>
                </div>
            </div>
            <div className="page-body">
                <div className={`playbook-layout ${theme.getThemeClass()}`}>
                    <div className="tab-bar-container">
                        <div className="tab-bar">
                            <div className={`tab-btn ${currentPage === PlaybookPage.Overview ? 'active' : ''}`}
                                 onClick={() => changePage(PlaybookPage.Overview)}>
                                {t("playbook_overview_tab")}
                            </div>
                            <div className={`tab-btn ${currentPage === PlaybookPage.Rules ? 'active' : ''}`}
                                 onClick={() => changePage(PlaybookPage.Rules)}>
                                {t("playbook_rules_tab")}
                            </div>
                            <div className={`tab-btn ${currentPage === PlaybookPage.ExecutedTrades ? 'active' : ''}`}
                                 onClick={() => changePage(PlaybookPage.ExecutedTrades)}>
                                {t("playbook_executed_trades_tab")}
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <Outlet/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PlaybookLayout;