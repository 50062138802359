import React, { useCallback, useEffect, useState } from "react";
import { MdSearch, MdOutlineArrowBack } from "react-icons/md";
import {sendGet, sendPost} from '../../common/request';
import Urls from '../../common/links';
import useSubAccount from "../hooks/useSubAccount";
import { toast } from "react-toastify";
import ImagePlaceHolder from "../images/image-placeholder.jpg"
import Loader from "./Loader";
import {useTranslation} from "react-i18next";

export default function SearchGroup({ handleChatPage, onGroupJoin }) {
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [groups, setGroups] = useState([]);
  const subAccount = useSubAccount();
  const {t} = useTranslation();

  const loadGroups = useCallback(async () => {
    setIsLoading(true);
    if(subAccount && subAccount.UserId) {
      const response = await sendGet(Urls.GetAllGroups, true);
      if(response.error) {
        toast.error(response.error);
        console.log(response.error);
      } else {
        const filteredGroups = response.groups.filter(group => {
          const memberIds = group.Members.map(m => m.id);
          if(memberIds.includes(subAccount.UserId)) {
            return false;
          } else {
            return true;
          }
        })
        setGroups(filteredGroups);
      }
    }
    setIsLoading(false);
  }, [subAccount])

  useEffect(() => {
    loadGroups();
  }, [loadGroups]);

  const handleInputChange = (event) => {
    let value = event.target.value;
    value = value.toLowerCase()
    setQuery(value);

    if (value.length > 0) {
      const filtered = groups.filter((suggestion) =>
        suggestion.name.toLowerCase().includes(value)
      );
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  };

  const joinGroup = async (group) => {
    setIsLoading(true);
    const response = await sendPost(Urls.JoinGroup(group.id), {}, true, 'application/json', true);
    if(response.error) {
      console.log(response.error);
      toast.error(response.error)
    } else {
      onGroupJoin();
    }
    setIsLoading(false)
  }

  return (
    <>
      {
        isLoading ?
          <Loader fullScreen={true}/> :
          <div className="search-community">
              <div className="back-btn" onClick={handleChatPage}>
                <MdOutlineArrowBack/>
              </div>
              <h1 className="search-community-heading">{t("community.searchGroup.header")}</h1>
              <div className="main-search-container">
                <div className="search-bar-ug">
                  <MdSearch/>
                  <input
                      type="search"
                      id="search-groups-users"
                      name="query"
                      placeholder={t("community.searchGroup.placeholder")}
                      value={query}
                      onChange={handleInputChange}
                  />
                </div>
                <div className="suggestions-list">
                  {filteredSuggestions.length > 0 ?
                      <ul className="search-dropdown">
                        {filteredSuggestions.map((suggestion, index) => (
                            <div className="searched-groups">
                              <div>
                                <img
                                    className="user-image"
                                    src={suggestion.image ?? ImagePlaceHolder}
                                    alt=""
                                />
                                <li key={index}>{suggestion.name}</li>
                              </div>
                              <button
                                  onClick={() => joinGroup(suggestion)}
                              >
                                <svg
                                    className="start-chat"
                                    width="21"
                                    height="20"
                                    viewBox="0 0 21 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                      d="M6.66641 5.2668L13.7414 2.90846C16.9164 1.85013 18.6414 3.58346 17.5914 6.75846L15.2331 13.8335C13.6497 18.5918 11.0497 18.5918 9.46641 13.8335L8.76641 11.7335L6.66641 11.0335C1.90807 9.45013 1.90807 6.85846 6.66641 5.2668Z"
                                      stroke="#000000"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                  />
                                  <path
                                      d="M8.92578 11.3755L11.9091 8.38379"
                                      stroke="#000000"
                                      stroke-width="1.5"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>
                        ))}
                      </ul>
                      : !query ?
                      <p className="empty-message">{t("community.searchGroup.message")}</p> : <p className="empty-message">{t("community.searchGroup.notFoundMessage")}</p>
                  }
                </div>
              </div>
            </div>
      }
    </>
  );
}
