import { useEffect, useState, useCallback, useMemo } from "react";
import "../styles/calendar.scss";
import { MdChevronLeft, MdChevronRight, MdCalendarMonth } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import numberFormat from "../../common/format";
import {useTheme} from "../Providers/ThemeContext";

function ExpandedCalendar({ title, data, dataLengthPerDate }) {
  let [days, setDays] = useState([]);
  let { t } = useTranslation();
  let dayNames = [
    t("day_sunday"),
    t("day_monday"),
    t("day_tuesday"),
    t("day_wednesday"),
    t("day_thursday"),
    t("day_friday"),
    t("day_saturday"),
  ];
  let date = useMemo(() => new Date(), []);
  let [year, setYear] = useState(date.getFullYear());
  let [month, setMonth] = useState(date.getMonth());
  let navigate = useNavigate();
  const theme = useTheme();

  const findDays = useCallback(() => {
    let monthDays = [];
    let startDate = new Date(year, month, 1);
    let startDay = startDate.getDay();
    startDate.setDate(startDate.getDate() - startDay);
    for (let i = 0; i < startDay; i++) {
      let strDate = toString(startDate);
      monthDays.push({
        day: startDate.getDate(),
        date: startDate.toDateString(),
        currentMonth: false,
        currentDate: false,
        length: dataLengthPerDate[strDate] || 0,
      });
      startDate.setDate(startDate.getDate() + 1);
    }

    while (startDate.getMonth() == month) {
      let strDate = startDate.toLocaleDateString();
      monthDays.push({
        day: startDate.getDate(),
        currentMonth: true,
        date: startDate.toDateString(),
        currentDate:
          date.getDate() == startDate.getDate() &&
          date.getMonth() == startDate.getMonth() &&
          date.getFullYear() == startDate.getFullYear(),
        value: data[strDate],
        length: dataLengthPerDate[strDate] || 0,
      });
      startDate.setDate(startDate.getDate() + 1);
    }

    let totalDays = 42;
    while (monthDays.length < totalDays) {
      let strDate = toString(startDate);
      monthDays.push({
        day: startDate.getDate(),
        date: startDate.toDateString(),
        currentMonth: false,
        currentDate: false,
        length: dataLengthPerDate[strDate] || 0,
      });
      startDate.setDate(startDate.getDate() + 1);
    }
    return monthDays;
  }, [data, dataLengthPerDate, date, month, year])

  let getCurrentDate = () => {
    let months = [
      t("month_jan"),
      t("month_feb"),
      t("month_mar"),
      t("month_apr"),
      t("month_may"),
      t("month_jun"),
      t("month_jul"),
      t("month_aug"),
      t("month_sep"),
      t("month_oct"),
      t("month_nov"),
      t("month_dec"),
    ];
    return `${months[month]} ${year}`;
  };

  let monthLeft = () => {
    if (month == 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  let monthRight = () => {
    if (month == 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  useEffect(() => {
    setDays(findDays());
  }, [month, data, dataLengthPerDate, findDays]);

  return (
    <div className={`calendar expanded ${theme.getThemeClass()}`}>
      <div>
        <div className="date-display">
          <div className="title">{title}</div>
          <div className="date-controls">
            <MdChevronLeft onClick={monthLeft} />
            <div className="date">{getCurrentDate()}</div>
            <MdChevronRight onClick={monthRight} />
          </div>
        </div>
        <div className="header-wrapper">
          <div className="header">
            {dayNames.map((day, index) => {
              return (
                <div key={index} className="item">
                  <span className="short">{day.substring(0, 3)}</span> <span className="long">{day.substring(3)}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="days">
          {days.map((day, index) => {
            return (
              <div
                key={index}
                className={`item ${day.currentMonth ? "" : "inactive"} ${
                  day.currentDate ? "active" : ""
                } ${day.value ? (day.value > 0 ? "green" : "red") : ""}`}
                onClick={() => {
                  if (day.value) {
                    console.log(day.date);
                    navigate(
                      `/daily-journal/${moment(day.date).format("DD-MM-YYYY")}`
                    );
                  }
                }}
              >
                <div className="flex-horizontal">
                  <div className="calendar-icon">
                    {day.value ? <MdCalendarMonth /> : <></>}
                  </div>
                  <div>{day.day}</div>
                </div>
                {day.value ? (
                  <div className={`value`}>
                    <span className="price">{numberFormat.format(day.value)}{" "}</span>
                    <div className="amount">
                      {day.length}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ExpandedCalendar;
