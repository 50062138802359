import moment from "moment/moment";

const getTimezoneOffset = (timezone) => {
    const offsetMinutes = moment.tz(timezone).utcOffset();
    const hours = Math.floor(offsetMinutes / 60);
    const minutes = offsetMinutes % 60;
    const sign = offsetMinutes >= 0 ? '+' : '-';
    return `UTC ${sign}${String(Math.abs(hours)).padStart(2, '0')}:${String(Math.abs(minutes)).padStart(2, '0')}`;
}

export {getTimezoneOffset}